import {ReactElement, useEffect, useRef, useState} from "react";
import {registerAllModules} from "handsontable/registry";
import {AdminWrapper} from "../admin/AdminWrapper";
import {CustomZDDropdown} from "../../atoms/custom-zd-dropdown/CustomZDDropdown";
import {Property, useProperties} from "../../contexts/properties/PropertiesContext";
import {
    BackgroundJob,
    BudgetBulkSetPeriodAssumptions,
    JobType,
    ReforecastBulkSetPeriodAssumptions,
    SubmitBulkSetAssumptionsMutationVariables,
    useSubmitBulkSetAssumptionsMutation
} from "../../__generated__/generated_types";
import {Button} from "@zendeskgarden/react-buttons";
import * as css from "./styles/styles.module.scss";
import {ListJobs, ListJobsRef} from "../admin/jobs/ListJobs";
import {BulkAssumptionsModal} from "./components/bulk-assumptions-modal/BulkAssumptionsModal";
import LeaseExpirationCurve from "./components/lease-expiration-curve/LeaseExpirationCurve";
import {Body, Close, Header, Modal} from "@zendeskgarden/react-modals";
import {History} from "@material-ui/icons";
import ExpandableSection from "./components/expandable-section/ExpandableSection";
import MetricTable from "./components/metric-table/MetricTable";
import {RevenueSource} from "../../constants/RevenueWorkflow";
import {Input} from "@zendeskgarden/react-forms";
import {Dropdown, Field, Item, Label, Menu, Select} from "@zendeskgarden/react-dropdowns";
import {getMetricDataLabel, getRenewalTradeOutTypeLabel} from "./BulkAssumptionHelpers";
import {adjustValuesTo100} from "./BulkAssumptionMathHelpers";
import {toast} from "react-toastify";


registerAllModules();

export interface MetricData {
    marketRent: (number | null)[] | undefined;
    occupancy: (number | null)[] | undefined;
    renewalPercent: (number | null)[] | undefined;
    moveOutPercent: (number | null)[] | undefined;
    renewalTradeOut: (number | null)[] | undefined;
    leaseExpirations: (number | null)[] | undefined;
    renewalTradeOutPct: number | undefined;
    renewalTradeOutType: "growth" | "discount" | "manual";
}

export function BulkAssumptions(): ReactElement {
    const {properties} = useProperties();
    const [selectedProperties, setSelectedProperties] = useState<Property[]>([]);
    const [reforecastStartMonthIndex, setReforecastStartMonthIndex] = useState<number>(0);
    const [reforecastYear, setReforecastYear] = useState<number>(0);

    const jobListRef = useRef<ListJobsRef | null>(null);

    const [metricData, setMetricData] = useState<MetricData>({
        marketRent: new Array(12).fill(0),
        occupancy: new Array(12).fill(0),
        renewalPercent: new Array(12).fill(0),
        moveOutPercent: new Array(12).fill(50),
        renewalTradeOut: new Array(12).fill(50),
        leaseExpirations: new Array(12).fill(8.3),
        renewalTradeOutPct: 3,
        renewalTradeOutType: "growth",
    });

    const [isValid, setIsValid] = useState<boolean>(false);
    const [updateInProgress, setUpdateInProgress] = useState<boolean>(false);

    const [acceptModalOpen, setAcceptModalOpen] = useState<boolean>(false);
    const [showJobLog, setShowJobLog] = useState(false);

    const [submitBulkSetAssumptions] = useSubmitBulkSetAssumptionsMutation({
        fetchPolicy: "no-cache",
        notifyOnNetworkStatusChange: true
    });

    const [metricsToUpdate, setMetricsToUpdate] = useState(new Set<keyof MetricData>());

    function reset() {
        setMetricData({
            marketRent: new Array(12).fill(0),
            occupancy: new Array(12).fill(0),
            renewalPercent: new Array(12).fill(0),
            moveOutPercent: new Array(12).fill(50),
            renewalTradeOut: new Array(12).fill(50),
            leaseExpirations: new Array(12).fill(8.3),
            renewalTradeOutPct: 3,
            renewalTradeOutType: "growth",
        });
        setMetricsToUpdate(new Set());
        setSelectedProperties([]);
    }

    function setMetric(name: keyof MetricData, values: (number | null)[] | undefined): void {
        setMetricData((prev) => {
            switch (name) {
                case "marketRent":
                    return {...prev, marketRent: values};
                case "occupancy":
                    return {...prev, occupancy: values};
                case "moveOutPercent":
                    return {...prev, moveOutPercent: values};
                case "renewalPercent":
                    return {...prev, renewalPercent: values};
                case "renewalTradeOut":
                    return {...prev, renewalTradeOut: values};
                case "leaseExpirations":
                    return {...prev, leaseExpirations: values};
                default:
                    return prev;
            }
        });
    }

    useEffect(() => {
        const validityChecks: (boolean | undefined)[] = [];

        if (metricsToUpdate.has("marketRent")) {
            validityChecks.push(metricData.marketRent?.every(x => typeof x == "number" || x == null));
        }

        if (metricsToUpdate.has("leaseExpirations")) {
            validityChecks.push(
                metricData.leaseExpirations?.every(x => typeof x == "number" || x == null) &&
                metricData.leaseExpirations.sum() >= 99 &&
                metricData.leaseExpirations.sum() <= 101
            );
        }

        if (metricsToUpdate.has("renewalPercent")) {
            validityChecks.push(
                metricData.renewalPercent?.every(x => typeof x == "number" || x == null) &&
                metricData.moveOutPercent?.every(x => typeof x == "number" || x == null)
            );
        }

        if (metricsToUpdate.has("renewalTradeOut")) {
            if (metricData.renewalTradeOutType == "manual") {
                validityChecks.push(metricData.renewalTradeOut?.every(x => typeof x == "number" || x == null));
            } else {
                validityChecks.push(typeof metricData.renewalTradeOutPct == "number" && !isNaN(metricData.renewalTradeOutPct));
            }
        }

        if (metricsToUpdate.has("occupancy")) {
            validityChecks.push(metricData.occupancy?.every(x => typeof x == "number" || x == null) ?? false);
        }

        setIsValid(
            validityChecks.length > 0 &&
            validityChecks.every(x => x === true) &&
            selectedProperties.length > 0
        );

        if (selectedProperties.length > 0) {
            setReforecastStartMonthIndex(Math.min(...selectedProperties.map(p => p.reforecastStartMonthIndex)));
        } else {
            setReforecastStartMonthIndex(0);
        }

        setReforecastYear(selectedProperties[0]?.reforecastYear ?? 0);
    }, [selectedProperties,
        metricData,
        metricsToUpdate,
    ]);

    function submitAssumptions(): void {
        if(!isValid) {
            return;
        }

        const variables: SubmitBulkSetAssumptionsMutationVariables = {
            propertyIds: selectedProperties.map(p => p.id),
            // ignore the linter here, the ! is required to make the compiler happy.
            // the value is really not null or undefined  here.
            budgetYear: selectedProperties[0]!.budgetYear,
            reforecast: {},
            budget: {},
        };
        const reforecast: ReforecastBulkSetPeriodAssumptions = {};
        const budget: BudgetBulkSetPeriodAssumptions = {};

        function make24Array(val: (number | null)[], metric?: keyof MetricData): string[] {
            let newArr: number[] = [];

            switch (metric) {
                case "renewalPercent":
                case "moveOutPercent":
                    newArr = new Array(24).fill(50);
                    break;
                default:
                    newArr = new Array(24).fill(0);
            }

            for(let i = 23, j = val.length - 1; j >= 0; j--, i--) {
                const valJ = val[j];
                if(valJ) {
                    newArr[i] = valJ;
                }
            }
            return newArr.map(x => String(x));
        }

        if (metricsToUpdate.has("marketRent") && metricData.marketRent) {
            const marketRent = make24Array(metricData.marketRent);
            reforecast["marketRentGrowth"] = marketRent.slice(0, 12);
            budget["marketRentGrowth"] = marketRent.slice(12);
        }

        if (metricsToUpdate.has("leaseExpirations") && metricData.leaseExpirations) {
            variables.expirationCurve = adjustValuesTo100(metricData.leaseExpirations).map(x => String(x));
        }

        if (metricsToUpdate.has("renewalPercent") && metricData.renewalPercent && metricData.moveOutPercent) {
            const renewalPercents = make24Array(metricData.renewalPercent, "renewalPercent");
            reforecast["renewalRatio"] = renewalPercents.slice(0, 12);
            budget["renewalRatio"] = renewalPercents.slice(12);

            const moveOuts = make24Array(metricData.moveOutPercent, "moveOutPercent");
            reforecast["moveOutPercentage"] = moveOuts.slice(0, 12);
            budget["moveOutPercentage"] = moveOuts.slice(12);
        }

        if (metricsToUpdate.has("renewalTradeOut") && metricData.renewalTradeOut) {
            if (metricData.renewalTradeOutType == "manual") {
                const renewalTradeOutPercentages = make24Array(metricData.renewalTradeOut);
                reforecast["renewalTradeOut"] = renewalTradeOutPercentages.slice(0, 12);
                budget["renewalTradeOut"] = renewalTradeOutPercentages.slice(12);
            } else if (metricData.renewalTradeOutType == "growth") {
                variables.renewalTradeOutGrowInPlace = String(metricData.renewalTradeOutPct);
            } else if (metricData.renewalTradeOutType == "discount") {
                variables.renewalTradeOutDiscountMarket = String(metricData.renewalTradeOutPct);
            }
        }

        if (metricsToUpdate.has("occupancy") && metricData.occupancy) {
            const occupancy = make24Array(metricData.occupancy);
            reforecast["occupancyPercentage"] = occupancy.slice(0, 12);
            budget["occupancyPercentage"] = occupancy.slice(12);
        }

        variables.reforecast = reforecast;
        variables.budget = budget;

        setUpdateInProgress(true);
        submitBulkSetAssumptions({
            variables,
        }).then(resp => {
            if(resp.data) {
                reset();
            }
        }).finally(() => {
            toast.success("Updates submitted");
            setUpdateInProgress(false);
        });
    }

    function toggleMetricToUpdate(metric: keyof MetricData) {
        setMetricsToUpdate((prev) => {
            const newSet = new Set(prev);
            if (prev.has(metric)) {
                newSet.delete(metric);
            } else {
                newSet.add(metric);
            }
            return newSet;
        });
    }

    return (
        <AdminWrapper>
            <div className={css.wrapper}>
            <div className={css.pageHeader}>
                <div>
                    <h2>Bulk Update Revenue Assumptions</h2>
                    <h6>Configure revenue assumptions for groups of properties. Select one or more of the metrics below to make changes.</h6>
                </div>

                <Button size="small" onClick={() => setShowJobLog(true)}>
                    <Button.StartIcon><History /></Button.StartIcon>
                    View Job History
                </Button>
            </div>

            <div className={css.section}>
                <h4>Properties</h4>
                <h6>Select properties to update.</h6>
                <CustomZDDropdown
                    key={String(updateInProgress)}
                    widthSpec="20rem"
                    applySelectedItems={items => {
                        const propertyIds = items as string[];
                        const selected = properties?.filter(p => propertyIds.includes(p.id)) ?? [];
                        setSelectedProperties(selected);
                    }}
                    openDropdownPlaceholder="Properties"
                    closedDropdownPlaceholder="Properties"
                    options={properties?.map(p => ({value: p.id, label: p.name})) ?? []}
                    isError={undefined}
                    isMulti
                    allOption
                />
            </div>

            <h5 style={{marginBottom: "1rem"}}>Which metrics should be updated?</h5>

            <ExpandableSection
                label="Market Rent"
                isExpanded={metricsToUpdate.has("marketRent")}
                onClick={() => toggleMetricToUpdate("marketRent")}
            >
                {
                    selectedProperties.length ?
                        <>
                            <h5>Enter values as a growth percentage of market rents.</h5>
                            <MetricTable
                                reforecastStartMonthIndex={reforecastStartMonthIndex}
                                reforecastYear={reforecastYear}
                                metricType={RevenueSource.AVG_MARKET_RENTS}
                                onUpdate={rowData => {
                                    setMetric("marketRent", rowData[0]);
                                }}
                            />
                        </>
                        : <h5>First, select at least one property.</h5>
                }
            </ExpandableSection>

            <ExpandableSection
                label="Lease Expirations"
                isExpanded={metricsToUpdate.has("leaseExpirations")}
                inError={metricData.leaseExpirations && (metricData.leaseExpirations.sum() < 98 || metricData.leaseExpirations.sum() > 102)}
                onClick={() => toggleMetricToUpdate("leaseExpirations")}
            >
                {
                    selectedProperties.length ?
                        <>
                            <h5>Drag the points on the chart, or use the fields below, to set your lease expiration curve. This should add up to 100%.</h5>
                            <LeaseExpirationCurve
                                onValueChange={rowData => {
                                    setMetric("leaseExpirations", rowData);
                                }}
                            />
                        </>
                        : <h5>First, select at least one property.</h5>
                }
            </ExpandableSection>

            <ExpandableSection
                label="Renewal Ratio/Move Out Ratio"
                isExpanded={metricsToUpdate.has("renewalPercent")}
                onClick={() => toggleMetricToUpdate("renewalPercent")}
            >
                {
                    selectedProperties.length ?
                    <>
                        <h5>Renewal and move out ratios, per month, should total 100%. Set either, and the other will automatically update.</h5>
                        <MetricTable
                            reforecastStartMonthIndex={reforecastStartMonthIndex}
                            reforecastYear={reforecastYear}
                            metricType={RevenueSource.RENEWALS_RATIO}
                            onUpdate={rowData => {
                                setMetric("renewalPercent", rowData[0]);
                                setMetric("moveOutPercent", rowData[1]);
                            }}
                        />
                    </>
                    : <h5>First, select at least one property.</h5>
                }
            </ExpandableSection>

            <ExpandableSection
                label="Renewal Increase"
                isExpanded={metricsToUpdate.has("renewalTradeOut")}
                onClick={() => toggleMetricToUpdate("renewalTradeOut")}
            >
                {
                    selectedProperties.length ?
                        <>
                            <h5>Renewal tradeouts can be configured one of three ways: rent growth, discount from market, or manual entry.</h5>
                            <div className={css.renewalIncreaseMenu} style={{marginBottom: metricData.renewalTradeOutType == "manual" ? "1rem" : 0}}>
                                <h5>Entry Mode:</h5>
                                <Dropdown
                                    selectedItem={metricData.renewalTradeOutType}
                                    onSelect={renType => setMetricData(prev => ({...prev, renewalTradeOutType: renType}))}
                                >
                                    <Field className={css.dropdown}>
                                        <Label hidden>Select renewal increase entry mode</Label>
                                        <Select>{getRenewalTradeOutTypeLabel(metricData.renewalTradeOutType)}</Select>
                                    </Field>
                                    <Menu>
                                        <Item key={1} value="growth">
                                            {getRenewalTradeOutTypeLabel("growth")}
                                        </Item>
                                        <Item key={2} value="discount">
                                            {getRenewalTradeOutTypeLabel("discount")}
                                        </Item>
                                        <Item key={3} value="manual">
                                            {getRenewalTradeOutTypeLabel("manual")}
                                        </Item>
                                    </Menu>
                                </Dropdown>
                                {
                                    metricData.renewalTradeOutType != "manual" &&
                                    <>
                                        <h5>by</h5>
                                        <Input
                                            className={css.pctInput}
                                            type="number"
                                            placeholder="Amount %"
                                            value={metricData.renewalTradeOutPct}
                                            onChange={evt => setMetricData(prev => ({...prev, renewalTradeOutPct: parseFloat(evt.target.value)})) }
                                        />
                                    </>
                                }
                            </div>
                            {
                                metricData.renewalTradeOutType == "manual" ?
                                    <MetricTable
                                        reforecastStartMonthIndex={reforecastStartMonthIndex}
                                        reforecastYear={reforecastYear}
                                        metricType={RevenueSource.RENEWALS_RATE}
                                        onUpdate={rowData => {
                                            setMetric("renewalTradeOut", rowData[0]);
                                        }}
                                    />
                                    : <></>
                            }
                    </>
                    : <h5>First, select at least one property.</h5>
                }
            </ExpandableSection>

            <ExpandableSection
                label="Occupancy"
                isExpanded={metricsToUpdate.has("occupancy")}
                onClick={() => toggleMetricToUpdate("occupancy")}
            >
                {
                    selectedProperties.length ?
                        <MetricTable
                            reforecastStartMonthIndex={reforecastStartMonthIndex}
                            reforecastYear={reforecastYear}
                            metricType={RevenueSource.OCCUPANCY}
                            onUpdate={rowData => {
                                setMetric("occupancy", rowData[0]);
                            }}
                        />
                        : <h5>First, select at least one property.</h5>
                }
            </ExpandableSection>

            <Button
                isPrimary
                disabled={!isValid || updateInProgress}
                onClick={() => setAcceptModalOpen(true)}
            >
                Review Updates
            </Button>

            {acceptModalOpen &&
                <BulkAssumptionsModal
                    selectedProperties={selectedProperties}
                    selectedMetrics={Array.from(metricsToUpdate).map(m => (getMetricDataLabel(m)))}
                    updateInProgress={updateInProgress}
                    accept={() => {
                        setAcceptModalOpen(false);
                        submitAssumptions();
                    }}
                    cancel={() => setAcceptModalOpen(false)}
                />
            }

            {
                showJobLog &&
                    <Modal
                        className={css.jobLogModal}
                        onClose={() => setShowJobLog(false)}
                    >
                        <Header>
                            Bulk Assumption Job History
                        </Header>
                        <Body className={css.jobLogModalBody}>
                            <ListJobs ref={jobListRef} jobType={JobType.BulkSetAssumptions} />
                        </Body>
                        <Close aria-label="Close modal" />
                    </Modal>
            }
            </div>
        </AdminWrapper>
    );
}
