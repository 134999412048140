import { ReactElement } from "react";
import { CopyDriverDetails, SendBudgetDetails, VersionType } from "../../../__generated__/generated_types";
import { FinancialEntity } from "../../../contexts/chartofaccounts/ChartOfAccountsContext";
import { Property } from "../../../contexts/properties/PropertiesContext";
import css from "./styles/css.module.scss";
import { Apartment, ArrowForward, Assignment } from "@material-ui/icons";
import { Tag } from "@zendeskgarden/react-tags";
import cn from "classnames";


export function renderCopyJobDetails(
    jobDetails: CopyDriverDetails,
    accounts: FinancialEntity[],
    properties: Property[],
): ReactElement {
    const sourcePropertyName = properties.find(x => x.id == jobDetails.sourcePropertyId)?.name;
    const accountList = jobDetails.accountIds ? accounts.filter(x => jobDetails.accountIds?.includes(x.id)) : [];
    const propertyNameList = jobDetails.destinationPropertyIds ? properties.filter(x => jobDetails?.destinationPropertyIds?.includes(x.id)).map(x => x.name) : ["All Properties"];
    const bdgtAbbrvYear = jobDetails.budgetYear ? jobDetails.budgetYear % 100 : "";
    const rfcstAbbrvYear = jobDetails.budgetYear ? jobDetails.budgetYear % 100 - 1 : "";
    const sourceVersionText = jobDetails.period?.sourceVersionType == VersionType.Budget ? `${bdgtAbbrvYear} BDGT` : `${rfcstAbbrvYear} RFCST`;
    const destinationVersionText = jobDetails.period?.sourceVersionType == VersionType.Budget ? `${bdgtAbbrvYear} BDGT` : `${rfcstAbbrvYear} RFCST`;

    return (
        <div>
            <div className={css.jobDetailsHeader}>
                <h4 className={css.title}>{sourcePropertyName}</h4>
                <Tag isPill size="small">
                    {sourceVersionText}
                    <ArrowForward className={css.periodArrow} fontSize="small" />
                    {destinationVersionText}
                </Tag>
            </div>

            <p className={css.jobDetailsSubtitle}>Assumptions {jobDetails.copyAssumptions ? "copied" : "not copied" }</p>

            <div className={css.jobDetailsBody}>
                <div className={css.jobDetailsListWrapper}>
                    <div className={css.jobDetailsListHeader}>
                        <Assignment /> {jobDetails?.accountIds ? `${jobDetails?.accountIds.length} Accounts` : "All Accounts"}
                    </div>
                    <ul className={css.jobDetailsList}>
                        {
                            accountList.length == 0 ?
                                "All Accounts"
                                : accountList.map(account => <li>{account.name}<span>{account.number}</span></li>)
                        }
                    </ul>
                </div>

                <ArrowForward />

                <div className={css.jobDetailsListWrapper}>
                    <div className={css.jobDetailsListHeader}>
                        <Apartment /> {jobDetails?.destinationPropertyIds?.length} Properties
                    </div>
                    <ul className={css.jobDetailsList}>
                        {
                            propertyNameList.map(propertyName => <li>{propertyName}</li>)
                        }
                    </ul>
                </div>
            </div>
        </div>
    );
}

export function renderSendBudgetJobDetails(
    jobDetails: SendBudgetDetails,
    properties: Property[],
): ReactElement {
    const itemsWithPropertyNames = jobDetails.items.map(item => {
        return {
            budgetName: item.budgetName,
            property: properties.find(p => p.id == item.propertyId)?.name,
        }
    });

    return (
        <div className={css.sendBudget}>
            <div className={css.sideBySide}>
                <span>Property</span><span>Entrata Budget Name</span>
            </div>

            {
                itemsWithPropertyNames.map(item => (
                    <div className={css.sideBySide}>
                        <span>{item.property}</span><span>{item.budgetName}</span>
                    </div>
                ))
            }
        </div>
    );
}
