import { useContext, useMemo } from "react";

import { useChartOfAccounts } from "../../../../../contexts/chartofaccounts/ChartOfAccountsContext";
import { AuthContext } from "../../../../../contexts/AuthContext";
import { TDriverMetricsYear, TDriverRevenueMetric } from "../../../../../contexts/account/data/logic/driversAndWorksheetData";
import { OpDriverMetricDisplayName } from "../../../../../constants/Strings";
import { REVENUE_TYPE_DESCRIPTIONS } from "../../../../../components/template/types";
import { IFormulaBarUpdates } from "../../../../../contexts/account/data/useFormulaBarUpdates";
import { getChartOfAccountsWithDepths, IFinancialEntityWithDepth } from "../../../../../utils/account";
import { disabledStrings, getFilteredAccountDrivers, getRootLevelMenuItems } from "./formulaBarMenu";
import { TAllAvailableDrivers } from "../../../../../contexts/account/data/utils";
import { TLookbackRefObject } from "../components/formula-menu/FormulaBarMenu";
import { ModelingMethodName } from "./utils";


export interface DriverMetricsMapObject {
    depth: number;
    name: string;
    value: string;
    isAvailable: boolean;
    disabled: boolean;
    disabledMessage: string;
    isStatic: boolean;
    isSearchable?: boolean;
    hasChildren?: boolean;
    children?: any;
    isNested?: boolean;
}

export enum DriverMetricsTypes {
    account = "account",
    worksheet = "worksheet",
    monthlyAverage = "monthlyAverage",
    percentGrowth = "percentGrowth",
    annualTargetValue = "annualTargetValue",
    operational = "operational",
    payroll = "payroll",
    revenue = "revenue",
    customDriver = "customDriver",
}

export type DriverMetricsMap = Record<string, DriverMetricsMapObject>

const defaultDriverMetricMapItem = {
    isAvailable: true,
    disabled: false,
    disabledMessage: '',
    isStatic: false,
    depth: 0,
    isSearchable: false,
    hasChildren: true,
};

/**
 * TODO (Sahil): This should be a prop so individual items can be disabled (ex: for property drivers, we need 'Revenue' disabled)
 */
export const driverMetricsMap: DriverMetricsMap = {
    [DriverMetricsTypes.account]: {...defaultDriverMetricMapItem, name: 'account', value: '% of Account', isSearchable:true},
    [DriverMetricsTypes.worksheet]: {...defaultDriverMetricMapItem, name: 'worksheet', value: 'Line Items'},
    [DriverMetricsTypes.monthlyAverage]: {...defaultDriverMetricMapItem, name: 'monthlyAverage', value: 'Monthly Average'},
    [DriverMetricsTypes.percentGrowth]: {...defaultDriverMetricMapItem, name: 'percentGrowth', value: '% Growth'},
    [DriverMetricsTypes.annualTargetValue]: {...defaultDriverMetricMapItem, name: 'annualTargetValue', value: 'Annual Target Amount'},
    [DriverMetricsTypes.operational]: {...defaultDriverMetricMapItem, name: 'operational', value: 'Operational Drivers'},
    [DriverMetricsTypes.payroll]: {...defaultDriverMetricMapItem, name: 'payroll', value: 'Payroll', isNested: true},
    [DriverMetricsTypes.revenue]: {...defaultDriverMetricMapItem, name: 'revenue', value: 'Revenue Calculation'},
    [DriverMetricsTypes.customDriver]: {...defaultDriverMetricMapItem, name: 'customDriver', value: 'Custom Driver'},
};

/**
 * @returns ['1 month', '2 months', ... , '11 months']
 */
export const lookbackPeriodMap: string[] = [
    "same month",
    ...new Array(11).fill(0).map((_, index) => `${index + 1} ${index > 0 ? 'months' : 'month'}`),
];

interface TGetMenuItems {
    selectedMenuItem: string|undefined,
    selectedDriverMetric: string|undefined,
    driverMetricsMap: DriverMetricsMap,
    lookbackRefObject?: TLookbackRefObject,
}

export interface IHandleMenuItemClick {
    selectedMetric: keyof TDriverMetricsYear | undefined,
    prevSelectedItem?: string | undefined,
    selectedItem: string | undefined,
}

export interface IHandleLookbackMenuClick {
    lookbackRefObject: TLookbackRefObject | undefined,
    selectedItem: string | undefined,
}

interface IUseFormulaBarMenuProps {
    allDrivers: TAllAvailableDrivers | undefined;
    editableFxBarChecker?: ((modelingMethod: ModelingMethodName) => boolean) | undefined,
    fbUpdates: IFormulaBarUpdates;
    isBulkUpdateBar?: boolean;
    disabledMenuItems?: ModelingMethodName[];
    isWorksheetDriven?: boolean;
    selectedAccountIds?: string[];
}

export interface IFormulaBarMenu {
    getDisplayNameForMetric: (metricName: string) => string | undefined,
    getFilteredMenuItems: (selectedMetric: string, value: string) => DriverMetricsMapObject[],
    getMenuItems: (args: TGetMenuItems) => DriverMetricsMapObject[],
    getPayrollMenuItems: () => DriverMetricsMapObject[],
    handleMenuItemClick: ({selectedMetric, prevSelectedItem, selectedItem}: IHandleMenuItemClick) => void,
    handleLookbackMenuItemClick: (args: IHandleLookbackMenuClick) => void,
}

export default function useFormulaBarMenu({
    allDrivers,
    editableFxBarChecker,
    fbUpdates,
    isBulkUpdateBar = false,
    disabledMenuItems = [],
    isWorksheetDriven = false,
    selectedAccountIds = [],
}: IUseFormulaBarMenuProps): IFormulaBarMenu {

    const authContext = useContext(AuthContext);
    const { chartOfAccounts } = useChartOfAccounts();

    const payrollDrivers = allDrivers?.payroll || [];
    const allCustomItems = allDrivers?.customItems || [];

    const getDisplayNameForMetric = (metricName: string) => driverMetricsMap[metricName]?.value ?? "";

    /**
     * @returns ['same month' , '1 months' , ... , '11 months']
     */
    const lookbackPeriodMenuItems = useMemo(() => lookbackPeriodMap.map((val) => ({
        name: val,
        value: val || '',
        isAvailable: true,
        disabled: false,
        disabledMessage: '',
        isStatic: false,
        depth: 0,
    })), []);

    const getFinancialEntities = () => {
        const flattenedCoAAllDepths = chartOfAccounts ? getChartOfAccountsWithDepths(chartOfAccounts) : [];

        /**
         * Remove items at depth 0, because
         *  we're not supposed to include top level components of depth 0, like Income
        */
        return flattenedCoAAllDepths.filter(x => x.depth != 0).map(x => ({
            ...x,
            depth: x.depth - 1,
        }));
    };

    const getAccountMenuItems = (financialEntities: IFinancialEntityWithDepth[]): DriverMetricsMapObject[] => {
        const alreadyAddedAccountIDs: string[] = fbUpdates
            .parsedFormDrivers["account"]
            .map((each) => each.accountId);

        const numberOfAccounts = financialEntities.length;

        /**
         * Build a list of menu items to render
         *
         * isStatic:
         * An account is static if it has children
         *  i.e. its following items have a depth greater than its own
         */
        return financialEntities.map((account: IFinancialEntityWithDepth, index: number) => ({
            depth: account.depth,
            name: account.id,
            value: `${account.number} ${account.name}`,
            isAvailable: true,
            disabled: alreadyAddedAccountIDs.includes(account.id),
            disabledMessage: disabledStrings.alreadyApplied,
            isStatic: !(
                (account.depth >= (financialEntities[index + 1]?.depth || 0))
                || (account.depth === numberOfAccounts - 1)),
            type: "interactive",
        }));
    };

    const getPayrollMenuItems = (): DriverMetricsMapObject[] => {
        if (payrollDrivers) {
            const alreadyAddedPayrollDriverIDs: string[] = fbUpdates
                .parsedFormDrivers["payroll"]
                .map((each) => each.itemType);

            return payrollDrivers?.map((payrollDriver) => ({
                depth: 0,
                name: payrollDriver.id || payrollDriver.type,
                value: payrollDriver.customName || payrollDriver.type,
                isAvailable: true,
                disabled: alreadyAddedPayrollDriverIDs.includes(payrollDriver.id) || alreadyAddedPayrollDriverIDs.includes(payrollDriver.type),
                disabledMessage: disabledStrings.alreadyApplied,
                isStatic: false,
                type: "interactive",
                children: payrollDriver.positions?.map((position) => ({
                    depth: 0,
                    name: position.id,
                    value: position.name,
                    isAvailable: true,
                    disabled: false,
                    isStatic: false,
                    type: "interactive",
                    children: null,
                })),
            }));
        }
        return [];
    };

    const getCustomDriverMenuItems = (): DriverMetricsMapObject[] => {
        const usedItems = fbUpdates.parsedFormDrivers["customDriver"].map(x => x.itemName);
        const usedItemsSet = new Set(usedItems);
        const all =
            Array.from(
                new Set(allCustomItems.concat(usedItems))
            )
            .sort((a, b) => a < b ? -1 : 1);

        if (all.length == 0) {
            return [{
                depth: 0,
                name: "No Items Available",
                value: "No Items Available",
                isAvailable: false,
                disabled: true,
                disabledMessage: disabledStrings.noCustomItemsSetup,
                isStatic: false
            }]
        }

        return all.map(item => ({
            depth: 0,
            name: item,
            value: item,
            isAvailable: true,
            disabled: usedItemsSet.has(item),
            disabledMessage: disabledStrings.alreadyApplied,
            isStatic: false,
            type: "interactive",
        }));
    }

    /**
     * @param lookbackRefObject Contains the account information that the lookback menu is tied to
     */
    const getMenuItems = ({
        selectedMenuItem,
        selectedDriverMetric,
        driverMetricsMap,
        lookbackRefObject
    }: TGetMenuItems): DriverMetricsMapObject[] => {

        /**
         * If we're inside the payroll sub menu, then render the positions for whichever compensation the user
         * clicked on
        */
        if (selectedMenuItem && selectedMenuItem !== "payroll" && selectedDriverMetric === "payroll") {
            const selectedCompItem = getPayrollMenuItems().find((menuItem) => menuItem.name === selectedMenuItem);

            return selectedCompItem?.children;
        }

        if (lookbackRefObject !== undefined) {
            if (lookbackRefObject.driver === "account") {
                const alreadyAppliedLookbackPeriod = fbUpdates
                    .parsedFormDrivers["account"]
                    .find((each) => each.glName === lookbackRefObject.refObject.name)
                    ?.lookbackPeriod || 0;

                return lookbackPeriodMenuItems.map((lookbackPeriodMenuItem) => {
                    if (lookbackPeriodMenuItem.name === lookbackPeriodMap[alreadyAppliedLookbackPeriod]) {
                        return {
                            ...lookbackPeriodMenuItem,
                            disabled: true,
                        };
                    }
                    return lookbackPeriodMenuItem;
                });
            }
            if (lookbackRefObject.driver === "operational") {
                const alreadyAppliedLookbackPeriod = fbUpdates
                    .parsedFormDrivers["operational"]
                    .find((each) => each.type === lookbackRefObject.refObject.name)
                    ?.lookbackPeriod || 0;

                return lookbackPeriodMenuItems.map((lookbackPeriodMenuItem) => {
                    if (lookbackPeriodMenuItem.name === lookbackPeriodMap[alreadyAppliedLookbackPeriod]) {
                        return {
                            ...lookbackPeriodMenuItem,
                            disabled: true,
                        };
                    }
                    return lookbackPeriodMenuItem;
                });
            }
        }

        if (payrollDrivers
            && payrollDrivers.find((payrollDriver) => payrollDriver.type === selectedMenuItem)) {
                const selectedPayrollDriver = payrollDrivers.find((payrollDriver) => payrollDriver.type === selectedMenuItem);
                const positions = selectedPayrollDriver?.positions;

                if (positions) {
                    return positions.map((position) => ({
                        name: position.id || position.name,
                        value: position.name,
                        isAvailable: true,
                        disabled: false,
                        disabledMessage: '',
                        isStatic: false,
                        type: "interactive",
                        depth: 0,
                    }));
                }
                return [];
        }

        switch(selectedMenuItem) {
            case undefined: {
                // If no item is currently selected in the menu, then it means that we're in the root of the menu
                return getRootLevelMenuItems(driverMetricsMap, fbUpdates, isWorksheetDriven, editableFxBarChecker, isBulkUpdateBar, disabledMenuItems, selectedAccountIds);
            }

            case DriverMetricsTypes.operational: {
                const alreadyAddedOperationalDrivers = fbUpdates
                    .parsedFormDrivers[selectedMenuItem]
                    .map((each) => each.type);
                const availableOperationalMetricDrivers = authContext.operationalMetricTypes || [];

                return availableOperationalMetricDrivers.map((availableOperationalMetricDriver) => ({
                    name: availableOperationalMetricDriver.name,
                    value: OpDriverMetricDisplayName[availableOperationalMetricDriver.name]?.plural
                        ?? availableOperationalMetricDriver.name,
                    isAvailable: true,
                    disabled: alreadyAddedOperationalDrivers.includes(availableOperationalMetricDriver.name),
                    disabledMessage: disabledStrings.alreadyApplied,
                    isStatic: false,
                    type: "interactive",
                    depth: 0,
                }));
            }

            case DriverMetricsTypes.revenue: {
                const alreadyAddedRevenueDrivers = fbUpdates
                    .parsedFormDrivers[selectedMenuItem]
                    .map((each) => each.revenueType);
                const availableRevenueMetricDrivers = authContext.revenueTypes || [];

                return availableRevenueMetricDrivers.map((availableRevenueMetricDriver) => ({
                    name: availableRevenueMetricDriver,
                    value: REVENUE_TYPE_DESCRIPTIONS[availableRevenueMetricDriver],
                    isAvailable: true,
                    disabled: alreadyAddedRevenueDrivers.includes(availableRevenueMetricDriver),
                    disabledMessage: disabledStrings.alreadyApplied,
                    isStatic: false,
                    type: "interactive",
                    depth: 0,
                }));
            }

            case DriverMetricsTypes.account: {
                const financialEntities = getFinancialEntities();

                return getAccountMenuItems(financialEntities);
            }

            case DriverMetricsTypes.payroll: {
                return getPayrollMenuItems();
            }

            case DriverMetricsTypes.customDriver: {
                return getCustomDriverMenuItems()
            }

            default:
                return Object.values(driverMetricsMap);
        }
    };

    // Handles a click made anywhere inside a menu
    const handleMenuItemClick = ({
        selectedMetric,
        // prevSelectedItem,
        selectedItem,
    }: IHandleMenuItemClick): void => {

        // Only consider updating the formula bar if the selected item is not a metric
        // i.e. the user didn't click in the root level
        if (selectedItem
            && !(selectedItem in driverMetricsMap)
            && selectedMetric) {

            if (selectedMetric === "revenue") {
                const updatePayload = {
                    sourceId: '',
                    revenueType: selectedItem,
                } as TDriverRevenueMetric;

                fbUpdates.updateRevenueDrivers({
                    updatePayload,
                });
            } else if (selectedMetric === "operational") {
                const sourceMetricId = authContext.operationalMetricTypes.find((each) => each.name === selectedItem)?.id;
                const createPayload = {
                    type: selectedItem,
                    amount: undefined,
                    sourceMetricId: sourceMetricId || '',
                    driverMetricTypeId: '',
                    lookbackPeriod: 0,
                    percentage: new Array(12).fill(0),
                    values: new Array(12).fill(0),
                    locked: false,
                };

                fbUpdates.addOperationalDriver({
                    createPayload,
                });
            } else if (selectedMetric === "account") {
                const financialEntities = getFinancialEntities();
                const accountObjInFilteredEntities = financialEntities.find((each) => each.id === selectedItem);

                if (accountObjInFilteredEntities) {
                    fbUpdates.updateAccountDrivers({
                        updatePayload: {
                            accountId: accountObjInFilteredEntities.id,
                            glNumber: accountObjInFilteredEntities.number,
                            glName: accountObjInFilteredEntities.name,
                        },
                    });
                }
            } else if (selectedMetric === "customDriver") {
                fbUpdates.addCustomDriver({itemName: selectedItem});
            }
        }
    };

    // Handles a click made inside the lookback menu
    const handleLookbackMenuItemClick = ({
        lookbackRefObject,
        selectedItem
    }: IHandleLookbackMenuClick): void => {
        if (lookbackRefObject?.driver === "account") {
            const accountId = lookbackRefObject?.refObject.id;

            if (accountId !== undefined && selectedItem !== undefined) {
                fbUpdates.updateAccountLookbackPeriodByAccountId({
                    accountId,
                    lookbackPeriod: selectedItem,
                });
            }
        } else if (lookbackRefObject?.driver === "operational") {
            const operationalMetricType = lookbackRefObject?.refObject.name;

            if (operationalMetricType !== undefined && selectedItem !== undefined) {
                fbUpdates.updateOperationalLookbackPeriodByMetricType({
                    operationalMetricType,
                    lookbackPeriod: selectedItem,
                });
            }
        }

        return;
    };

    const getFilteredMenuItems = (selectedMetric: string, value: string): DriverMetricsMapObject[] => {
        if (selectedMetric === "account") {
            const financialEntities = getFinancialEntities();
            const filteredFinancialEntities = getFilteredAccountDrivers(financialEntities, value);

            return getAccountMenuItems(filteredFinancialEntities);
        }
        return [];
    };

    return {
        getDisplayNameForMetric,
        getFilteredMenuItems,
        getPayrollMenuItems,
        getMenuItems,
        handleMenuItemClick,
        handleLookbackMenuItemClick,
    };
}
