import {GetMultiPropertyAccountDriversPropertyExplorerQuery} from "../../../../../__generated__/generated_types";
import {TPositionsAndCompItemsDataProvider} from "../../shared/PositionsAndDisplayColumnsProvider";
import "native-injects";

export type TComparisionData = {
    compItem: {
        id: string,
        name: string
    },
    propData?: undefined | {
        position: {
            id: string,
            name: string
        }
        prop1: boolean,
        prop2: boolean
    }
}

export function parseComparisionData(
    prop1Id: string,
    prop2Id: string,
    rawData: GetMultiPropertyAccountDriversPropertyExplorerQuery["multiPropertyAccountDrivers"]["payroll"],
    positions: NonNullable<TPositionsAndCompItemsDataProvider["positions"]>,
    compItemIds: NonNullable<TPositionsAndCompItemsDataProvider["compItemIds"]>,
    compItemNames: NonNullable<TPositionsAndCompItemsDataProvider["compItemNames"]>
): TComparisionData[] {
    // maps compensation item id to map of postion -> property ids
    const mapped: Map<string, Map<string, string[]>> = new Map();
    for (const row of rawData) {
        for (const compensation of row.primaryCompensationAccounts) {
            let compItemPositions = mapped.get(compensation.primaryCompensationItem);
            if (!compItemPositions) {
                compItemPositions = new Map();
                mapped.set(compensation.primaryCompensationItem, compItemPositions);
            }
            let positionProperties = compItemPositions.get(compensation.positionId);
            if (!positionProperties) {
                positionProperties = [];
                compItemPositions.set(compensation.positionId, positionProperties);
            }
            positionProperties.push(row.propertyId);
        }
        for (const compensation of row.compensationItemAccounts) {
            let compItemPositions = mapped.get(compensation.compensationItemId);
            if (!compItemPositions) {
                compItemPositions = new Map();
                mapped.set(compensation.compensationItemId, compItemPositions);
            }
            let positionProperties = compItemPositions.get(compensation.positionId);
            if (!positionProperties) {
                positionProperties = [];
                compItemPositions.set(compensation.positionId, positionProperties);
            }
            positionProperties.push(row.propertyId);
        }
    }
    const ret: TComparisionData[] = [];
    for (const compItemId of compItemIds) {
        const compItemPositions = mapped.get(compItemId);
        const compItemName = compItemNames[compItemId];
        if (!compItemName || !compItemPositions || compItemPositions.size === 0) {
            continue;
        }
        for (const position of positions) {
            const positionProperties = compItemPositions.get(position.id);
            if (!positionProperties) {
                continue;
            }
            const prop1Found = positionProperties.includes(prop1Id);
            const prop2Found = positionProperties.includes(prop2Id);
            // show only differencies
            if (prop1Found === prop2Found) {
                continue;
            }
            ret.push({
                compItem: {id: compItemId, name: compItemName},
                propData: {
                    position: {id: position.id, name: position.name},
                    prop1: prop1Found,
                    prop2: prop2Found
                }
            });
        }
    }
    return ret;
}

/**
 * NOTE: pay attention to the the nuance of the data provided by this logic
 * We display compensation items and positions - two-keyed data
 * We decided to lay out information such that we group positions by compensation item
 * Then for a group of positions we always show compensation item at the top of the group of position rows
 *
 * For latter to always be fulfilled (the display of compensation item at the top of the group of positions rows)
 * the function produces data aligned to page size and ensuring that beginning of the page has compensation item row
 *
 * IMPORTANT: rows for the same comp item id are expected to be contiguous
 */
export function hidrateHeaderRows(rawComparisonData: TComparisionData[], pageSize: number): TComparisionData[] {
    // guard against accidental pass of data with headers
    const sanitized = rawComparisonData.filter(row => row.propData !== undefined) as (TComparisionData & {propData: NonNullable<TComparisionData["propData"]>})[];

    if (sanitized.length === 0 || pageSize < 2) {
        return [];
    }

    let currentCompItemId = sanitized.firstElement.compItem.id;
    let currentRowIdx = 0;

    const ret: TComparisionData[] = [];
    while (currentRowIdx < sanitized.length) {
        for (let pageRow = 1; pageRow <= pageSize; pageRow++) {
            const row = sanitized[currentRowIdx];
            if (!row) {
                break;
            }

            if (pageRow === 1 || currentCompItemId !== row.compItem.id) {
                ret.push({
                    compItem: {
                        ...row.compItem
                    }
                });
                currentCompItemId = row.compItem.id;
            }
            else {
                ret.push(row);
                currentRowIdx++;
            }
        }
        if (!sanitized[currentRowIdx]) {
            break;
        }
    }

// illustrative use cases
//     pageSize 3
//     a (1) a (2) b

// 1    a (1) header (pageRow == 1)
// 2    a (1) data
// 3    a (2)
// 1    b header (pageRow == 1)
// 2    b data

//     pageSize 4
//     a (1) a (2) b

// 1    a (1) header (pageRow == 1)
// 2    a (1) data (currentRowIdx++)
// 3    a (2) (currentRowIdx++)
// 4    b header (currentCompItemId !== row.compItem.id)
// 1    b header (pageRow == 1)
// 2    b data

    return ret;
}

export function buildPageData(params: {
    data: TComparisionData[],
    page: number,
    pageSize: number,
}): TComparisionData[] {
    const {data, page, pageSize} = params;
    const start = (page - 1) * pageSize;
    return data.slice(start, start + pageSize);
}