import {useEffect, useMemo, useRef, useState} from "react";
import {LoadPayrollDriversByPositionQuery, useGetMultiPropertyAccountDriversPropertyExplorerLazyQuery, VersionType} from "../../../../__generated__/generated_types";
import {ApolloError} from "@apollo/client";
import {Property} from "../../../../contexts/properties/PropertiesContext";
import {assignDriverInfo, mapActPercent, mapCustomDriver, mapGrowth, mapLineItems, mapOperational, mapPayroll, mapRevenue, TData} from "./logic";
import {FinancialEntity} from "../../../../contexts/chartofaccounts/ChartOfAccountsContext";
import {formatterGlName} from "../../../../utils/formatters";
import {TPositionsAndCompItemsDataProvider} from "../shared/PositionsAndDisplayColumnsProvider";

const TAKE = 100;

export interface DriverDataLoaderParams {
    year: number | undefined;
    versionType: VersionType | undefined;
    accountId: string | undefined;
    properties: Property[] | undefined;
    chartOfAccountsFlat: FinancialEntity[] | undefined;
    positions: TPositionsAndCompItemsDataProvider["positions"];
    compItemIds: TPositionsAndCompItemsDataProvider["compItemIds"];
    compItemNames: TPositionsAndCompItemsDataProvider["compItemNames"];
}

export type PayrollDriversQueryData = LoadPayrollDriversByPositionQuery["payrollDriversByPosition"]["items"];
export type TDriverDataLoader = ReturnType<typeof useDriverDataLoader>;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useDriverDataLoader(props: DriverDataLoaderParams) {
    const [parsedData, setParsedData] = useState<TData[]>();
    const [partialParsedData, setPartialParsedData] = useState<TData[]>([]);
    const [page, setPage] = useState<number>(0);
    const [parsedDataLoading, setParsedDataLoading] = useState<boolean>(true);
    const [parsedDataError, setParsedDataError] = useState<ApolloError | undefined>(undefined);
    const chunkLoadTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

    const [getMultiPropertyAccountDrivers, {data: multiPropertyAccountDriversData, loading: multiPropertyAccountDriversLoading, error: multiPropertyAccountDriversError}] = useGetMultiPropertyAccountDriversPropertyExplorerLazyQuery({
        fetchPolicy: "no-cache"
    });

    const propertyIds = useMemo(() => {
        if (!props.properties) {
            return undefined;
        }
        return props.properties.map(p => p.id);
    }, [props.properties]);

    async function handleDataChunk() {
        const propertiesCount = propertyIds?.length ?? 0;
        if(!multiPropertyAccountDriversLoading
            && multiPropertyAccountDriversData
            && props.chartOfAccountsFlat
            && props.positions
            && props.compItemIds
            && props.compItemNames
            && props.versionType
            && props.year
        ) {
            const accountFullNames: Parameters<typeof mapActPercent>["0"] =
                props.chartOfAccountsFlat.map(acc => ({id: acc.id, fullName: formatterGlName(acc.name, acc.number)}));
            let newPartialParsedData = [] as TData[];
            if (props.properties) {
                const propertiesDrivers = new Map<string, TData>();
                const chunk = props.properties.slice(TAKE * page, TAKE);
                for (const property of chunk) {
                    propertiesDrivers.set(property.id, {
                        property: {
                            id: property.id,
                            name: property.name
                        },
                        monthlyAverage: null,
                        growthPercent: null,
                        annualTargetAmount: null,
                        payroll: null,
                        accountPercent: null,
                        worksheet: null,
                        operational: null,
                        revenue: null,
                        customDriver: null
                    });
                }
                assignDriverInfo(mapOperational(multiPropertyAccountDriversData.multiPropertyAccountDrivers.operational), "operational", propertiesDrivers);
                assignDriverInfo(mapCustomDriver(multiPropertyAccountDriversData.multiPropertyAccountDrivers.customDriver), "customDriver", propertiesDrivers);
                assignDriverInfo(mapLineItems(multiPropertyAccountDriversData.multiPropertyAccountDrivers.worksheet), "worksheet", propertiesDrivers);
                assignDriverInfo(mapRevenue(multiPropertyAccountDriversData.multiPropertyAccountDrivers.revenue), "revenue", propertiesDrivers);
                assignDriverInfo(await mapActPercent(accountFullNames, multiPropertyAccountDriversData.multiPropertyAccountDrivers.acctPercentage), "accountPercent", propertiesDrivers);
                assignDriverInfo(await mapPayroll(
                    multiPropertyAccountDriversData.multiPropertyAccountDrivers.payroll,
                    props.positions,
                    props.compItemIds,
                    props.compItemNames
                ), "payroll", propertiesDrivers);
                const {growthPercent, monthlyAverage, annualTargetAmount} = mapGrowth(
                    multiPropertyAccountDriversData.multiPropertyAccountDrivers.growth,
                    props.versionType as (VersionType.Reforecast | VersionType.Budget),
                    props.versionType === VersionType.Reforecast ? props.year : props.year - 1
                );
                assignDriverInfo(growthPercent, "growthPercent", propertiesDrivers);
                assignDriverInfo(monthlyAverage, "monthlyAverage", propertiesDrivers);
                assignDriverInfo(annualTargetAmount, "annualTargetAmount", propertiesDrivers);
                newPartialParsedData = chunk.map(property => propertiesDrivers.get(property.id)!);
            }


            const newPage = page + 1;
            setPage(newPage);
            if (newPage * TAKE < propertiesCount
                && props.year && props.versionType && props.accountId && propertyIds // make transpiler happy as these are not supposed to be undefined by this point
            ) {
                setPartialParsedData(prev => [...prev, ...newPartialParsedData]);
                getMultiPropertyAccountDrivers({
                    variables: {
                        yearsVersionTypes: [{
                            year: props.year,
                            versionType: props.versionType
                        }],
                        accountId: props.accountId,
                        propertyIds: propertyIds.slice(0, TAKE),
                    }
                });
            }
            else {
                setParsedData([...partialParsedData, ...newPartialParsedData]);
                setParsedDataLoading(false);
            }
        }
    }

    useEffect(() => {
        if (chunkLoadTimeout.current) {
            clearTimeout(chunkLoadTimeout.current);
        }
        chunkLoadTimeout.current = setTimeout(handleDataChunk, 0);
    }, [multiPropertyAccountDriversLoading, multiPropertyAccountDriversData]);

    useEffect(() => {
        if(!multiPropertyAccountDriversLoading && multiPropertyAccountDriversError) {
            setParsedDataLoading(false);
            setParsedDataError(multiPropertyAccountDriversError);
        }
    }, [multiPropertyAccountDriversLoading, multiPropertyAccountDriversError]);

    useEffect(() => {
        if(props.year
            && props.versionType
            && propertyIds
            && propertyIds.length > 0
            && props.accountId
        ){
            setPartialParsedData([]);
            setPage(0);
            setParsedDataLoading(true);
            getMultiPropertyAccountDrivers({
                variables: {
                    yearsVersionTypes: [{
                        year: props.year,
                        versionType: props.versionType
                    }],
                    accountId: props.accountId,
                    propertyIds: propertyIds.slice(0, TAKE),
                }
            });
        }
    }, [props.year, props.versionType, propertyIds, props.accountId]);

    return {
        parsedData,
        parsedDataLoading,
        parsedDataError,
    };
}