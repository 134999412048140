import {useEffect, useState} from "react";
import {buildFilteredData, buildPropertyPayrollDriverAccountsDisplayData, TData} from "./logic";
import {TDriverDataLoader} from "./DriverDataLoader";
import {FinancialEntity} from "../../../../contexts/chartofaccounts/ChartOfAccountsContext";
import {Property} from "../../../../contexts/properties/PropertiesContext";

interface DriverDataTransformerParams {
    data: TDriverDataLoader["data"];
    loading: TDriverDataLoader["loading"];
    chartOfAccountsFlat: FinancialEntity[] | undefined;
    isCoaReady: boolean;
    properties: Property[] | undefined;
    displayColumns: string[] | undefined;
    selectedDisplayColumns: Set<string> | undefined;
    benchmarkProperty: TData | undefined;
    filterMultiassigned: boolean;
    filterUnassigned: boolean;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useDriverDataTransformer(props: DriverDataTransformerParams) {
    const {
        data: rawData,
        loading: rawDataLoading,
        properties,
        chartOfAccountsFlat,
        isCoaReady,
        displayColumns,
        selectedDisplayColumns,
        benchmarkProperty,
        filterMultiassigned,
        filterUnassigned
    } = props;

    const [parsedData, setParsedData] = useState<TData[]>();
    const [filteredData, setFilteredData] = useState<TData[]>();

    useEffect(() => {
        if (!rawData
            || rawDataLoading
            || !properties
            || !chartOfAccountsFlat
            || !isCoaReady
            || !displayColumns
        ) {
            return;
        }
        const {data} = buildPropertyPayrollDriverAccountsDisplayData(
            rawData,
            properties,
            chartOfAccountsFlat,
            displayColumns.slice(1)
        );
        setParsedData(data);
    }, [rawData, rawDataLoading, properties, chartOfAccountsFlat, isCoaReady, displayColumns]);


    useEffect(() => {
        if (!parsedData || !displayColumns || !selectedDisplayColumns) {
            return;
        }
        setFilteredData(buildFilteredData(
            parsedData,
            benchmarkProperty,
            displayColumns.filter(key => key !== "property" && selectedDisplayColumns.has(key)),
            filterMultiassigned,
            filterUnassigned,
        ));
    }, [parsedData, benchmarkProperty, filterMultiassigned, filterUnassigned, displayColumns, selectedDisplayColumns]);

    return {transformedData: filteredData};
}