import { TAccountTableRowData } from "../data/AccountTableData";
import { DriverType } from "../../../__generated__/generated_types";

export type TableDriverType = TAccountTableRowData["driverType"];

export interface IDriverRow<T extends TableDriverType = TableDriverType> {
    id: string;
    driverType: T;
}

export interface IDriverRowAccountPercentage extends IDriverRow<DriverType.AccPercentage> {
    percentage: string[];
}

export interface IDriverRowOperational extends IDriverRow<DriverType.Operational> {
    fee: string[];
    percentage: string[];
}

export interface IDriverRowLineItem extends IDriverRow<DriverType.Worksheet> {
    values: string[];
}

export interface IDriverRowCustom extends IDriverRow<"customDriver"> {
    percentage: string[];
    count: string[];
    amount: string[];
}

function createEmptyDriver(id: string, type: TAccountTableRowData["driverType"]): IDriverRow | undefined {
    if(type === DriverType.Operational) {
        return {
            id: id,
            driverType: type,
            fee: new Array(12).fill("0"),
            percentage: new Array<string>(12).fill("0")
        } as IDriverRowOperational;
    } else if(type === DriverType.AccPercentage) {
        return {
            id: id,
            driverType: type,
            percentage: new Array<string>(12).fill("0")
        } as IDriverRowAccountPercentage;
    } else if(type === DriverType.Worksheet) {
        return {
            id: id,
            driverType: type,
            values: new Array<string>(12).fill("0")
        } as IDriverRowLineItem;
    } else if(type === "customDriver") {
        return {
            id: id,
            driverType: type,
            count: new Array(12).fill("0"),
            amount: new Array(12).fill("0"),
            percentage: new Array(12).fill("0")
        } as IDriverRowCustom;
    }

    return undefined;
}

function arrayFromTableRow(row: TAccountTableRowData): string[] {
    const val = new Array(12).fill("0");
    for(let i = 0; i < 12; i++) {
        val[i] = row[i]?.toString() ?? null;
    }
    return val;
}

export function mapAccountTableToDrivers(rowData: TAccountTableRowData[]): Map<string, IDriverRow> {
    const mapped = new Map<string, IDriverRow>();
    for(const row of rowData) {
        if(row.rowType !== "driver") {
            continue;
        }


        if(row.driverType !== DriverType.Worksheet && !row.parentRowId) {
            continue;
        }

        let driverId = row.parentRowId ?? row.label;
        if(row.driverType === DriverType.AccPercentage && row.parentRowId === "multi_pct_of_account" && row.id) {
            driverId = row.id;
        }
        const driverType = row.driverType;
        if(driverType === undefined) {
            continue;
        }

        let driver = mapped.get(driverId);
        if(!driver) {
            driver = createEmptyDriver(driverId, driverType);
            // Driver couldn't be created, we don't recognize the type.
            if(!driver) {
                continue;
            }
            mapped.set(driverId, driver);
        }

        if(driverType === DriverType.Operational) {
            const opDriver = driver as IDriverRowOperational;
            switch(row.dataFormat) {
                case "percent":
                    opDriver.percentage = arrayFromTableRow(row);
                    break;
                case "currency":
                    opDriver.fee = arrayFromTableRow(row);
                    break;
            }
        } else if(driverType === DriverType.AccPercentage) {
            const accDriver = driver as IDriverRowAccountPercentage;
            if(row.dataFormat === "percent") {
                accDriver.percentage = arrayFromTableRow(row);
            }
        } else if(driverType === DriverType.Worksheet) {
            const lineItem = driver as IDriverRowLineItem;
            if(row.dataFormat === "currency") {
                lineItem.values = arrayFromTableRow(row);
            }
        } else if(driverType === "customDriver") {
            const customDriver = driver as IDriverRowCustom;
            if(row.dataFormat === "percent") {
                customDriver.percentage = arrayFromTableRow(row);
            } else if(row.dataFormat === "currency") {
                customDriver.amount = arrayFromTableRow(row);
            } else if(row.dataFormat === "count") {
                customDriver.count = arrayFromTableRow(row);
            }
        }
    }

    return mapped;
}