import {ReactElement, ReactNode, useEffect, useMemo, useState} from "react";
import {
    buildPageData,
    rowKey,
    TData,
    TDataKey,
} from "./logic";
import {useProperties} from "../../../../contexts/properties/PropertiesContext";
import {useChartOfAccounts} from "../../../../contexts/chartofaccounts/ChartOfAccountsContext";
import {DataDisplayTable} from "../../../../components/data-table/DataDisplayTable";
import {PAGE_SIZE, PaginationParams, usePagination} from "../../../../components/data-table/usePagination";
import {Button} from "@zendeskgarden/react-buttons";
import {SortingParams, useSorting} from "../../../../components/data-table/useSorting";
import * as css from "./payrollaccs.module.scss";
import cn from "classnames";
import {Close, Settings} from "@material-ui/icons";
import {ReactComponent as CompareIcon} from '@zendeskgarden/svg-icons/src/16/duplicate-stroke.svg';
import {ReactComponent as AlertIcon} from '@zendeskgarden/svg-icons/src/16/alert-warning-fill.svg';
import {Tooltip} from "@zendeskgarden/react-tooltips";
import {useDriverDataLoader} from "./DriverDataLoader";
import {Dropdown, Field, Item, Menu, Select} from "@zendeskgarden/react-dropdowns";
import {usePositionsAndCompItemsDataProvider} from "../shared/PositionsAndDisplayColumnsProvider";
import {useDriverDataTransformer} from "./DriverDataTransformer";
import {SettingsModal, SettingsModalProps} from "./SettingsModal";

export interface PropertyRevenueDriverAccountsParams {
    budgetYear: number;
    customLabelComponent?: ReactNode;
}

export function PropertyPayrollDriverAccounts(props: PropertyRevenueDriverAccountsParams): ReactElement | null {
    const [benchmarkProperty, setBenchmarkProperty] = useState<TData>();
    const [filterMultiassigned, setFilterMultiassigned] = useState(false);
    const [filterUnassigned, setFilterUnassigned] = useState(false);
    const [settingsVisible, setSettingVisible] = useState(false);

    const {properties} = useProperties();
    const {chartOfAccountsFlat, isReady: coaReady} = useChartOfAccounts();
    const {
        positions,
        compItemIds,
        compItemNames
    } = usePositionsAndCompItemsDataProvider();

    const {displayColumns, displayColumnNames} = useMemo((): {
        displayColumns: string[] | undefined,
        displayColumnNames: Record<string, string> | undefined;
    } => {
        if (!compItemIds || !compItemNames) {
            return {
                displayColumns: undefined,
                displayColumnNames: undefined
            };
        }
        return {
            displayColumns: ["property", ...compItemIds],
            displayColumnNames: {
                "property": "Property",
                ...compItemNames
            }
        };
    }, [compItemIds, compItemNames]);

    const [selectedPosition, setSelectedPosition] = useState<string>();
    const [selectedDisplayColumns, setSelectedDisplayColumns] = useState<Set<string>>();

    const {data: rawData, loading: rawDataLoading} = useDriverDataLoader({
        properties: properties,
        budgetYear: props.budgetYear,
        positionId: selectedPosition
    });

    const {transformedData} = useDriverDataTransformer({
        data: rawData,
        loading: rawDataLoading,
        chartOfAccountsFlat,
        isCoaReady: coaReady,
        properties,
        displayColumns,
        selectedDisplayColumns,
        benchmarkProperty,
        filterMultiassigned: filterMultiassigned,
        filterUnassigned
    });

    const pagination = usePagination();
    const sorting = useSorting<TDataKey>();

    // displayPageData holds all data that needs to change atomically in a single render cycle
    const [displayPageData, setDisplayPageData] = useState<{
        rows: TData[] | undefined,
        benchmarkProperty: TData | undefined,
        paginationParams: PaginationParams,
        sortingParams: SortingParams<TDataKey>;
    }>({
        rows: undefined,
        benchmarkProperty: undefined,
        paginationParams: pagination.paginationParams,
        sortingParams: sorting.sortingParams
    });

    useEffect(() => {
        if (!transformedData) {
            return;
        }
        pagination.set({total: transformedData.length, currentPage: 1});
    }, [transformedData]);

    useEffect(() => {
        if (!transformedData) {
            return;
        }

        setDisplayPageData({
            rows: buildPageData({
                data: transformedData,
                page: pagination.paginationParams.currentPage,
                pageSize: pagination.paginationParams.pageSize,
                exclude: benchmarkProperty,
                sortColumn: sorting.sortingParams.sortColumn,
                sortType: sorting.sortingParams.sortType
            }),
            benchmarkProperty,
            paginationParams: pagination.paginationParams,
            sortingParams: sorting.sortingParams
        });
    }, [
        transformedData,
        pagination.paginationParams.currentPage,
        pagination.paginationParams.pageSize,
        pagination.paginationParams.totalPages,
        sorting.sortingParams.sortColumn,
        sorting.sortingParams.sortType
    ]);


    useEffect(() => {
        if (!positions) {
            return;
        }
        setSelectedPosition(positions[0]?.id);
        setBenchmarkProperty(undefined);
        sorting.reset();
    }, [positions]);

    useEffect(() => {
        if (displayColumns && !selectedDisplayColumns) {
            setSelectedDisplayColumns(new Set(displayColumns));
        }
    }, [displayColumns]);


    // NOTE: selecting and unselecting benchmark property resets current page to 1
    // If we really wanted to - we'd find out new value for currentPage based on changed page size
    // and the most of the items visible before benchmark property selection/unselection
    function handleSetBenchmarkProperty(row: TData) {
        if (!transformedData) {
            return;
        }
        pagination.set({total: transformedData.length, pageSize: PAGE_SIZE - 1, currentPage: 1});
        setBenchmarkProperty(row);
    }

    function handleClearBenchmarkProperty() {
        if (!transformedData) {
            return;
        }
        pagination.set({total: transformedData.length, pageSize: PAGE_SIZE, currentPage: 1});
        sorting.reset();
        setBenchmarkProperty(undefined);
    }

    function hadnleToggleSort(column: TDataKey | undefined) {
        sorting.toggleSort(column);
        pagination.set({currentPage: 1});
    }

    function handleSelectPosition(positionId: string) {
        setSelectedPosition(positionId);
        setBenchmarkProperty(undefined);
    }

    const handleSettingsApply: SettingsModalProps["onApply"] = ({columns, filterMultiassigned: filterMultiassigned, filterUnassigned}) => {
        setSelectedDisplayColumns(new Set(columns.filter(column => column.enabled).map(column => column.key)));
        setFilterMultiassigned(filterMultiassigned);
        setFilterUnassigned(filterUnassigned);
        setSettingVisible(false);
    };

    function cellDataToDisplay(key: TDataKey, row: TData): string | ReactElement | number {
        if (key === "property") {
            return (
                <div className={css.propertyCell}>
                    <span>{row.property.name}</span>
                    {(selectedDisplayColumns?.size ?? 1) > 1 &&
                        <Tooltip content="Compare To" placement="end">
                            <Button isBasic size="small" onClick={() => handleSetBenchmarkProperty(row)}><CompareIcon style={{minHeight: "18px", minWidth: "18px"}} /></Button>
                        </Tooltip>
                    }
                </div>
            );
        }
        const compensationItemAccounts = row.compensationItems[key];
        if (!compensationItemAccounts || compensationItemAccounts.length === 0) {
            return "";
        }
        if (compensationItemAccounts.length === 1) {
            return compensationItemAccounts[0]?.name ?? "";
        }
        return (
            <>
                <Tooltip
                    zIndex={10}
                    content={
                        <>
                            <h4>Other Accounts:</h4>
                            <ul>
                                {compensationItemAccounts.slice(1).map(acc => (
                                    <li key={acc.accountId}>{acc.name}</li>
                                ))}
                            </ul>
                        </>
                    } placement="end">
                    <div>
                        <span><AlertIcon style={{color: "var(--yellow-200)"}} />&nbsp;</span>
                        <span>
                            {compensationItemAccounts[0]?.name ?? ""}&nbsp;...
                        </span>
                    </div>
                </Tooltip>
            </>
        );
    }

    function cellSecondHeaderDataToDisplay(key: TDataKey, row: TData): string | ReactElement | number {
        if (key === "property") {
            return (
                <div className={css.propertyCell}>
                    {row.property.name} <Button isBasic size="small" onClick={() => handleClearBenchmarkProperty()}><Close /></Button>
                </div>
            );
        }
        return row.compensationItems[key]?.[0]?.name ?? "";
    }

    const positionDropdown = useMemo(() => {
        if (!positions || !selectedPosition) {
            return undefined;
        }
        return (
            <Dropdown
                selectedItem={selectedPosition}
                onSelect={(v) => handleSelectPosition(v)}
            >
                <Field>
                    <Select style={{minWidth: "20em"}}>
                        {positions.find(position => position.id === selectedPosition)?.name}
                    </Select>
                </Field>
                <Menu>
                    {
                        positions.map(position => (
                            <Item key={position.id} value={position.id}>{position.name}</Item>
                        ))
                    }
                </Menu>
            </Dropdown>
        );
    }, [positions, selectedPosition]);

    const hasNonDefaultSettings = useMemo(() => {
        return displayColumns
            && selectedDisplayColumns
            && (displayColumns.length != selectedDisplayColumns.size)
            || filterUnassigned || filterMultiassigned;
    }, [displayColumns, selectedDisplayColumns, filterMultiassigned, filterUnassigned]);

    return (
        <div className={css.wrapper}>
            <div className={css.header}>
                {props.customLabelComponent ?
                    <div className={css.customLabelWrapper}>
                        {props.customLabelComponent}<h4>&nbsp;|&nbsp;Position&nbsp;</h4>
                    </div>
                    :
                    <h4>{PropertyPayrollDriverAccounts.label}&nbsp;|&nbsp;Position&nbsp;</h4>
                }
                {positionDropdown}
                <div className={css.headerButtons}>
                    <div className={cn(css.settingsGear, hasNonDefaultSettings ? css.highlight : undefined)}>
                        <Button isBasic onClick={() => setSettingVisible(true)}>
                            <Settings />
                        </Button>
                    </div>
                </div>
            </div>
            <div className={css.container}>
                {displayColumns && displayColumnNames &&
                    <DataDisplayTable
                        className={css.tableWrapper}
                        dataProvider={{
                            rows: displayPageData?.rows,
                            totalRows: !transformedData ? undefined : Math.max(pagination.paginationParams.pageSize, transformedData.length),
                            columns: displayColumns.filter(col => selectedDisplayColumns?.has(col)),
                            secondHeaderRow: displayPageData?.benchmarkProperty
                        }}
                        headers={displayColumnNames}
                        paginationParams={{
                            params: displayPageData.paginationParams,
                            setPage: pagination.setPage
                        }}
                        sortingParams={{
                            columns: displayColumns,
                            params: displayPageData.sortingParams,
                            toggleSort: hadnleToggleSort,
                            currentSortForColumn: sorting.currentSortForColumn
                        }}
                        customCellRenderers={{
                            cellDataToDisplay: cellDataToDisplay,
                            cellSecondHeaderDataToDisplay: cellSecondHeaderDataToDisplay,
                            rowKey: rowKey
                        }}
                    />
                }
            </div>
            {settingsVisible &&
                <SettingsModal
                    onClose={() => setSettingVisible(false)}
                    onApply={handleSettingsApply}
                    columns={displayColumns?.map(key => ({
                        key: key,
                        name: displayColumnNames?.[key] ?? "",
                        enabled: !!selectedDisplayColumns?.has(key)
                    })) ?? []}
                    fixedColumns={["property"]}
                    filterMultiassigned={filterMultiassigned}
                    filterUnassigned={filterUnassigned}
                />
            }
        </div>
    );
}

PropertyPayrollDriverAccounts.label = "EXPLORE PAYROLL CONFIGURATION";