import {ReactElement, useEffect, useRef, useState} from "react";
import {Dropdown, Field as DropdownField, Item, Menu, Select} from "@zendeskgarden/react-dropdowns";
import {Checkbox, Field, Input, Label} from "@zendeskgarden/react-forms";
import {Col, Grid, Row} from "@zendeskgarden/react-grid";
import {Head, HeaderCell, HeaderRow, Table} from "@zendeskgarden/react-tables";
import {ThemeProvider} from "@zendeskgarden/react-theming";
import Infinite from "react-infinite";

import {FinancialEntityType, VersionType} from "../../../__generated__/generated_types";
import lockOnDisabled from "../../../assets/icons/lock/locked-disabled.svg";
import useAppStore from "../../../hooks/useAppStore";
import {useProperties} from "../../../contexts/properties/PropertiesContext";
import {SelectableFinancialEntity, TVersionTypeDropdownItem} from "../PropertyDrivers";
import {IUsePropertyDriversReturn} from "../logic/usePropertyDrivers";
import {
    areAllAccountsSelected,
    areAllFilteredAccountsSelected,
    getChildAccounts,
    getFilteredAccounts,
    getFilteredCategories,
    isIndeterminate,
    isSelectAllSelected,
} from "../logic/utils";

import PropertyDriversFormulaBar from "./PropertyDriversFormulaBar";
import * as css from "./styles/css.module.scss";
import LockButton from "./LockButton";
import cn from "classnames";
import {Button} from "@zendeskgarden/react-buttons";
import {FinancialEntity} from "../../../contexts/chartofaccounts/ChartOfAccountsContext";


const VERSION_TYPES = [
    VersionType.Budget,
    VersionType.Reforecast,
];

interface ISelectAccounts {
    pd: IUsePropertyDriversReturn,
    coaEntities: FinancialEntity[],
}


function DelayRender(props: {timeoutMs: number, uniqueId: string, children: (canRender: boolean) => ReactElement | ReactElement[]}): ReactElement {
    const [canRender, setCanRender] = useState(false);
    useEffect(() => {
        setCanRender(false);
        const delayRender = setTimeout(() => {
            setCanRender(true);
        }, props.timeoutMs);

        return () => {
            clearTimeout(delayRender);
        };
    }, [props.uniqueId]);

    return <>
        {props.children(canRender)}
    </>;
}

export default function SelectAccounts({
    pd,
    coaEntities,
}: ISelectAccounts): ReactElement {

    const appStore = useAppStore();
    const { propertyId } = useProperties();
    const searchRegex = /Category:'([^']+)'(?:\s+(.*))?/;
    const coaCategories = coaEntities.filter(x => x.type != FinancialEntityType.Account);

    const [filteredAccounts, setFilteredAccounts] = useState<SelectableFinancialEntity[]>([]);
    const [filteredCategories, setFilteredCategories] = useState<FinancialEntity[]>([]);
    const [searchInput, setSearchInput] = useState<string>("");
    const [showFacetMenu, setShowFacetMenu] = useState(false);
    const searchField = useRef<HTMLInputElement>(null);
    const categoryMenu = useRef<HTMLDivElement>(null);
    const lastSingleSelect = useRef<{id: string, index: number, selected: boolean} | null>(null);

    useEffect(
        () => {
            appStore.set({ isLoading: false });
        },
        []
    );

    /**
     * Every time the accounts selection updates, filteredAccounts should update too
    */
    useEffect(
        () => {
            pd.setSelectedAccounts(pd.accounts.filter((account) => account.isSelected));
        },
        [pd.accounts]
    );

    useEffect(() => {
        // if we have a facet, pre-filter the accounts by category
        if (searchInput.includes("Category:'")) {
            const match = searchInput.match(searchRegex);

            if (match) {
                let [_, categorySearchText, accountSearchText] = match;
                categorySearchText = categorySearchText?.trim().toLowerCase();
                accountSearchText = accountSearchText?.trim().toLowerCase();

                if (categorySearchText) {
                    const categories = coaCategories.filter(x => {
                        if (categorySearchText) {
                            return x.name.toLowerCase().includes(categorySearchText);
                        }
                    });

                    const childAccounts: SelectableFinancialEntity[] = [];
                    for (const category of categories) {
                        childAccounts.push(...getChildAccounts(category));
                    }

                    const filteredChildren = pd.accounts.filter(item =>
                            childAccounts.some(({ id }) => id === item.id)
                    );

                    setFilteredAccounts(getFilteredAccounts(filteredChildren, accountSearchText?.trim() ?? ""));
                }
            }
        } else {
            const filterInput = searchInput.trim();
            setFilteredCategories(getFilteredCategories(coaCategories, filterInput));
            setFilteredAccounts(getFilteredAccounts(pd.accounts, filterInput));
        }
    }, [pd.accounts, searchInput]);

    useEffect(() => {
        // This has to be cleared when the search happens.
        lastSingleSelect.current = null;
    }, [searchInput]);

    useEffect(
        () => {
            if (pd.selectedAccounts.length === 0) {
                pd.setAccounts(pd.accounts.map((each) => ({
                    ...each,
                    isSelected: false,
                })));
                setFilteredAccounts(filteredAccounts.map((each) => ({
                    ...each,
                    isSelected: false,
                })));
            }
        },
        [pd.selectedAccounts.length]
    );

    // handle hiding the facet overlay menu
    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            const target = event.target as Node | null;
            if (!target) {
                return;
            }

            if (
                event.target &&
                showFacetMenu &&
                categoryMenu.current &&
                !categoryMenu.current.contains(target) &&
                searchField.current &&
                !searchField.current.contains(target)
            ) {
                setShowFacetMenu(false);
            }
        }

        if (showFacetMenu) {
            document.addEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showFacetMenu]);

    const handleVersionTypeSelect = (selectedVersionType: TVersionTypeDropdownItem) => {
        pd.setVersionType(selectedVersionType);
    };

    const handleCategoryClick = (name: string) => {
        if (searchField.current) {
            searchField.current.focus();
            setShowFacetMenu(false);
        }
        handleSearchInputChange(`Category:'${name}' `);
    };

    const handleSearchInputChange = (value: string) => {
        setSearchInput(value);

        const trimmedValue = value.trim();
        setShowFacetMenu(trimmedValue !== "" && !trimmedValue.includes("Category:'"));
    };

    const handleSelect = (
            accountList: SelectableFinancialEntity[],
            selectedAccount: SelectableFinancialEntity,
            isSelected: boolean,
            index: number,
            shiftHeld: boolean
    ) => {
        if(shiftHeld && lastSingleSelect.current && lastSingleSelect.current.index !== index) {
            const lastSelected = lastSingleSelect.current;
            let startIndex = index;
            let endIndex = lastSelected.index;
            if(startIndex > endIndex) {
                const tmp = startIndex;
                startIndex = endIndex;
                endIndex = tmp;
            }

            const updatedAccountIds = new Set<string>();
            for(let i = startIndex; i <= endIndex && i < accountList.length; i++) {
                //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                updatedAccountIds.add(accountList[i]!.id);
            }
            const updatedAccounts = pd.accounts.map(item => {
                if(updatedAccountIds.has(item.id)) {
                    return {
                        ...item,
                        isSelected: lastSelected.selected
                    };
                }
                return item;
            });
            pd.setAccounts(updatedAccounts);
        } else {
            const updatedAccounts = pd.accounts.map(item => {
                if(item.id === selectedAccount.id) {
                    return {
                        ...item,
                        isSelected: isSelected
                    };
                }
                return item;
            });
            lastSingleSelect.current = {
                id: selectedAccount.id,
                selected: isSelected,
                index: index
            };
            pd.setAccounts(updatedAccounts);
        }
    };

    const handleSelectAll = () => {
        if (searchInput.length > 0) {
            pd.setAccounts(pd.accounts.map((account: SelectableFinancialEntity) => {
                if (filteredAccounts.some(x => x.id == account.id)) {
                    return { ...account, isSelected: !areAllFilteredAccountsSelected(filteredAccounts) };
                }
                return account;
            }));
        } else {
            pd.setAccounts(pd.accounts.map((account: SelectableFinancialEntity) => ({ ...account, isSelected: !areAllAccountsSelected(pd.accounts) })));
        }
    };

    const renderRow = (accountList: SelectableFinancialEntity[], currentAccount: SelectableFinancialEntity, index: number) => {
        return (
            <DelayRender
                timeoutMs={1000}
                uniqueId={currentAccount.id}
                children={
                    canRender =>
                        <Row
                            key={currentAccount.id}
                            className={`${css.noMargin} ${css.bodyRow}`}
                            aria-rowindex={index + 1}
                        >
                            <Col
                                className={css.tableCell}
                                style={inlineStyles.lockBodyCell}
                            >
                                {pd.budgetYear && canRender &&
                                    <LockButton
                                        accountId={currentAccount.id}
                                        propertyId={propertyId}
                                        budgetYear={pd.budgetYear}
                                        versionType={pd.versionType}
                                        isConfigureModalOpen={pd.isConfigureModelingMethodsModalOpen}
                                        updateAccountLocksByAccountId={pd.updateAccountLocksByAccountId}
                                        updateAccountLocksDraftByAccountId={pd.updateAccountLocksDraftByAccountId}
                                        isLocked={pd.isPropertyLocked || pd.accountLocksDraft.get(currentAccount.id)}
                                        disabled
                                    />
                                }
                            </Col>
                            <Col className={css.tableCell} style={inlineStyles.accountCell}>
                                <Field>
                                    <Checkbox
                                            checked={currentAccount.isSelected}
                                            onClick={event => handleSelect(accountList, currentAccount, event.currentTarget.checked, index, event.shiftKey)}
                                            onChange={(_event) => {/* shut up react warning */}}
                                    >
                                    <Label
                                        isRegular
                                        className={`${css.accountName} ${css.checkboxLabel}`}
                                    >
                                        <span className={css.accountNameText}>{currentAccount.number} {currentAccount.name}</span>
                                    </Label>
                                    </Checkbox>
                                </Field>
                            </Col>
                            <Col className={css.tableCell}>
                                <div className={css.formulaBarCell}>
                                    {canRender &&
                                    <PropertyDriversFormulaBar
                                        accountId={currentAccount.id || ''}
                                        propertyId={propertyId}
                                        isFxBarReadOnly
                                        pd={pd}
                                    />}
                                </div>
                            </Col>
                        </Row>
                }
            />
        );
    };

    return (
        <ThemeProvider>
            <Grid className={`${css.selectedAccountsGrid} ${css.noPadding}`}>
                <Row className={`${css.searchWrapper} ${css.noMargin}`}>
                    <Col className={`${css.searchWrapperCol} ${css.wrapperTitle}`}>
                        Configure GL accounts
                    </Col>
                    <Col className={css.searchWrapperCol}>
                        <form className={css.searchBarForm}>
                            <Field className={css.searchBarField}>
                                <Input
                                    ref={searchField}
                                    placeholder="Search"
                                    value={searchInput}
                                    onChange={event => {
                                        handleSearchInputChange(event.currentTarget.value);
                                    }}
                                    onFocus={event => {
                                        if (event.currentTarget.value.trim() != "" && !event.currentTarget.value.includes("Category:'")) {
                                            setShowFacetMenu(true);
                                        }
                                    }}
                                    className={css.searchBarInput}
                                />
                                <div ref={categoryMenu} className={cn(css.facetMenu, showFacetMenu && css.isVisible)}>
                                    <Button
                                        key={1}
                                        size="small"
                                        isPill
                                        onClick={() => { handleCategoryClick(searchInput) }}
                                    >
                                        Category contains '{searchInput}'
                                    </Button>
                                    {
                                        filteredCategories.slice(0, 4).map(category => (
                                            <Button
                                                key={category.id}
                                                size="small"
                                                isPill
                                                onClick={() => { handleCategoryClick(category.name) }}
                                            >
                                                Category is '{category.name}'
                                            </Button>
                                        ))
                                    }
                                </div>
                            </Field>
                        </form>
                    </Col>
                    <Col className={css.searchWrapperCol}>
                        <Dropdown
                            selectedItem={pd.versionType}
                            onSelect={handleVersionTypeSelect}
                        >
                            <DropdownField className={css.dropdownField}>
                                <Select className={css.dropdownSelect}>
                                    <div className={css.dropdownLabel}>
                                        Forecast Period
                                    </div>
                                    <div className={css.selectedItem}>
                                        {pd.versionType} {pd.year}
                                    </div>
                                </Select>
                            </DropdownField>
                            <Menu>
                                {VERSION_TYPES.map(each => (
                                <Item key={each} value={each}>
                                    {each}
                                </Item>
                                ))}
                            </Menu>
                        </Dropdown>
                    </Col>
                </Row>
                <Row className={css.noMargin}>
                    <Col className={css.noPadding}>
                        <Table className={css.driversTable}>
                            <Head isSticky>
                                <HeaderRow className={css.headerRow}>
                                    <HeaderCell
                                        className={css.tableCell}
                                        style={inlineStyles.lockCell}
                                        width="50px"
                                    >
                                        <img src={lockOnDisabled} alt="lock" width="15px" height="15px" />
                                    </HeaderCell>
                                    <HeaderCell className={css.tableCell}>
                                        <Field>
                                            <Checkbox
                                                onChange={handleSelectAll}
                                                checked={isSelectAllSelected(pd.accounts, filteredAccounts, searchInput)}
                                                indeterminate={isIndeterminate(pd.accounts, filteredAccounts, searchInput)}
                                            >
                                                <Label className={css.checkboxLabel}>
                                                    GL Account
                                                </Label>
                                            </Checkbox>
                                        </Field>
                                    </HeaderCell>
                                    <HeaderCell className={css.tableCell}>
                                        Formula
                                    </HeaderCell>
                                </HeaderRow>
                            </Head>
                        </Table>
                        <Infinite className={css.infiniteTable} containerHeight={800} elementHeight={56} preloadBatchSize={1}>
                            {filteredAccounts.map((currentAccount, index) => (
                                <div key={index}>
                                {renderRow(filteredAccounts, currentAccount, index)}
                                </div>
                            ))}
                        </Infinite>
                    </Col>
                </Row>
            </Grid>
        </ThemeProvider>
    );
}

const inlineStyles = ({
    lockCell: {
        paddingLeft: "16px",
    },
    lockBodyCell: {
        display: "flex",
        flex: "0 0 50px",
        paddingLeft: "16px",
    },
    accountCell: {
        display: "flex",
        alignItems: "center",
    }
});
