import { ReactElement } from "react";
import css from "./expandable-section.module.scss";
import cn from "classnames";
import { Checkbox, Field, Label } from "@zendeskgarden/react-forms";
import { Warning } from "@material-ui/icons";


export interface IExpandableSectionProps {
    isExpanded: boolean;
    children: ReactElement | ReactElement[];
    label?: string;
    onClick?: () => void;
    inError?: boolean;
}

export default function ExpandableSection({isExpanded, label, onClick, children, inError}: IExpandableSectionProps): ReactElement {
    const showError = inError && isExpanded;

    return (
        <div className={cn(css.wrapper, isExpanded && css.isExpanded)}>
            <div className={css.header}>
                <Field className={css.checkbox}>
                    <Checkbox checked={isExpanded} onClick={onClick}>
                        <Label className={css.checkboxLabel}>{label}</Label>
                    </Checkbox>
                </Field>
                <Warning className={cn(css.errorIcon, showError && css.isVisible)} />
            </div>

            <div className={css.content}>
                <div className={css.contentInner}>
                    <div className={css.innerPaddingSpacer}></div>
                    { children }
                </div>
            </div>
        </div>
    )
}
