import React, { ReactElement, useContext, useEffect, useRef, useState } from "react";
import { Col as GridCol, Grid, Row as GridRow, Col } from "@zendeskgarden/react-grid";
import { ThemeProvider } from "@zendeskgarden/react-theming";

import { ViziblyTheme } from "../analyst/ViziblyZDGTheme";
import { FinancialEntity, useChartOfAccounts } from "../../contexts/chartofaccounts/ChartOfAccountsContext";
import { BackgroundJob, FinancialEntityType, JobStatus, JobType, VersionType, useGetJobLazyQuery, useGetUserPropertiesForecastLocksLazyQuery, useListJobsLazyQuery, useSetForecastPeriodLocksMutation } from "../../__generated__/generated_types";

import * as css from "./styles/propertyDrivers.module.scss";
import ConfigureModelingMethodsBtn from "./components/ConfigureModelingMethodsBtn";
import usePropertyDrivers, {ActiveView, IUsePropertyDriversReturn} from "./logic/usePropertyDrivers";
import SelectAccounts from "./components/SelectAccounts";
import EditFormulas from "./components/EditFormulas";
import EditAssumptions from "./components/EditAssumptions";
import CopyDriversBtn from "./components/CopyDriversToProperties";
import { Body, Close, Header, Modal } from "@zendeskgarden/react-modals";
import ProgressIndicator from "./components/ProgressIndicator";
import CopyDriversToPeriod from "./components/CopyDriversToPeriod";
import { ReactComponent as LockIcon } from '@zendeskgarden/svg-icons/src/16/lock-locked-stroke.svg';
import { Field, Label, Toggle } from "@zendeskgarden/react-forms";
import { ListJobs, ListJobsDisplayColumn, ListJobsRef } from "../admin/jobs/ListJobs";
import { Button } from "@zendeskgarden/react-buttons";
import { History } from "@material-ui/icons";
import { AuthContext } from "../../contexts/AuthContext";
import { renderJobInformation } from "./components/shared";
import {Property, useProperties} from "../../contexts/properties/PropertiesContext";
import {BulkAddDrivers} from "./components/BulkAddDrivers";

export interface SelectableFinancialEntity extends FinancialEntity {
    isSelected: boolean;
}

export type TVersionTypeDropdownItem = VersionType.Budget | VersionType.Reforecast;


export default function PropertyDrivers(): ReactElement {
    const pd = usePropertyDrivers();
    const coa = useChartOfAccounts();
    const properties = useProperties();
    const listJobsRef = useRef<ListJobsRef>(null);
    const authContext = useContext(AuthContext);

    const [getLockedProperties, {data: lockedProperties, loading: lockedPropertiesLoading}] = useGetUserPropertiesForecastLocksLazyQuery({
        fetchPolicy: "no-cache",
    });
    const [listJobs] = useListJobsLazyQuery({
        fetchPolicy: "no-cache",
        notifyOnNetworkStatusChange: true,
        onCompleted: data => {
            const sortedJobs = data.listJobs.items.sort((a, b) => {
                return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
            });
            const latestJob = sortedJobs[0];

            if (latestJob) {
                setMostRecentJob(latestJob);
            }
        }
    });
    const [getJob] = useGetJobLazyQuery({
        fetchPolicy: "no-cache",
        notifyOnNetworkStatusChange: true,
        onCompleted: data => {
            if (data.getJob) {
                setMostRecentJob(data.getJob);
            }
        }

    });

    const [isLocked, setIsLocked] = useState<boolean>(false);
    const [isCopyPeriodLocked, setIsCopyPeriodLocked] = useState<boolean>(false);
    const [showJobLog, setShowJobLog] = useState(false);
    const [mostRecentJob, setMostRecentJob] = useState<BackgroundJob | null>(null);
    const [updatePeriodLock] = useSetForecastPeriodLocksMutation({
        onCompleted: () => {
            getLockedProperties({
                variables: {
                    budgetYear: pd.versionType == VersionType.Reforecast ? pd.year + 1 : pd.year,
                }
            });
        }
    });

    //TODO: Maybe move this to usePropertyDrivers?
    const [isBulkAddModelOpen, setIsBulkAddModelOpen] = useState<boolean>(false);

    useEffect(() => {
        if (authContext.user) {
            listJobs({
                variables: {
                    page: 1,
                    take: 10,
                    jobType: JobType.CopyDrivers,
                    userId: authContext.user.id,
                }
            });
        }
    }, [authContext]);

    useEffect(() => {
        if (!pd.year) {
            return;
        }

        getLockedProperties({
            variables: {
                budgetYear: pd.versionType == VersionType.Reforecast ? pd.year + 1 : pd.year,
            }
        });
    }, [pd.year]);

    useEffect(() => {
        if (!pd.currentProperty || !lockedProperties?.userPropertiesForecastLocks || lockedPropertiesLoading) {
            return;
        }

        const rfcstLocked = lockedProperties.userPropertiesForecastLocks.reforecastLocked.includes(pd.currentProperty.id) ?? false;
        const bdgtLocked = lockedProperties.userPropertiesForecastLocks.budgetLocked.includes(pd.currentProperty.id) ?? false;

        setIsLocked(pd.versionType == VersionType.Reforecast ? rfcstLocked : bdgtLocked);
        setIsCopyPeriodLocked(pd.versionType == VersionType.Reforecast ? bdgtLocked : rfcstLocked);

    }, [pd.currentProperty, lockedProperties?.userPropertiesForecastLocks, lockedPropertiesLoading]);

    useEffect(
        () => {
            if (!coa.isReady || coa.chartOfAccounts == undefined || !coa.chartOfAccountsFlat) {
                return;
            }

            /**
             * We only need the "ACCOUNT" type objects from the CoA
             * Sorting them by their order keeps the relevant accounts together
             * Initialize all accounts with `isSelected: false`
             */
            const accountsToRender = coa.chartOfAccountsFlat
                .filter((each) => each.type === FinancialEntityType.Account)
                .sort((a, b) => a.order - b.order)
                .map((each) => ({ ...each, isSelected: false }));

            pd.setAccounts(accountsToRender);
        },
        [coa.isReady]
    );

    useEffect(() => {
        if(mostRecentJob === null) {
            return;
        }

        const interval = setInterval(() => {
            if (mostRecentJob.status === JobStatus.Completed || mostRecentJob.status === JobStatus.Failed) {
                clearInterval(interval);
            } else {
                getJob({
                    variables: {
                        jobId: mostRecentJob.id,
                    },
                });

                if (showJobLog && listJobsRef.current) {
                    listJobsRef.current.reload();
                }
            }
        }, 5000);

        return () => clearInterval(interval);
    }, [mostRecentJob]);

    const renderConfigureModelingMethodsViews = () => {
        const renderViewByActiveViewType = () => {
            switch (pd.activeView) {
                case ActiveView.editFormulas: {
                    return <EditFormulas pd={pd} />;
                }
                case ActiveView.editAssumptions: {
                    return <EditAssumptions pd={pd} />;
                }
                // Unlikely
                default: {
                    return <></>;
                }
            }
        };

        return (
            <Modal onClose={() => pd.setIsConfigureModelingMethodsModalOpen(false)} isLarge className={css.configureMmModal}>
                <Header>
                    Configure Modeling Methods
                </Header>
                <Body className={css.modalBody}>
                    <Grid>
                        <ProgressIndicator pd={pd} />
                        {renderViewByActiveViewType()}
                    </Grid>
                </Body>
                <Close aria-label="Close modal" />
            </Modal>
        );
    };

    const renderBulkAddDriversModel = (
            property: Property,
            versionType: VersionType.Budget | VersionType.Reforecast,
            selectedAccounts: SelectableFinancialEntity[]
    ) => {
        return (
            <Modal onClose={() => setIsBulkAddModelOpen(false)} isLarge className={css.configureMmModal}>
                <Header>
                    Bulk Add Drivers
                </Header>
                <Body>
                    <Grid>
                        <BulkAddDrivers
                                property={property}
                                versionType={versionType}
                                accounts={selectedAccounts}
                                cancel={() => setIsBulkAddModelOpen(false)}
                        />
                    </Grid>
                </Body>
                <Close aria-label="Close modal" />
            </Modal>
        );
    };

    return (
        <ThemeProvider theme={ViziblyTheme}>
            <Grid className={`${css.sectionWrapper} ${css.pageHeader}`}>
                <GridRow>
                    <Col>
                        <h3 className={css.pageTitle}>
                            {pd.currentProperty?.name} | {pd.year} {pd.versionType.toLowerCase().capitalize()} {isLocked && <LockIcon className={css.pageTitleLockIcon} />}
                        </h3>
                    </Col>

                    <Col className={css.headerActionsWrapper}>
                        <div>
                            <span className={css.copyJobStatusText} onClick={() => setShowJobLog(true)}>
                                {
                                    mostRecentJob ?
                                        renderJobInformation(mostRecentJob)
                                        : "No copy jobs in progress"
                                }
                            </span>
                        </div>
                        <Button size="small" onClick={() => setShowJobLog(true)}>
                            <Button.StartIcon><History /></Button.StartIcon>
                            View Job History
                        </Button>
                        <Field className={css.headerActionsWrapper}>
                            <Toggle checked={isLocked} onChange={event => {
                                const periodToUpdate = pd.versionType == VersionType.Reforecast ? "isReforecastLocked" : "isBudgetLocked";
                                updatePeriodLock({
                                    variables: {
                                        propertyId: pd.propertyId,
                                        budgetYear: pd.versionType == VersionType.Reforecast ? pd.year + 1 : pd.year,
                                        locks: {
                                            [periodToUpdate]: event.target.checked,
                                        }
                                    }
                                })
                            }}>
                                <Label>Finalize</Label>
                            </Toggle>
                        </Field>
                    </Col>
                </GridRow>
                {
                    coa.chartOfAccountsFlat &&
                        <SelectAccounts coaEntities={coa.chartOfAccountsFlat} pd={pd} />
                }
                {pd.isConfigureModelingMethodsModalOpen && renderConfigureModelingMethodsViews()}
                {isBulkAddModelOpen && pd.currentProperty && renderBulkAddDriversModel(pd.currentProperty, pd.versionType, pd.selectedAccounts)}
                <GridRow className={css.footerWrapper}>
                    <GridCol className={css.counterWrapper}>
                        <div className={css.dummyFlexWrapper}>
                            <div className={css.counter}>
                                {pd.selectedAccounts.length}
                            </div>
                            <div className={css.counterLabel}>
                                GL accounts selected
                            </div>
                        </div>
                    </GridCol>
                    <GridCol className={css.copyDriversBtnCol}>
                        {pd.selectedAccounts.length < 21 ?
                                <ConfigureModelingMethodsBtn isLocked={isLocked} pd={pd} /> :
                                <Button onClick={() => setIsBulkAddModelOpen(true)}>Bulk Add Modeling Methods</Button>
                        }
                    </GridCol>
                    <GridCol className={css.copyDriversBtnCol}>
                        <CopyDriversToPeriod
                            allAccountsCount={pd.accounts.length}
                            selectedAccounts={pd.selectedAccounts}
                            versionType={pd.versionType}
                            isCopyPeriodLocked={isCopyPeriodLocked}
                        />
                    </GridCol>
                    <GridCol className={css.copyDriversBtnCol}>
                        <CopyDriversBtn
                            allAccountsCount={pd.accounts.length}
                            selectedAccounts={pd.selectedAccounts}
                            versionType={pd.versionType}
                            year={pd.year}
                            lockedProperties={lockedProperties}
                            onJobSubmitted={job => setMostRecentJob(job)}
                        />
                    </GridCol>
                </GridRow>
            </Grid>

            {
                showJobLog && coa.chartOfAccountsFlat && properties.properties &&
                    <Modal
                        className={css.jobLogModal}
                        onClose={() => setShowJobLog(false)}
                    >
                        <Header>
                            Modeling Method Copy Job History
                        </Header>
                        <Body className={css.jobLogModalBody}>
                            <ListJobs
                                jobType={JobType.CopyDrivers}
                                ref={listJobsRef}
                                visibleColumns={[
                                    ListJobsDisplayColumn.user,
                                    ListJobsDisplayColumn.status,
                                    ListJobsDisplayColumn.created,
                                    ListJobsDisplayColumn.started,
                                    ListJobsDisplayColumn.ended,
                                    ListJobsDisplayColumn.errorMessage,
                                    ListJobsDisplayColumn.details,
                                ]}
                                accounts={coa.chartOfAccountsFlat}
                                properties={properties.properties}
                            />
                        </Body>
                        <Close aria-label="Close modal" />
                    </Modal>
            }
        </ThemeProvider>
    );
}
