import {HotTable} from "@handsontable/react";
import Handsontable, {CellCoords} from "handsontable";
import {ReactElement, useEffect, useMemo, useRef, useState} from "react";
import 'handsontable/dist/handsontable.full.css';
import {LICENSES} from "../../constants/Licenses";
import * as css from "./styles/accountTable.module.scss";
import {buildHandsonTableSettings} from "./logic/AccountTableSettings";
import {parseNonSavingAccountTableData, placeholderColumns, TAccountTableRowData} from "./data/AccountTableData";
import {
    hotValidUserActions,
    numberOrZero,
    recalculateCustomDriver,
    sanitizeTableInput,
    setForceReadOnlyToMultiPctAccountRow,
    toggleHiddenRows,
    updateRowSummary
} from "./logic/AccountTableHelpers";
import * as CellRenderers from "./CellRenderers";
import {useChartOfAccounts} from "../../contexts/chartofaccounts/ChartOfAccountsContext";
import {
    DriverType,
    GetSinglePropertyDriversAndWorksheetItemsQuery,
    VersionType
} from "../../__generated__/generated_types";
import {Body, Close, Footer, FooterItem, Header, Modal} from "@zendeskgarden/react-modals";
import {IAccountSummaryData} from "../../pages/workflows/account/AccountSummaryContext";
import {Button} from "@zendeskgarden/react-buttons";
import {IDriverRow, mapAccountTableToDrivers} from "./logic/DriverHelpers";

const MAX_ROWS_TO_PASTE = 10;

export type TNonSavingAccountTableDriversAndWorksheetData = Pick<GetSinglePropertyDriversAndWorksheetItemsQuery["singlePropertyAccount"], "accountPercentageDriver" | "worksheetDriver" | "renovationsDriver" | "growthDriver" | "customDriver" | "operationalMetricDriver" | "payrollDriver" | "revenueDriver">;
export type TNonSavingAccountTableCombinedData = {
    driversAndWorksheetData: TNonSavingAccountTableDriversAndWorksheetData,
    financialYearValues: IAccountSummaryData,
};

export type TNonSavingAccountTableProps = {
    year: number,
    reforecastStartMonth: number,
    versionType: VersionType,
    combinedData: TNonSavingAccountTableCombinedData,
    editEnd?: (driverData: Map<string, IDriverRow>) => void,
}

type TOverrideRevertValues = {
    row: number,
    col: number,
    original: number,
    override: number,
}

function isValidPasteValue(text: string): boolean {
    return text
                    .replaceAll("\r\n", "	")
                    .replaceAll("\n", "	")
                    .split("	")
                    .findIndex(val => Number.isNaN(+val))
            == -1;
}

export function NonSavingAccountTable(
    {
         year,
         reforecastStartMonth,
         versionType,
         combinedData,
         editEnd
    }: TNonSavingAccountTableProps
): ReactElement {
    const coa = useChartOfAccounts();
    // register our custom renderers with HoT
    const cellRenderers = CellRenderers;
    Object.entries(cellRenderers).forEach(([key, value]) => {
        Handsontable.renderers.registerRenderer(key, value);
    });

    // ref vars
    const hotRef = useRef<HotTable>(null);
    const originals = useRef<{[key: number]: (number | null)}>({});
    const overrides = useRef<{[key: number]: (number | null)}>({});
    const overrideRevertValues = useRef<TOverrideRevertValues[]>();
    const visibleParentRows = useRef<Set<number>>(new Set<number>());
    const copyPasteCache = useRef<{val: string}>({val: ""});
    const hiddenRows = useRef<number[]>([]);
    const isDriven = useRef<boolean>(false);
    const isRevenueDriven = useRef<boolean>(false);

    const [accPctSaveDebounceRows, setAccPctSaveDebounceRows] = useState<number[]>([]);
    const isSavingAccPctRows = useRef(false);
    const accPctSaveDebounceRowsIndex = useRef<number | null>(null);

    const isForceFetchingAfterBulkUpdateAccPct = useRef(false);

    const [triedPasteRows, setTriedPasteRows] = useState<number>();
    const isReady = useRef<boolean>(false);

    // state vars
    const [showPasteBlockedAlert, setShowPasteBlockedAlert] = useState(false);
    const [data, setData] = useState<TAccountTableRowData[] | undefined>([]);
    const [tableSettings, setTablesettings] = useState<Handsontable.GridSettings | undefined>();

    // This is a total hack because the data state is only ever updated once, but it's manipulated all the time.
    // So, the data array is mutated, but never changed so no useEffect on data will ever work. Hence, this.
    const [dataUpdated, setDataUpdated] = useState<number>(0);


    useEffect(() => {
        if(editEnd && data && data.length > 0) {
            const to = setTimeout(() => {
                editEnd(mapAccountTableToDrivers(data));
            }, 250);
            return () => clearTimeout(to);
        }
    }, [dataUpdated]);

    useEffect(() => {
        let debounce: ReturnType<typeof setTimeout>;
        if (accPctSaveDebounceRows.length > 0 && hotRef.current?.hotInstance) {
            if (!isSavingAccPctRows.current) {
                const multiAccPctRowIndex = data?.findIndex?.(x => x.id == "multi_pct_of_account");

                if (multiAccPctRowIndex != undefined && multiAccPctRowIndex > -1) {
                    isSavingAccPctRows.current = true;
                    setForceReadOnlyToMultiPctAccountRow(hotRef.current.hotInstance, multiAccPctRowIndex, true);
                    hotRef.current.hotInstance.render();
                }
            }
            debounce = setTimeout(() => {
                const toUpdate = new Set<number>();
                let uptoIdx = 0;
                while (toUpdate.size < MAX_ROWS_TO_PASTE && uptoIdx < accPctSaveDebounceRows.length) {
                    const row = accPctSaveDebounceRows[uptoIdx];
                    if (row != undefined) {
                        toUpdate.add(row);
                    }
                    uptoIdx++;
                }
                accPctSaveDebounceRowsIndex.current = uptoIdx;
                if (!data || !hotRef.current?.hotInstance) {
                    return;
                }
                //
                // This code handles two cases:
                // - Multi Percent of Account (where exists row with id == "multi_pct_of_account")
                // - Single Percent of Account (where there is single row with acc percentage driver percentages)
                //
                // For the former case all data is in rows starting next to row with id == "multi_pct_of_account"
                // And ending with the first row with rowData.driverType != DriverType.AccPercentage
                //
                // For the latter case all data is in for pointed to by firstRow
                //
                let firstRow = data.findIndex(x => x.id == "multi_pct_of_account");
                let hasMulti = true;
                if (firstRow == -1) {
                    firstRow = Math.min(...Array.from(toUpdate)) - 1;
                    hasMulti = false;
                }
                let startCol = 1;
                if (versionType == "REFORECAST") {
                    startCol = reforecastStartMonth + 1;
                }
                const avgs = new Array(12 - startCol + 1).fill(0);
                let count = 0;
                for (let row = firstRow+1; ; row++) {
                    const rowData = data[row];
                    if (!rowData || rowData.driverType != DriverType.AccPercentage) { // latter is stop condition to only process Account Percentage rows
                        break;
                    }
                    count++;
                    const rowNumbers: number[] = hotRef.current.hotInstance.getData(row, startCol, row, 12).flatMap((a: any[]) => a.map(v => numberOrZero(v)));
                    for (let col = startCol; col <= 12; col++) {
                        avgs[col - startCol] += rowNumbers[col - startCol];
                    }

                    if (toUpdate.has(row)) {
                        if (rowData.summaryFunction) {
                            updateRowSummary(hotRef.current.hotInstance, row, rowData.summaryFunction, reforecastStartMonth, versionType);
                        }
                    }
                }
                if (hasMulti && count > 0) {
                    for (let col = startCol; col <= 12; col++) {
                        hotRef.current.hotInstance.setDataAtCell(firstRow, col, +(avgs[col - startCol] / count).toFixed(2), "calculation");
                    }
                }
            }, 500);
        }

        return () => {
            clearTimeout(debounce);
        };
    }, [accPctSaveDebounceRows]);


    // start off by parsing our driver and financial data props
    useEffect(() => {
        isReady.current = false;

        if (!combinedData || !versionType || !coa) {
            return;
        }

        //trackedFinancialValues.current = combinedData.financialYearValues;

        const {
            accountPercentageDriver,
            operationalMetricDriver,
            payrollDriver,
            renovationsDriver,
            revenueDriver,
            growthDriver,
            customDriver,
            worksheetDriver} = combinedData.driversAndWorksheetData;

        if (accountPercentageDriver && operationalMetricDriver && payrollDriver && renovationsDriver && revenueDriver && worksheetDriver && growthDriver) {
            isDriven.current = accountPercentageDriver.isDriven
                    || operationalMetricDriver.isDriven
                    || payrollDriver.isDriven
                    || renovationsDriver.isDriven
                    || revenueDriver.isDriven
                    || worksheetDriver.isDriven
                    || customDriver?.isDriven
                    || (growthDriver && growthDriver.length > 0);
            isRevenueDriven.current = revenueDriver.isDriven;
        }

        const parsedData = parseNonSavingAccountTableData(
                {
                    ...combinedData.driversAndWorksheetData,
                    year: year,
                    versionType: versionType,
                    overrideValues: new Array(12).fill(null),
                    originalValuesRaw: new Array(12).fill(null),
                    accountValues: new Array(12).fill(null)
                },
                reforecastStartMonth,
                coa
        );
        setData(parsedData);
    }, [combinedData, versionType]);

    // make sure we have the rfcstStartMonth for the current property before building HoT settings object
    useEffect(() => {
        if (!data || data.length == 0 || !combinedData.driversAndWorksheetData || !versionType) {
            return;
        }

        data.forEach((row, index) => {
            if (row.id) {
                visibleParentRows.current.add(index);
            }
        });

        const settings = buildHandsonTableSettings(
                data,
                overrides.current,
                placeholderColumns,
                reforecastStartMonth,
                versionType,
                visibleParentRows.current,
                isDriven.current,
                false,
        );
        setTablesettings(settings);

        isReady.current = true;

        isForceFetchingAfterBulkUpdateAccPct.current = false;

    }, [data]);


    // The hook is memoized to be able to remove hook with hotinstance.removeHook
    // The removeHook requires to provide the same function reference that was used to add the hook
    // NOTE: this is changed while doing mini account view in executive summary because there
    // appeared to be a bug where the hook was not being removed and it was causing a expand/collapse to not work

    // I did not touch other hooks as they are not causing issues being non-readonly mode only
    const afterOnCellMouseUpHook = useMemo(() => {
        return (_event: MouseEvent, {row, col}: CellCoords, _td: HTMLTableCellElement) => {
            if (!hotRef.current?.hotInstance || !data) {
                return;
            }

            const rowData = data[row];

            if (rowData && rowData.id && col == 0) {
                const isVisible = toggleHiddenRows(hotRef.current.hotInstance, data, rowData.id, row);
                if (isVisible) {
                    visibleParentRows.current.add(row);
                } else {
                    visibleParentRows.current.delete(row);
                }
                hiddenRows.current = hotRef.current.hotInstance.getPlugin('hiddenRows').getHiddenRows();
                hotRef.current.hotInstance.render();
            }
        };
    }, [data]);

    // This is the main hook that sets up initial state, logic, and HoT event hooks
    useEffect(() => {
        if (!data || data.length == 0 || !versionType || !hotRef.current?.hotInstance || !tableSettings) {
            return;
        }

        const rfcstStartMonth = reforecastStartMonth;

        hotRef.current.hotInstance.batch(() => {
            if (!data || data.length == 0 || !versionType || !hotRef.current?.hotInstance || !tableSettings) {
                return;
            }

            data.forEach((rowData, row) => {
                if (rowData.summaryFunction && hotRef.current?.hotInstance) {
                    updateRowSummary(hotRef.current.hotInstance, row, rowData.summaryFunction, rfcstStartMonth, versionType);
                }
            });

            hiddenRows.current = hotRef.current.hotInstance.getPlugin("hiddenRows").getHiddenRows();
            const copyPastePlugin = hotRef.current.hotInstance.getPlugin("copyPaste");

            hotRef.current.hotInstance.updateSettings({
                contextMenu: {
                    items: {
                        "copy": {
                            name: 'Copy',
                            key: 'copy',
                        },
                        "paste": {
                            name: 'Paste',
                            key: 'paste',
                            callback() {
                                navigator.clipboard
                                        .readText()
                                        .then(
                                                (value: string) => {
                                                    if (isValidPasteValue(value)) {
                                                        copyPastePlugin.paste(value);
                                                    }
                                                },
                                                (reason: any) => {
                                                    const message = reason.message;
                                                    if (JSON.stringify(reason).includes("permission denied") || message && message.includes("permission denied")) {
                                                        setShowPasteBlockedAlert(true);
                                                    }
                                                }
                                        );
                                this.listen();
                            }
                        }
                    }
                },
                afterCopy(_, range) {
                    const rangeData = copyPastePlugin.getRangedCopyableData(range);
                    copyPasteCache.current.val = rangeData;
                }
            });

            hotRef.current.hotInstance.removeHook("beforePaste", () => undefined);
            hotRef.current.hotInstance.addHook("beforePaste", (_data, ranges): boolean => {
                let validationResult = true;
                if (ranges.length > 0) {
                    const range = ranges[0];
                    // Be aware that this works accompanied by HoT setting <selectionMode: "range">,
                    if (range) {
                        const rowsInRange = Math.abs(range.endRow - range.startRow) + 1;
                        if (rowsInRange > MAX_ROWS_TO_PASTE) {
                            setTriedPasteRows(rowsInRange);
                            validationResult = false;
                        }
                    }
                }
                return validationResult;
            });

            hotRef.current.hotInstance.removeHook("beforeAutofill", () => undefined);
            hotRef.current.hotInstance.addHook("beforeAutofill", (selectionData, _sourceRange, targetRange, _direction) => {
                let validationResult = true;
                const rowsInRange = Math.abs(targetRange.to.row - targetRange.from.row) + 1;
                if (rowsInRange > MAX_ROWS_TO_PASTE) {
                    setTriedPasteRows(rowsInRange);
                    validationResult = false;
                }
                return validationResult ? selectionData : false;
            });


            hotRef.current.hotInstance.removeHook("beforeChange", () => undefined);
            hotRef.current.hotInstance.addHook("beforeChange", (changes, _source) => {
                for (let i = 0; i < changes.length; i++) {
                    const change = changes[i];

                    if (!change) {
                        return;
                    }

                    change[3] = sanitizeTableInput(change[3]);
                }
            });

            hotRef.current.hotInstance.removeHook("afterChange", () => undefined);
            hotRef.current.hotInstance.addHook("afterChange", (changes, _source) => {
                const source = _source as string;

                if (!changes) {
                    return;
                }

                for (let i = 0; i < changes.length; i++) {
                    const change = changes[i];

                    if (!change) {
                        continue;
                    }

                    const row = change[0];
                    const col = change[1];
                    const oldVal = change[2];
                    const newVal = change[3];

                    if (row == undefined || col == undefined || oldVal === undefined || newVal == undefined || oldVal === newVal) {
                        continue;
                    }

                    const rowData = data[row];
                    if (!rowData || !hotRef.current?.hotInstance) {
                        continue;
                    }

                    const colIndex = typeof col === 'string' ? hotRef.current.hotInstance.propToCol(col) : col + 1;

                    // TODO combine and debounce for other save calls, like how is done for overrides - jonathan
                    switch (rowData.rowType) {
                        case "driver": {
                            if (hotValidUserActions.includes(source)) {
                                const parentRow = data.findIndex(x => x.id == rowData.parentRowId);

                                switch (rowData.driverType) {
                                    case "customDriver": {
                                        if (!rowData.parentRowId || colIndex > 12) {
                                            continue;
                                        }

                                        recalculateCustomDriver(hotRef.current.hotInstance, parentRow, colIndex);
                                        break;
                                    }
                                    case DriverType.AccPercentage: {
                                        if (rowData.id == "multi_pct_of_account") {
                                            if (colIndex < 1 || colIndex > 12) {
                                                continue;
                                            }
                                        }

                                        if (!rowData.parentRowId) {
                                            continue;
                                        }

                                        setAccPctSaveDebounceRows(prev => [...prev, row]);
                                        break;
                                    }
                                }

                                if (rowData.summaryFunction && rowData.driverType != DriverType.AccPercentage && rowData.parentRowId != "multi_pct_of_account") {
                                    updateRowSummary(hotRef.current.hotInstance, row, rowData.summaryFunction, rfcstStartMonth, versionType);
                                }
                            }
                        }
                    }

                    const summaryRowIndex = data.findIndex(x => x.rowType == "versionSummary");
                    const colOriginal = originals.current[colIndex - 1];
                    const colOverride = overrides.current[colIndex - 1];

                    if (hotValidUserActions.includes(source) && rowData.contributesToSummary) {
                        if (colOriginal != undefined) {
                            let newColOriginal = colOriginal + (newVal - oldVal);
                            if (rowData.driverType === DriverType.AccPercentage && rowData.parentRowId == "multi_pct_of_account") {
                                // Acct Percentage is now special. We have min/max values
                                // that apply to the total of all the account percentage rows.
                                // That means we have to calculate all the account percentage values
                                // all the time on any update. Hooray!
                                // This may be slow depending, but we'll have to deal with it.
                                // At worst, we have to cache values for each account percentage row.

                                // Get all the account percentage drivers we aren't operating on :)
                                const allPercentageDrivers = data
                                        .map((d, idx): [TAccountTableRowData, number] => [d, idx])
                                        .filter(t =>
                                                t[0].driverType === DriverType.AccPercentage && t[0].id !== "multi_pct_of_account" && t[1] !== row);

                                let total = 0;
                                for(const t of allPercentageDrivers) {
                                    const rowData = t[0];
                                    const rowIdx = t[1];
                                    const amount = rowData.context?.["amounts"]?.[colIndex - 1] ?? 0;
                                    const currVal = hotRef.current.hotInstance.getDataAtCell(rowIdx, colIndex);
                                    total += amount * (currVal / 100.0);
                                }

                                const amount = rowData.context?.["amounts"]?.[colIndex - 1] ?? 0;
                                let newTotal = total + (amount * (newVal / 100.0));
                                let oldTotal = total + (amount * (oldVal / 100.0));

                                if(Math.abs(newTotal - oldTotal) > 0.001) {
                                    // We have actually changed the total, so we'll proceed with it.
                                    const augments = combinedData.driversAndWorksheetData.accountPercentageDriver?.augments;
                                    if (augments) {
                                        if (augments.minValue !== null && augments.minValue !== undefined) {
                                            const minValue = parseFloat(augments.minValue);
                                            if (newTotal < minValue) {
                                                newTotal = minValue;
                                            }
                                            if (oldTotal < minValue) {
                                                oldTotal = minValue;
                                            }
                                        }
                                        if(augments.maxValue !== null && augments.maxValue !== undefined) {
                                            const maxValue = parseFloat(augments.maxValue);
                                            if (newTotal > maxValue) {
                                                newTotal = maxValue;
                                            }
                                            if (oldTotal > maxValue) {
                                                oldTotal = maxValue;
                                            }
                                        }
                                    }

                                    newColOriginal = colOriginal + (newTotal - oldTotal);
                                } else {
                                    // We have not changed the total, so we just don't bother setting a new value.
                                    newColOriginal = colOriginal;
                                }
                            }
                            originals.current[colIndex - 1] = newColOriginal;
                        }

                        const newTotal = originals.current[colIndex - 1];

                        if (newTotal != undefined && colOverride == null) {
                            hotRef.current.hotInstance.setDataAtCell(summaryRowIndex, colIndex, Math.round(newTotal), "computedSummary");
                        }
                    }

                    // if the change is in response to one of our recalculations, make sure to update the row summary
                    if (source == "computedDriver" && rowData.summaryFunction) {
                        updateRowSummary(hotRef.current.hotInstance, row, rowData.summaryFunction, rfcstStartMonth, versionType);

                        if (colOriginal != undefined) {
                            originals.current[colIndex - 1] = colOriginal + (newVal - oldVal);
                        }

                        const newTotal = originals.current[colIndex - 1];

                        if (newTotal != undefined && colOverride == null) {
                            hotRef.current.hotInstance.setDataAtCell(summaryRowIndex, colIndex, Math.round(newTotal), "computedSummary");
                        }
                    }

                    if (source == "computedSummary" && rowData.summaryFunction) {
                        updateRowSummary(hotRef.current.hotInstance, row, rowData.summaryFunction, 0, versionType);
                    }
                }
                // This isn't guaranteed to be unique if the user can move fast enough.
                // In practice, a user shouldn't be able to enter data that fast.
                setDataUpdated(Date.now());
            });

            hotRef.current.hotInstance.removeHook("afterOnCellMouseUp", afterOnCellMouseUpHook);
            hotRef.current.hotInstance.addHook("afterOnCellMouseUp", afterOnCellMouseUpHook);

            hotRef.current.hotInstance.removeHook("beforeOnCellContextMenu", () => undefined);

            hotRef.current.hotInstance.addHook("beforeOnCellContextMenu", function(_event, _, _td) {
                const hot = hotRef.current?.hotInstance;
                if (!hot) {
                    return;
                }
                const selectedRange = hot.getSelectedRange()?.flatMap(range => range.getAll()) ?? [];
                const overridesToRevert: TOverrideRevertValues[] = [];

                for (const {row, col} of selectedRange) {
                    if (row < 0 || col < 0) {
                        continue;
                    }
                    const cell = hot.getCellMeta(row, col);
                    const originalValue = originals.current[col - 1];
                    const overrideValue = overrides.current[col - 1];

                    if (cell.renderer == "ForecastMonthCalculationOverrideable" && overrideValue != null && originalValue != undefined) {

                        overridesToRevert.push({
                            row,
                            col: col - 1,
                            original: originalValue,
                            override: hot.getDataAtCell(row, col),
                        });
                    }
                }
                if (overridesToRevert.length > 0) {
                    overrideRevertValues.current = overridesToRevert;
                }
                else {
                    overrideRevertValues.current = undefined;
                }
            });

            hotRef.current.hotInstance.removeHook("beforeUndoStackChange", () => undefined);
            hotRef.current.hotInstance.addHook("beforeUndoStackChange", (_doneActions, source) => {
                if (source && !hotValidUserActions.includes(source)) {
                    return false;
                }
            });

            // This is nasty but I did not find better way of keeping the styling in place while batch updates go through
            hotRef.current.hotInstance.removeHook("afterCellMetaReset", () => undefined);
            hotRef.current.hotInstance.addHook("afterCellMetaReset", () => {
                if (isSavingAccPctRows.current || isForceFetchingAfterBulkUpdateAccPct.current) {
                    const multiAccPctRowIndex = data?.findIndex?.(x => x.id == "multi_pct_of_account");

                    if (multiAccPctRowIndex != undefined && multiAccPctRowIndex > -1 && hotRef.current?.hotInstance) {
                        setForceReadOnlyToMultiPctAccountRow(hotRef.current.hotInstance, multiAccPctRowIndex, true);
                    }
                }
            });
        });
    }, [tableSettings, overrideRevertValues.current]);

    return (
            <div className={css.accountTableWrapper}>
                {data && data.length > 0 &&
                    <HotTable
                        key={isReady.current ? "blue" : "green"}
                        ref={hotRef}
                        className={css.accountTable}
                        settings={tableSettings}
                        licenseKey={LICENSES.HandsOnTable}
                        fixedRowsBottom={
                                data?.filter(x => ["versionSummary", "historical", "versionType"].includes(x.rowType)).length ?? 2
                        }
                    />
                }
                {triedPasteRows != undefined &&
                        <Modal onClose={() => setTriedPasteRows(undefined)} isLarge>
                            <Header isDanger>
                                Can't update
                            </Header>
                            <Body>
                                You are attempting to update {triedPasteRows} rows at once which is more than {MAX_ROWS_TO_PASTE} allowed.
                            </Body>
                            <Footer>
                                <FooterItem>
                                    <Button onClick={() => setTriedPasteRows(undefined)} isBasic>
                                        Ok
                                    </Button>
                                </FooterItem>
                            </Footer>
                            <Close aria-label="Close modal" />
                        </Modal>
                }
                {showPasteBlockedAlert &&
                        <Modal onClose={() => setShowPasteBlockedAlert(false)}>
                            <Header isDanger>
                                Can't paste
                            </Header>
                            <Body>
                                To be able to paste into you need to enable clipboard access in your browser settings.
                            </Body>
                            <Footer>
                                <FooterItem>
                                    <Button onClick={() => setShowPasteBlockedAlert(false)}>
                                        Ok
                                    </Button>
                                </FooterItem>
                            </Footer>
                            <Close aria-label="Close modal" />
                        </Modal>
                }
            </div>
    );
}