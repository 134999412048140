import {GetPayrollCompensationItemsQuery, PayrollCompensationItemType, PayrollPrimaryCompensationItem} from "../../../../__generated__/generated_types";

const BASE_COMP_ITEMS: string[] = [
    PayrollPrimaryCompensationItem.Compensation,
    PayrollPrimaryCompensationItem.Overtime,
    PayrollPrimaryCompensationItem.Bonus,
];

const BASE_COMP_ITEMS_NAMES: Record<string, string> = {
    [PayrollPrimaryCompensationItem.Compensation]: "Base Pay",
    [PayrollPrimaryCompensationItem.Overtime]: "Overtime",
    [PayrollPrimaryCompensationItem.Bonus]: "Bonuses",
};

const KNOWN_COMP_ITEM_TYPES_ORDERED: string[] = [
    PayrollCompensationItemType.Medical,
    PayrollCompensationItemType.Dental,
    PayrollCompensationItemType.Vision,
    PayrollCompensationItemType.Retirement_401KMatch,
    PayrollCompensationItemType.MedicareTax,
    PayrollCompensationItemType.SocialSecurityTax,
    PayrollCompensationItemType.FederalUnemploymentTax,
    PayrollCompensationItemType.StateUnemploymentTax,
    PayrollCompensationItemType.WorkersCompensation,
];

const KNOWN_COMP_ITEM_TYPE_NAMES: Record<string, string> = {
    [PayrollCompensationItemType.Medical]: "Medical",
    [PayrollCompensationItemType.Dental]: "Dental Benefits",
    [PayrollCompensationItemType.Vision]: "Vision",
    [PayrollCompensationItemType.Retirement_401KMatch]: "401K",
    [PayrollCompensationItemType.MedicareTax]: "Medicare Tax",
    [PayrollCompensationItemType.SocialSecurityTax]: "Social Security Tax",
    [PayrollCompensationItemType.FederalUnemploymentTax]: "Federal Unemployment Tax",
    [PayrollCompensationItemType.StateUnemploymentTax]: "State Unemployment Tax",
    [PayrollCompensationItemType.WorkersCompensation]: "Workers Compensation",
};



export function buildCompItemsDisplayData(compItemsData: GetPayrollCompensationItemsQuery) {
    const compItemIds = [...BASE_COMP_ITEMS];
    const compItemNames: Record<string, string> = {
        ...BASE_COMP_ITEMS_NAMES
    };
    for (const knownCompItemType of KNOWN_COMP_ITEM_TYPES_ORDERED) {
        const compItem = compItemsData.listCompensationItems.find(dbData => dbData.type === knownCompItemType);
        if (!compItem) {
            continue;
        }
        compItemIds.push(compItem.id);
        compItemNames[compItem.id] = KNOWN_COMP_ITEM_TYPE_NAMES[compItem.type] ?? ""; // coalesce should never happen
    }
    let customItems = compItemsData.listCompensationItems.filter(ci => ci.type === PayrollCompensationItemType.Custom);
    customItems = customItems.sortBy("customName");
    for (const dbCompItem of customItems) {
        compItemIds.push(dbCompItem.id);
        compItemNames[dbCompItem.id] = dbCompItem.customName ?? "";
    }
    return {compItemIds, compItemNames};
}