import { Button } from "@zendeskgarden/react-buttons";
import { Dropdown, Item, Menu, NextItem, PreviousItem, Trigger } from "@zendeskgarden/react-dropdowns";
import { ThemeProvider } from "@zendeskgarden/react-theming";
import { ReactElement, useContext, useState } from "react";
import { ViziblyTheme } from "../../../../styles/zendesk-garden/ViziblyZDGTheme";
import * as css from "./styles/css.module.scss";
import { useNavigation } from "./navigation/useNavigation";
import { parseNavigationDestination } from "./logic/parseNavigationDestination";
import { FeatureFlagContext } from "../../../../feature-flag/FeatureFlagContextProvider";
import { Feature } from "../../../../feature-flag/Feature";
import { DescriptionOutlined } from "@material-ui/icons";
import { useLocation } from "react-router-dom";
import { ReportsNavMenuConfig } from "./ReportsNavMenuConfig";


enum ReportsNavMenuItem {
    OVERVIEW = "overview",
    LINE_ITEMS = "line_items",
    MONTHLY_VARIANCE_REPORT = "monthly_variance_report",
    BULK_EXPORT = "bulk_export",
    BACK_FROM_BULK_EXPORT = "back_from_bulk_export",
    FINANCIAL_REPORTS = "financial_reports",
    OPERATIONAL_REPORTS = "operational_reports",
    PORTFOLIO_ANALYSIS = "portfolio_analysis",
}

interface IReportsNavMenuState {
    isOpen: boolean;
    tempSelectedItem: ReportsNavMenuItem | undefined;
}

export function ReportsNavMenu(): ReactElement {
    const location = useLocation();
    const navigation = useNavigation();
    const { hasFeature } = useContext(FeatureFlagContext);

    const [state, setState] = useState<IReportsNavMenuState>({
        isOpen: false,
        tempSelectedItem: undefined
    });

    const handleItemSelected = (item: ReportsNavMenuItem) => {
        if (item !== ReportsNavMenuItem.BULK_EXPORT && item !== ReportsNavMenuItem.BACK_FROM_BULK_EXPORT) {
            const parsedDestination = parseNavigationDestination(item);
            navigation.goto(parsedDestination);
        }
    };

    const handleStateChange = (changes: any, stateAndHelpers: any) => {
        const updatedState: any = {};

        if (Object.prototype.hasOwnProperty.call(changes, 'isOpen')) {
            updatedState.isOpen =
            changes.selectedItem === ReportsNavMenuItem.BULK_EXPORT ||
            changes.selectedItem === ReportsNavMenuItem.BACK_FROM_BULK_EXPORT ||
            changes.isOpen;
        }

        if (Object.prototype.hasOwnProperty.call(changes, 'selectedItem')) {
            updatedState.tempSelectedItem = changes.selectedItem;
            stateAndHelpers.setHighlightedIndex(1);
        }

        if (Object.keys(updatedState).length > 0) {
            setState(updatedState);
        }
    };

    function isReportPath(path: string): boolean {
        const validPaths = [
            ReportsNavMenuConfig.OVERVIEW_PATH,
            ReportsNavMenuConfig.LINE_ITEMS_PATH,
            ReportsNavMenuConfig.PROPERTY_EXECUTIVE_SUMMARY,
            ReportsNavMenuConfig.MVR_PATH,
            ReportsNavMenuConfig.FINANCIAL_REPORTS_PATH,
            ReportsNavMenuConfig.OPERATIONAL_REPORTS_PATH,
            ReportsNavMenuConfig.PORTFOLIO_ANALYSIS,
            ReportsNavMenuConfig.MODELING_ANALYSIS
        ];

        return validPaths.includes(path.replace("/", ""));
    }

    const buttonClass = isReportPath(location.pathname) ? css.focusedButton : undefined;

    return (
        <ThemeProvider theme={ViziblyTheme}>
            <Dropdown
                isOpen={state.isOpen}
                onSelect={item => handleItemSelected(item)}
                onStateChange={handleStateChange}
            >
                <Trigger>
                    <Button isBasic className={buttonClass}>
                        <Button.StartIcon>
                            <DescriptionOutlined style={{width: "18px", height: "18px"}} />
                        </Button.StartIcon>
                        <span className={css.reportsLabel}>Reports</span>
                    </Button>
                </Trigger>
                <Menu>
                    {state.tempSelectedItem === ReportsNavMenuItem.BULK_EXPORT ? (
                        <>
                            <PreviousItem value="back_from_bulk_export">Bulk Export</PreviousItem>
                            <Item value="financial_reports">Financial Reports</Item>
                            <Item value="operational_reports">Operational Reports</Item>
                        </>
                    ) : (
                        <>
                            <Item value="overview">Overview</Item>
                            <Item value="property_executive_summary">Executive Summary</Item>
                            <Item value="line_items">Line Items</Item>
                            <Item value="portfolio_analysis">Portfolio Analysis</Item>
                            <Item value="modeling_analysis">Property Explorer</Item>
                            {hasFeature(Feature.MVR) &&
                                <Item value="monthly_variance_report">Monthly Variance Report</Item>
                            }
                            <NextItem value="bulk_export">Bulk Export</NextItem>
                        </>
                    )}
                </Menu>
            </Dropdown>
        </ThemeProvider>
    );
}
