import {ToggleOffOutlined, ToggleOn} from "@material-ui/icons";
import {Button, ToggleButton} from "@zendeskgarden/react-buttons";
import {Field, Label, Message} from "@zendeskgarden/react-forms";
import {Col, Grid, Row} from "@zendeskgarden/react-grid";
import {Body, Close, Footer, FooterItem, Header, Modal} from "@zendeskgarden/react-modals";
import {ReactElement, useState} from "react";

type ColumnData = {key: string, name: string, enabled: boolean;};
type ColumnDataUpdate = Pick<ColumnData, "key" | "enabled">;

export interface SettingsModalProps {
    onClose: () => void;
    onApply: (_: {columns: ColumnDataUpdate[], filterMultiassigned: boolean, filterUnassigned: boolean;}) => void;
    columns: ColumnData[],
    fixedColumns: string[],
    filterMultiassigned: boolean,
    filterUnassigned: boolean;
}

export function SettingsModal(props: SettingsModalProps): ReactElement {
    const [columns, setColumns] = useState(props.columns);
    const [filterMultiassigned, setFilterMultiassigned] = useState(props.filterMultiassigned);
    const [filterUnassigned, setFilterUnassigned] = useState(props.filterUnassigned);
    function setColumnEnabled(key: string, enabled: boolean) {
        setColumns(prev => {
            const updated = prev.map(column => {
                const updatedColumn = {...column};
                if (key === updatedColumn.key) {
                    updatedColumn.enabled = enabled;
                }
                return updatedColumn;
            });
            return updated;
        });
    }
    function setAllColumnsEnabled(enabled: boolean) {
        setColumns(prev => prev.map(column => ({...column, enabled: props.fixedColumns.includes(column.key) ? column.enabled : enabled})));
    }
    return (
        <Modal onClose={props.onClose} isLarge>
            <Header>
                Settings
            </Header>
            <Body>
                <Grid>
                    <Row>
                        <Col>
                            <div style={{height: "32px", display: "flex", flexDirection: "row", alignItems: "center"}}>
                                <Label>Filters</Label>
                            </div>
                            <Message>Filters applied to visible columns only</Message>
                            <div style={{display: "flex", flexDirection: "column", rowGap: "2em", marginTop: "2em"}}>
                                <Field>
                                    <ToggleButton
                                        isBasic
                                        onClick={() => setFilterMultiassigned(prev => !prev)}
                                    >
                                        {filterMultiassigned ?
                                            <ToggleOn fontSize="large" />
                                            : <ToggleOffOutlined fontSize="large" />
                                        }
                                        <span style={{marginLeft: ".5rem"}}>Show multi-assigned costs</span>
                                    </ToggleButton>
                                    <Message>Keeps only lines where some visible cost column has multiple accounts</Message>
                                </Field>
                                <Field>
                                    <ToggleButton
                                        isBasic
                                        onClick={() => setFilterUnassigned(prev => !prev)}
                                    >
                                        {filterUnassigned ?
                                            <ToggleOn fontSize="large" />
                                            : <ToggleOffOutlined fontSize="large" />
                                        }
                                        <span style={{marginLeft: ".5rem"}}>Show unmapped compensation items</span>
                                    </ToggleButton>
                                    <Message>Keeps only lines where some visible cost column does not have assigned account</Message>
                                </Field>
                            </div>
                        </Col>
                        <Col>
                            <div style={{display: "flex", alignItems: "center"}}>
                                <Label>Compensation Items</Label>
                                <div style={{marginRight: 0, marginLeft: "auto"}}>
                                    <Button isBasic size={"small"} onClick={() => setAllColumnsEnabled(true)}>All</Button>
                                    <Button isBasic size={"small"} onClick={() => setAllColumnsEnabled(false)}>None</Button>
                                </div>
                            </div>
                            <div style={{display: "flex", flexDirection: "column"}}>
                                {columns.map(column => (
                                    <ColumnToggleButton
                                        key={column.key}
                                        name={column.name}
                                        value={column.enabled}
                                        setValue={(enabled) => setColumnEnabled(column.key, enabled)}
                                        disabled={props.fixedColumns.includes(column.key)}
                                    />
                                ))}
                            </div>
                        </Col>
                    </Row>
                </Grid>
            </Body>
            <Footer>
                <FooterItem>
                    <Button onClick={props.onClose} isBasic>
                        Cancel
                    </Button>
                </FooterItem>
                <FooterItem>
                    <Button
                        isPrimary
                        onClick={() => props.onApply({
                            columns: columns,
                            filterMultiassigned: filterMultiassigned,
                            filterUnassigned: filterUnassigned
                        })}>
                        Apply
                    </Button>
                </FooterItem>
            </Footer>
            <Close aria-label="Close modal" />
        </Modal>
    );
}

function ColumnToggleButton(props: {name: string, value: boolean, setValue: (v: boolean) => void; disabled: boolean;}): ReactElement {
    const {name, value, setValue, disabled} = props;
    return (
        <ToggleButton
            disabled={disabled}
            isBasic
            onClick={() => setValue(!value)}
            style={{justifyContent: "space-between"}}
        >
            <span style={{marginLeft: ".5rem"}}>{name}</span>
            {value
                ?
                <ToggleOn fontSize="large" />
                : <ToggleOffOutlined fontSize="large" />
            }
        </ToggleButton>
    );
}