import { ReactElement, useEffect, useRef, useState } from "react";

import { TDriverGrowthMetric } from "../../../../../../contexts/account/data/logic/driversAndWorksheetData";
import { DistributionMethod } from "../../../../../../__generated__/generated_types";
import { distributionMethodMap } from "../../../../../../contexts/account/data/utils";
import AnnualTargetValueMenu from "../formula-menu/AnnualTargetValueMenu";

import * as css from "./styles/css.module.scss";
import { deleteNodeKeys, TFormulaNodeProps } from "./logic/formulaNode";
import {IFormulaBarUpdates} from "../../../../../../contexts/account/data/useFormulaBarUpdates";


type TAnnualTargetValueGrowthDriverFxNodeProps = TFormulaNodeProps&{
    drivers: TDriverGrowthMetric[],
}


export function renderAnnualTargetValueNode(
    driver: TDriverGrowthMetric | undefined,
    asJSX: undefined | {
        locked: boolean,
        fbUpdates: IFormulaBarUpdates
    }
): string | JSX.Element {
    const annualTargetValueManualEntry = driver?.annualTargetValueManualEntry || 0;
    if (!asJSX) {
        return `$${annualTargetValueManualEntry}`;
    }
    else if (asJSX.locked) {
        return <>&nbsp;${annualTargetValueManualEntry}</>;
    }
    const annualTargetValueNode = <a style={{marginLeft: "6px"}}>${annualTargetValueManualEntry}</a>;
    return (
        <AnnualTargetValueMenu
            fbUpdates={asJSX.fbUpdates}
            level={0}
            triggerNode={annualTargetValueNode}
        />
    );
}

export function renderDistributionMethodNode(
    driver: TDriverGrowthMetric | undefined,
    asJSX: undefined | {
        locked: boolean,
        fbUpdates: IFormulaBarUpdates
    }
): string | JSX.Element {
    const distributionMethod = driver?.distributionMethod || DistributionMethod.Flat;
    const distributionMethodText = distributionMethodMap[distributionMethod];
    if (!asJSX) {
        return distributionMethodText;
    }
    else if (asJSX.locked) {
        return <>&nbsp;{distributionMethodText}</>;
    }
    const distributionMethodNode = <a style={{marginLeft: "6px"}}>{distributionMethodText}</a>;
    return (
        <AnnualTargetValueMenu
            fbUpdates={asJSX.fbUpdates}
            level={1}
            triggerNode={distributionMethodNode}
        />
    );
}

export default function AnnualTargetValueGrowthDriverFxNode(props: TAnnualTargetValueGrowthDriverFxNodeProps): ReactElement {
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [childNodeHovered, setChildNodeHovered] = useState<boolean>(false);

    const wrapperRef = useRef<HTMLDivElement>(null);


    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                wrapperRef.current &&
                (wrapperRef.current === event.target ||
                wrapperRef.current.contains(event.target as Node))
            ) {
                return;
            }
            setIsFocused(false);
        };

        // Clicking outside the current node should remove focus
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleClick = () => {
        setIsFocused(!isFocused);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (
            isFocused && deleteNodeKeys.includes(e.key)
        ) {
            props.fbUpdates.removeAnnualTargetValueDriver();
        }
    };



    const nodeClassName = props.locked ? css.fxNodeLocked : css.fxNode;
    const className = `${nodeClassName} ${props.className ? props.className : ''} ${css.deletableNode} ${isFocused ? css.focusedNode : ''} ${childNodeHovered ? css.disableHover : ''}`;

    return (
        <div
            className={className}
            onClick={handleClick}
            onKeyDown={handleKeyDown}
            onMouseEnter={() => setChildNodeHovered(false)}
            ref={wrapperRef}
            tabIndex={0}
        >
            (Annual Target Amount:
            <div
                className={css.fxNode}
                onMouseEnter={() => setChildNodeHovered(true)}
                onMouseLeave={() => setChildNodeHovered(false)}
            >
                {renderAnnualTargetValueNode(props.drivers[0], {...props})}
            </div>
            &nbsp;with
            <div
                className={css.fxNode}
                onMouseEnter={() => setChildNodeHovered(true)}
                onMouseLeave={() => setChildNodeHovered(false)}
            >
                {renderDistributionMethodNode(props.drivers[0], {...props})}
            </div>
            )
        </div>
    );
}
