import {Pagination} from "@zendeskgarden/react-pagination";
import {Body, Row, Cell, Head, HeaderCell, HeaderRow, Table, SortableCell} from "@zendeskgarden/react-tables";
import {ReactElement, useMemo} from "react";
import {zdgPaginationTransformPageProps} from "../../styles/zendesk-garden/ZDPaginationTransformPageProps";
import {PaginationParams} from "./usePagination";
import * as css from "./datadisplaytable.module.scss";
import {SortType} from "../../utils/sorting";
import {SortingParams} from "./useSorting";
import cn from "classnames";

/**
 * This component is an attempt to have uniform data display table based on plain html table
 * Features:
 * - Column filters
 * - Pagination
 * Use Cases: Revenue Exception Manager, Payroll Exception Manager
 */
export interface DataProvider<TData, TDataKey extends string> {
    rows: TData[] | undefined;
    totalRows: number | undefined;
    secondHeaderRow?: TData | undefined;
    columns: TDataKey[];
}

export interface DataDisplayTableParams<TData, TDataKey extends string> {
    paginationParams: {
        params: PaginationParams,
        setPage: (page: number) => void;
    };
    sortingParams?: {
        columns: TDataKey[];
        params: SortingParams<TDataKey>,
        toggleSort: (column: TDataKey | undefined) => void;
        currentSortForColumn: (column: TDataKey | undefined) => SortType | undefined;
    };
    dataProvider: DataProvider<TData, TDataKey>;
    headers: Record<TDataKey, string>;

    // This cellDataToDisplay allows to pass in object with additional information in that object
    // Example:
    // type TData = {
    //     property: {
    //         id: string
    //         name: string
    //     },
    //     unitCount: number
    // }
    // function columnDataToString(column, dataRow) {
    //     if (column === "property") {
    //         return dataRow["property"].name
    //     }
    //     return dataRow[column];
    // }
    customCellRenderers?: {
        cellDataToDisplay?: (column: TDataKey, dataRow: TData) => string | ReactElement | number;
        rowKey?: (dataRow: TData) => string | number;
        cellSecondHeaderDataToDisplay?: (column: TDataKey, dataRow: TData) => string | ReactElement | number;
    };
    className?: string | undefined;
}

export function DataDisplayTable<TData extends Record<string, unknown>, TDataKey extends string>(props: DataDisplayTableParams<TData, TDataKey>): ReactElement | null {
    const cellDataToDisplay = useMemo(() => {
        let ret = props.customCellRenderers?.cellDataToDisplay;
        if (!ret) {
            ret = (column: TDataKey, dataRow: TData) => {
                const val = dataRow[column];
                if (typeof val === "string" || typeof val === "number") {
                    return val;
                }
                return JSON.stringify(val);
            };
        }
        return ret;
    }, [props.customCellRenderers?.cellDataToDisplay]);

    const cellSecondHeaderDataToDisplay = useMemo(() => {
        let ret = props.customCellRenderers?.cellSecondHeaderDataToDisplay;
        if (!ret) {
            ret = (column: TDataKey, dataRow: TData) => {
                const val = dataRow[column];
                if (typeof val === "string" || typeof val === "number") {
                    return val;
                }
                return JSON.stringify(val);
            };
        }
        return ret;
    }, [props.customCellRenderers?.cellSecondHeaderDataToDisplay]);

    const headerRow = useMemo(() => {
        return (
            <HeaderRow>
                {props.dataProvider.columns.map((column, i) =>
                    props.sortingParams && props.sortingParams.columns.includes(column)
                        ?
                        <SortableCell
                            key={i}
                            onClick={() => props.sortingParams?.toggleSort(column)}
                            sort={props.sortingParams?.currentSortForColumn(column)}
                        >
                            {props.headers[column] ?? ""}
                        </SortableCell>
                        :
                        <HeaderCell key={i}>
                            {props.headers[column] ?? ""}
                        </HeaderCell>
                )}
            </HeaderRow>
        );
    }, [props.dataProvider.columns, props.headers, props.sortingParams?.params.sortColumn, props.sortingParams?.params.sortType]);

    const tableRows = useMemo(() => {
        if (!props.dataProvider.rows) {
            return new Array(props.paginationParams.params.pageSize).fill(null).map((_, i) =>
                <Row key={`${i}-e`}>
                    {
                        props.dataProvider.columns.map((_, j) =>
                            <Cell key={j}>
                            </Cell>
                        )
                    }
                </Row>
            );
        }
        // To make UI behaviour consistent for user and have no pagination control jumps based
        // on table vertical size I am making the table vertical size consistent for all pages
        // by appending empty rows to fill in difference between page size and actual data rows
        // This usually happens for last page.
        // However when all data fits into a single page and there is no pagination control
        // I do not append empty rows.
        let emptyRows = 0;
        if (props.paginationParams.params.totalPages > 1 &&
            props.paginationParams.params.pageSize > props.dataProvider.rows.length
        ) {
            emptyRows = props.paginationParams.params.pageSize - props.dataProvider.rows.length;
        }
        else if (props.paginationParams.params.totalPages === 1 &&
            props.dataProvider.totalRows !== undefined &&
            props.dataProvider.totalRows > props.dataProvider.rows.length
        ) {
            emptyRows = props.dataProvider.totalRows - props.dataProvider.rows.length;
        }
        return props.dataProvider.rows.map((dataRow, i) =>
            <Row key={props.customCellRenderers?.rowKey?.(dataRow) ?? i}>
                {
                    props.dataProvider.columns.map((column, j) =>
                        <Cell key={j}>
                            {cellDataToDisplay(column, dataRow)}
                        </Cell>
                    )
                }
            </Row>
        ).concat(
            new Array(emptyRows).fill(null).map((_, i) =>
                <Row key={`${i}-e`}>
                    {
                        props.dataProvider.columns.map((_, j) =>
                            <Cell key={j}>
                            </Cell>
                        )
                    }
                </Row>
            )
        );
    }, [props.dataProvider.rows, props.dataProvider.columns]);

    const secondHeaderRow = useMemo(() => {
        const dataRow = props.dataProvider.secondHeaderRow;
        if (!dataRow) {
            return null;
        }
        return (
            <HeaderRow>
                {props.dataProvider.columns.map((column, i) =>
                    <HeaderCell key={i}>
                        {cellSecondHeaderDataToDisplay(column, dataRow)}
                    </HeaderCell>
                )}
            </HeaderRow>
        );
    }, [props.dataProvider.secondHeaderRow, props.dataProvider.columns]);

    const pagination = useMemo(() => {
        const {setPage, params: {totalPages, currentPage}} = props.paginationParams;
        if (totalPages == 1) {
            return null;
        }
        return (
            <Pagination
                className={css.pagination}

                totalPages={totalPages}
                currentPage={currentPage}
                onChange={page => setPage(page)}
                transformPageProps={zdgPaginationTransformPageProps}
            />
        );
    }, [props.paginationParams?.params]);

    return (
        <div className={cn(css.wrapper, props.className)}>
            <div className={css.tableWrapper}>
                <Table>
                    <Head isSticky>
                        {headerRow}
                        {secondHeaderRow}
                    </Head>
                    <Body>{tableRows}</Body>
                </Table>
            </div>
            {pagination}
        </div >
    );
}