import {Body, Close, Header, Modal} from "@zendeskgarden/react-modals";
import {ReactElement} from "react";
import {TData, TDriverInfo} from "../logic";
import * as css from "./diffmodalsingle.module.scss";

export interface IDiffModalSingle {
    label: string;
    first: Pick<TData, "property"> & {driverData: TDriverInfo | null};
    second: Pick<TData, "property"> & {driverData: TDriverInfo | null};
    onClose: () => void;
}

export function DiffModalSingle(props: IDiffModalSingle): ReactElement {
    return (
        <Modal onClose={props.onClose} isLarge className={css.modal}>
            <Header>
                {props.label}
            </Header>
            <Body>
                <div className={css.bodyContent}>
                    <div className={css.description}>
                        <span>Compare driver configuration between properties for selected driver</span>
                    </div>
                    <div className={css.driverLines}>
                        <div className={css.driverLine}>
                            <span>Property</span><span>Configuration</span>
                        </div>
                        <div className={css.driverLine}>
                            <span>{props.first.property.name}</span><span>{props.first.driverData?.content ?? "Not Set"}</span>
                        </div>
                        <div className={css.driverLine}>
                            <span>{props.second.property.name}</span><span>{props.second.driverData?.content ?? "Not Set"}</span>
                        </div>
                    </div>
                </div>
            </Body>
            <Close aria-label="Close modal" />
        </Modal>
    )
}