import {useEffect, useState} from "react";
import {
    buildFilteredData,
    DISPLAY_COLUMNS,
    TData,
    TDataKey,
    TDiffData
} from "./logic";
import {TDriverDataLoader} from "./DriverDataLoader";

interface DriverDataTransformerParams {
    parsedData: TDriverDataLoader["parsedData"];
    parsedDataLoading: TDriverDataLoader["parsedDataLoading"];
    displayColumns: (keyof TData)[];
    benchmarkProperty: TData | undefined;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useDriverDataTransformer(props: DriverDataTransformerParams) {
    const {
        parsedData,
        parsedDataLoading,
        displayColumns,
        benchmarkProperty,
    } = props;

    const [filteredData, setFilteredData] = useState<TData[]>();
    const [columnDiffs, setColumnDiffs] = useState<Map<string, TDiffData>>();
    const [nonEmptyColumns, setNonEmptyColumns] = useState<Set<TDataKey>>(new Set(DISPLAY_COLUMNS));

    useEffect(() => {
        if (!parsedData || parsedDataLoading) {
            return;
        }
        const {propertyRows, diffData} = buildFilteredData(
            parsedData,
            benchmarkProperty,
            displayColumns.filter(key => key !== "property") as (keyof Omit<TData, "property">)[],
        );
        setColumnDiffs(diffData);
        setFilteredData(propertyRows);
        const nonEmptyColumns = new Set<TDataKey>(["property"]);
        for (const row of propertyRows) {
            const propertyNonEmptyColumns = Object.entries(row).filter(([, val]) => val !== null).map(([key]) => key);
            for (const column of propertyNonEmptyColumns) {
                if ((DISPLAY_COLUMNS as string[]).includes(column)) {
                    nonEmptyColumns.add(column as TDataKey);
                }
            }
            if (nonEmptyColumns.size === DISPLAY_COLUMNS.length) {
                break;
            }
        }
        if (benchmarkProperty) {
            const propertyNonEmptyColumns = Object.entries(benchmarkProperty).filter(([, val]) => val !== null).map(([key]) => key);
            for (const column of propertyNonEmptyColumns) {
                if ((DISPLAY_COLUMNS as string[]).includes(column)) {
                    nonEmptyColumns.add(column as TDataKey);
                }
            }
        }
        setNonEmptyColumns(nonEmptyColumns);
    }, [parsedData, parsedDataLoading, benchmarkProperty]);

    return {transformedData: filteredData, columnDiffs, nonEmptyColumns};
}