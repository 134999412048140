import {ReactElement, useEffect, useState} from "react";
import * as css from "./diffmodalmulti.module.scss";
import {DataDisplayTable} from "../../../../../components/data-table/DataDisplayTable";
import {Body, Close, Header, Modal} from "@zendeskgarden/react-modals";
import {TData} from "../logic";
import {useGetMultiPropertyAccountDriversPropertyExplorerLazyQuery, VersionType} from "../../../../../__generated__/generated_types";
import {FinancialEntity} from "../../../../../contexts/chartofaccounts/ChartOfAccountsContext";
import {buildPageData, parseComparisionData, TComparisionData, TFeatureKey} from "./DiffModalMultiLogic";
import {PaginationParams, usePagination} from "../../../../../components/data-table/usePagination";


export interface IDiffModalMulti {
    featureLabel: string;
    modalHeader: string;
    featureKey: TFeatureKey;
    chartOfAccountsFlat: FinancialEntity[];
    firstProperty: Pick<TData, "property">;
    secondProperty: Pick<TData, "property">;
    accountId: string;
    year: number;
    versionType: VersionType;
    onClose: () => void;
}

export function DiffModalMulti(props: IDiffModalMulti): ReactElement {
    const [fetchData, {data, loading}] = useGetMultiPropertyAccountDriversPropertyExplorerLazyQuery({fetchPolicy: "no-cache"});

    const pagination = usePagination();

    const [comparisionData, setComparisionData] = useState<TComparisionData[]>();
    // displayPageData holds all data that needs to change atomically in a single render cycle
    const [displayPageData, setDisplayPageData] = useState<{
        rows: TComparisionData[] | undefined,
        paginationParams: PaginationParams,
    }>({
        rows: undefined,
        paginationParams: pagination.paginationParams,
    });

    useEffect(() => {
        fetchData({
            variables: {
                yearsVersionTypes: [{
                    year: props.year,
                    versionType: props.versionType
                }],
                accountId: props.accountId,
                propertyIds: [props.firstProperty.property.id, props.secondProperty.property.id],
            }
        });
    }, [
        props.firstProperty.property.id,
        props.secondProperty.property.id,
        props.year,
        props.versionType
    ]);

    useEffect(() => {
        if (!data || loading) {
            return;
        }

        const comparisionData = parseComparisionData(
            props.featureKey,
            props.firstProperty.property.id,
            props.secondProperty.property.id,
            props.chartOfAccountsFlat,
            data.multiPropertyAccountDrivers
        );
        setComparisionData(comparisionData);
        pagination.set({total: comparisionData.length, currentPage: 1});
    }, [data, loading]);

    useEffect(() => {
        if (!comparisionData) {
            return;
        }
        setDisplayPageData({
            rows: buildPageData({
                data: comparisionData,
                page: pagination.paginationParams.currentPage,
                pageSize: pagination.paginationParams.pageSize,
            }),
            paginationParams: pagination.paginationParams,
        });

    }, [
        comparisionData,
        pagination.paginationParams.currentPage,
        pagination.paginationParams.pageSize,
        pagination.paginationParams.totalPages,
    ]);

    function cellDataToDisplay(key: "feature" | "prop1" | "prop2", row: TComparisionData): string | ReactElement | number {
        if (key === "feature") {
            return row.feature.name;
        }

        return row[key] ? "V" : "";
    }

    return (
        <Modal onClose={props.onClose} isLarge className={css.modal}>
            <Header>
                <span>{props.modalHeader}</span>
            </Header>
            <Body>
                {displayPageData.rows &&
                    <div className={css.tableWrapper}>
                        <DataDisplayTable
                            className={css.tableWrapper}
                            dataProvider={{
                                rows: displayPageData?.rows,
                                totalRows: !comparisionData ? undefined : Math.min(pagination.paginationParams.pageSize, comparisionData.length),
                                columns: ["feature", "prop1", "prop2"],
                                secondHeaderRow: undefined
                            }}
                            headers={{
                                feature: props.featureLabel,
                                prop1: props.firstProperty.property.name,
                                prop2: props.secondProperty.property.name
                            }}
                            paginationParams={{
                                params: displayPageData.paginationParams,
                                setPage: pagination.setPage
                            }}
                            sortingParams={undefined}
                            customCellRenderers={{
                                cellDataToDisplay: cellDataToDisplay,
                                cellSecondHeaderDataToDisplay: undefined,
                                rowKey: (data) => data.feature.id
                            }}
                        />
                    </div>
                }
            </Body>
            <Close aria-label="Close modal" />
        </Modal>
    );
}