import { ReactElement, useState } from "react";
import { BackgroundJob, useGetJobLazyQuery } from "../../../__generated__/generated_types";
import { FinancialEntity } from "../../../contexts/chartofaccounts/ChartOfAccountsContext";
import { Property } from "../../../contexts/properties/PropertiesContext";
import { Button } from "@zendeskgarden/react-buttons";
import { Apartment, ArrowForward, Assignment } from "@material-ui/icons";
import css from "./styles/css.module.scss";
import { Body, Close, Header, Modal } from "@zendeskgarden/react-modals";
import BarLoader from "../../../components/bar-loader/BarLoader";
import { renderCopyJobDetails, renderSendBudgetJobDetails } from "./JobDetailRenderers";


interface IJobDetailsProps {
    job: BackgroundJob;
    accounts: FinancialEntity[];
    properties: Property[];
}

export default function JobDetails({ job, accounts, properties }: IJobDetailsProps): ReactElement {
    const [getJob, {loading}] = useGetJobLazyQuery({
        fetchPolicy: "no-cache",
        notifyOnNetworkStatusChange: true,
        onCompleted: data => {
            const details = data.getJob?.details;
            if (details) {
                switch (details.__typename) {
                    case "CopyDriverDetails": {
                        setJobDetails(renderCopyJobDetails(details, accounts, properties));
                        break;
                    }
                    case "SendBudgetDetails": {
                        setJobDetails(renderSendBudgetJobDetails(details, properties));
                        break;
                    }
                }
            }
        }

    });

    const shortDetails = job.shortDetails;
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [jobDetails, setJobDetails] = useState<ReactElement>();
    let detailsSummary;

    switch (shortDetails?.__typename) {
        case "CopyDriverShortDetails": {
            const sourcePropertyName = properties.find(x => x.id == shortDetails.sourcePropertyId)?.name;
            const accountCount = shortDetails.accountCount == 0 ? 'All' : shortDetails.accountCount;

            detailsSummary = <div className={css.jobDetailsButtonContents}>
                    <span style={{fontWeight: 500, marginRight: ".5rem"}}>{sourcePropertyName}:</span>
                    <Assignment fontSize="small" /> {accountCount}
                    <ArrowForward className={css.jobDetailsButtonArrow} />
                    <Apartment fontSize="small" /> {shortDetails.destinationPropertyCount}
                </div>
            break;
        }
        case "SendBudgetShortDetails": {
            const label = shortDetails.propertyCount == 1 ? `${shortDetails.propertyCount} Property` : `${shortDetails.propertyCount} Properties`
            detailsSummary = <div className={css.jobDetailsButtonContents}>
                    <span style={{fontWeight: 500}}>{label}</span>
                </div>
            break;
        }
        default:
            detailsSummary = "No details";
    }

    return (
        <div>
            <Button
                className={css.jobDetailsButton}
                isBasic
                isPill
                onClick={() => {
                    setShowDetailsModal(true);
                    getJob({
                        variables: {
                            jobId: job.id,
                        }
                    });
                }}
            >
                {detailsSummary}
            </Button>

            {
                showDetailsModal &&
                    <Modal
                        className={css.jobDetailsModal}
                        onClose={() => setShowDetailsModal(false)}
                    >
                        <Header>
                            Job Details
                        </Header>
                        <Body className={css.jobDetailsModalBody}>
                            <BarLoader loading={loading} />
                            {jobDetails}
                        </Body>
                        <Close aria-label="Close modal" />
                    </Modal>
            }
        </div>
    );
}
