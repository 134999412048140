import "native-injects";
import {SortType} from "../../../../utils/sorting";
import {GetMultiPropertyAccountDriversPropertyExplorerQuery, GrowthCalcMethod, VersionType} from "../../../../__generated__/generated_types";
import {TPositionsAndCompItemsDataProvider} from "../shared/PositionsAndDisplayColumnsProvider";
import {renderBasePeriod as renderBasePeriodMonthlyAverage, renderMonthlyAdjustment} from "../../../workflows/account/formula-bar/components/formula-nodes/MonthlyAverageGrowthDriverFxNode";
import {
    renderAnnualTargetValueNode as renderAnnualTargetValue,
    renderDistributionMethodNode as renderDistributionMethodNodeAnnualTargetValue
} from "../../../workflows/account/formula-bar/components/formula-nodes/AnnualTargetValueGrowthDriverFxNode";
import {
    analyzePercentGrowthBasePeriod,
    renderBasePeriod as renderBasePeriodPercentGrowth,
    renderAdjustmentValueNode as renderAdjustmentValue
} from "../../../workflows/account/formula-bar/components/formula-nodes/PercentGrowthDriverFxNode";
import {REVENUE_TYPE_DESCRIPTIONS} from "../../../../components/template/types";

export const DISPLAY_COLUMNS: TDataKey[] = [
    "property",
    "accountPercent",
    "annualTargetAmount",
    "customDriver",
    "growthPercent",
    "monthlyAverage",
    "operational",
    "payroll",
    "revenue",
    "worksheet"
];

export const COLUMN_NAMES: Record<TDataKey, string> = {
    property: "Property",
    "accountPercent": "% of Account",
    "annualTargetAmount": "Annual Target Amount",
    "customDriver": "Custom Driver",
    "growthPercent": "Growth",
    "monthlyAverage": "Monthly Average",
    "operational": "Operational",
    "payroll": "Payroll",
    "revenue": "Revenue",
    "worksheet": "Line Items"
}

export type TAccountInfo = {
    accountId: string,
    name: string
}

export type TDriverInfo = {
    hash: string | number;
    content: string;
}

export type TData = {
    property: {
        id: string;
        name: string;
    };
    monthlyAverage: TDriverInfo | null;
    growthPercent: TDriverInfo | null;
    annualTargetAmount: TDriverInfo | null;
    payroll: TDriverInfo | null;
    accountPercent: TDriverInfo | null;
    worksheet: TDriverInfo | null;
    operational: TDriverInfo | null;
    revenue: TDriverInfo | null;
    customDriver: TDriverInfo | null;
}

export type TDataKey = keyof TData;

export type TDiffData = Record<Exclude<TDataKey, "property">, boolean>;

export function assignDriverInfo(
    driverDataPerProperty: Map<string, TDriverInfo>,
    key: keyof Omit<TData, "property">,
    propertiesDrivers: Map<string, TData>
):void {
    for (const [propertyId, driverInfo] of Array.from(driverDataPerProperty)) {
        const propertyDriversInfo = propertiesDrivers.get(propertyId);
        if (!propertyDriversInfo) {
            continue;
        }
        propertyDriversInfo[key] = driverInfo;
    }
}

async function idsHash(ids: string[]): Promise<number> {
    if (ids.length === 0) {
        return 0;
    }
    const buffer = new TextEncoder().encode(ids.join());
    const hashBuffer = await crypto.subtle.digest("SHA-1", buffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    const hash = parseInt(hashHex, 16);
    return hash;
}

export function mapGrowth(
    data: GetMultiPropertyAccountDriversPropertyExplorerQuery["multiPropertyAccountDrivers"]["growth"],
    versionType: VersionType.Reforecast | VersionType.Budget,
    reforecastYear: number

): Record<Extract<keyof TData, "growthPercent" | "monthlyAverage" | "annualTargetAmount">, Map<string, TDriverInfo>> {
    const growthPercent:Map<string, TDriverInfo> = new Map();
    const monthlyAverage:Map<string, TDriverInfo> = new Map();
    const annualTargetAmount:Map<string, TDriverInfo> = new Map();

    for (const row of data) {
        if (row.growthCalcMethod === GrowthCalcMethod.MonthlyAverage) {
            const renderedBasePeriod = renderBasePeriodMonthlyAverage(row, undefined);
            const renderedMonthlyAdjustment = renderMonthlyAdjustment(row, undefined);
            const hash = `${renderedBasePeriod}${renderedMonthlyAdjustment}`;
            const content = `${renderedBasePeriod} with ${renderedMonthlyAdjustment}`;

            monthlyAverage.set(row.propertyId, {hash, content});
        }
        else if (row.growthCalcMethod === GrowthCalcMethod.AnnualTargetValue) {
            const renderedAnnualTargetValueNode = renderAnnualTargetValue(row, undefined);
            const renderedDistributionMethodNode = renderDistributionMethodNodeAnnualTargetValue(row, undefined);
            const hash = `${renderedAnnualTargetValueNode}${renderedDistributionMethodNode}`;
            const content = `${renderedAnnualTargetValueNode} with ${renderedDistributionMethodNode}`;
            annualTargetAmount.set(row.propertyId, {hash, content});
        }
        else if (row.growthCalcMethod === GrowthCalcMethod.PercentGrowth) {
            const {percentGrowthBasePeriod, showDistributionNode} = analyzePercentGrowthBasePeriod(row, versionType, reforecastYear);
            const renderedBasePeriod = renderBasePeriodPercentGrowth(percentGrowthBasePeriod, undefined);
            const renderedAdjustmentValueNode = renderAdjustmentValue(row, undefined);
            const renderedDistributionMethod = renderDistributionMethodNodeAnnualTargetValue(row, undefined);
            const hash = `${showDistributionNode}${renderedBasePeriod}${renderedAdjustmentValueNode}${renderedDistributionMethod}`;
            let content = `${renderedBasePeriod} +${renderedAdjustmentValueNode}`;
            if (showDistributionNode) {
                content += ` with ${renderedDistributionMethod}`;
            }
            growthPercent.set(row.propertyId, {hash, content});
        }
    }

    return {
        growthPercent,
        monthlyAverage,
        annualTargetAmount
    };
}

export async function mapPayroll(
    data: GetMultiPropertyAccountDriversPropertyExplorerQuery["multiPropertyAccountDrivers"]["payroll"],
    positions: NonNullable<TPositionsAndCompItemsDataProvider["positions"]>,
    compItemIds: NonNullable<TPositionsAndCompItemsDataProvider["compItemIds"]>,
    compItemNames: NonNullable<TPositionsAndCompItemsDataProvider["compItemNames"]>
): Promise<Map<string, TDriverInfo>> {
    const ret = new Map<string, TDriverInfo>();
    await data.forEachConcurrent(async propertyData => {
        const itemPositions = new Map<string, string[]>();
        for (const primaryCompensation of propertyData.primaryCompensationAccounts) {
            let itemPositionsRecord = itemPositions.get(primaryCompensation.primaryCompensationItem);
            if (!itemPositionsRecord) {
                itemPositionsRecord = [];
                itemPositions.set(primaryCompensation.primaryCompensationItem, itemPositionsRecord);
            }
            itemPositionsRecord.push(primaryCompensation.positionId);
        }
        for (const compensation of propertyData.compensationItemAccounts) {
            let itemPositionsRecord = itemPositions.get(compensation.compensationItemId);
            if (!itemPositionsRecord) {
                itemPositionsRecord = [];
                itemPositions.set(compensation.compensationItemId, itemPositionsRecord);
            }
            itemPositionsRecord.push(compensation.positionId);
        }
        const allIds:string[] = [];
        let allCount = 0;
        for (const compItemId of Array.from(itemPositions.keys()).sort()) {
            let positionIds = itemPositions.get(compItemId);
            if (!positionIds) {
                continue;
            }
            allIds.push(compItemId);
            positionIds = [...positionIds].sort();
            allIds.push(...positionIds);
            // counting pairs of <Comp Item, Position>
            // to later display as ### more
            // Rationale: itemPositions maps comp item to list of positions
            // Hence suming lengths of position lists for yeach comp item yields
            // total number of <Comp Item, Position> pairs
            allCount += positionIds.length;
        }
        if (allCount === 0) {
            return;
        }

        const hash = await idsHash(allIds);

        let content = "";
        const firstCompItemId = compItemIds.find(compItemId => itemPositions.has(compItemId));
        if (firstCompItemId) {
            const compItemName = compItemNames[firstCompItemId];
            const firstCompItemPositionIds = itemPositions.get(firstCompItemId);
            if (firstCompItemPositionIds && compItemName) {
                const firstPosition = positions.find(position => firstCompItemPositionIds.includes(position.id));
                if (firstPosition) {
                    content = `${compItemName}: ${firstPosition.name}`;
                    if (allIds.length > 2) {
                        content += ` +${allCount - 1} more`;
                    }
                }
            }
        }
        ret.set(propertyData.propertyId, {hash, content});
    });
    return ret;
}

export async function mapActPercent(accountFullNames: {id: string, fullName:string}[], data: GetMultiPropertyAccountDriversPropertyExplorerQuery["multiPropertyAccountDrivers"]["acctPercentage"]): Promise<Map<string, TDriverInfo>> {
    const accountIdsByProperty = new Map<string, Set<string>>();
    for (const row of data) {
        let propertyAccountIds = accountIdsByProperty.get(row.propertyId);
        if (!propertyAccountIds) {
            propertyAccountIds = new Set();
            accountIdsByProperty.set(row.propertyId, propertyAccountIds);
        }
        propertyAccountIds.add(row.sourceAccountId);
    }
    const ret = new Map<string, TDriverInfo>();
    await Array.from(accountIdsByProperty).forEachConcurrent(async ([propertyId, propertyAccountIds]) => {
        const hash = await idsHash(Array.from(propertyAccountIds).sort());
        let content = "";
        const firstAccountName = accountFullNames.find(acc => propertyAccountIds.has(acc.id))?.fullName ?? "";
        for (let i = 0; i < Math.min(2, propertyAccountIds.size); i++) {
            if (i === 0) {
                content = firstAccountName;
            }
            else if (i === 1) {
                content += ` +${propertyAccountIds.size - 1} more`;
            }
        }
        const propertyDriverInfo = {hash, content};
        ret.set(propertyId, propertyDriverInfo);
    });
    return ret;
}

export function mapLineItems(data: GetMultiPropertyAccountDriversPropertyExplorerQuery["multiPropertyAccountDrivers"]["worksheet"]): Map<string, TDriverInfo> {
    const itemNames = new Map<string, string[]>();
    for (const row of data) {
        let propertyItemNames = itemNames.get(row.propertyId);
        if (!propertyItemNames) {
            propertyItemNames = [];
            itemNames.set(row.propertyId, propertyItemNames);
        }
        propertyItemNames.push(row.description);
    }
    const ret = new Map<string, TDriverInfo>();
    for (const [propertyId, propertyItemNames] of Array.from(itemNames)) {
        const propertyItemNamesSorted = propertyItemNames.sort();
        let itemNamesString = "";
        for (let i = 0; i < Math.min(2, propertyItemNamesSorted.length); i++) {
            if (i === 0) {
                const itemName = propertyItemNamesSorted[i];
                itemNamesString = itemName ? itemName : "";
            }
            else if (i === 1) {
                itemNamesString += ` +${propertyItemNamesSorted.length - 1} more`;
            }
        }
        const propertyDriverInfo = {
            hash: itemNamesString,
            content: itemNamesString
        };
        ret.set(propertyId, propertyDriverInfo);
    }
    return ret;
}

export function mapRevenue(data: GetMultiPropertyAccountDriversPropertyExplorerQuery["multiPropertyAccountDrivers"]["revenue"]): Map<string, TDriverInfo> {
    const itemNames = new Map<string, string[]>();
    for (const row of data) {
        let propertyItemNames = itemNames.get(row.propertyId);
        if (!propertyItemNames) {
            propertyItemNames = [];
            itemNames.set(row.propertyId, propertyItemNames);
        }
        propertyItemNames.push(REVENUE_TYPE_DESCRIPTIONS[row.revenueType]);
    }
    const ret = new Map<string, TDriverInfo>();
    for (const [propertyId, propertyItemNames] of Array.from(itemNames)) {
        const propertyItemNamesSorted = propertyItemNames.sort();
        let itemNamesString = "";
        for (let i = 0; i < Math.min(2, propertyItemNamesSorted.length); i++) {
            if (i === 0) {
                const itemName = propertyItemNamesSorted[i];
                itemNamesString = itemName ? itemName : "";
            }
            else if (i === 1) {
                itemNamesString += ` +${propertyItemNamesSorted.length - 1} more`;
            }
        }
        const propertyDriverInfo = {
            hash: itemNamesString,
            content: itemNamesString
        };
        ret.set(propertyId, propertyDriverInfo);
    }
    return ret;
}

export function customDriverDispay(itemName: string): string {
    return `Fee * % of ${itemName}`;
}

export function mapCustomDriver(data: GetMultiPropertyAccountDriversPropertyExplorerQuery["multiPropertyAccountDrivers"]["customDriver"]): Map<string, TDriverInfo> {
    const itemNames = new Map<string, string[]>();
    for (const row of data) {
        let propertyItemNames = itemNames.get(row.propertyId);
        if (!propertyItemNames) {
            propertyItemNames = [];
            itemNames.set(row.propertyId, propertyItemNames);
        }
        propertyItemNames.push(row.itemName);
    }
    const ret = new Map<string, TDriverInfo>();
    for (const [propertyId, propertyItemNames] of Array.from(itemNames)) {
        const propertyItemNamesSorted = propertyItemNames.sort();
        let itemNamesString = "";
        for (let i = 0; i < Math.min(2, propertyItemNamesSorted.length); i++) {
            if (i === 0) {
                const itemName = propertyItemNamesSorted[i];
                itemNamesString = itemName ? customDriverDispay(itemName) : "";
            }
            else if (i === 1) {
                itemNamesString += ` +${propertyItemNamesSorted.length - 1} more`;
            }
        }
        const propertyDriverInfo = {
            hash: itemNamesString,
            content: itemNamesString
        };
        ret.set(propertyId, propertyDriverInfo);
    }
    return ret;
}

export function opMetricDisplay(metricName: string): string {
    switch(metricName) {
        case "Expiration": return "Fee * % of Expirations";
        case "Move In": return "Fee * % of Move Ins";
        case "Move Out": return "Fee * % of Move Outs";
        case "Renewal": return "Fee * % of Renewals";
        case "Total Unit": return "Fee * % of Total Units";
        case "Occupied": return "Fee * % of Occupied Units";
        default: return "";
    }
}

export function mapOperational(data: GetMultiPropertyAccountDriversPropertyExplorerQuery["multiPropertyAccountDrivers"]["operational"]): Map<string, TDriverInfo> {
    const metricNames = new Map<string, string[]>();
    for (const row of data) {
        let propertyMetricNames = metricNames.get(row.propertyId);
        if (!propertyMetricNames) {
            propertyMetricNames = [];
            metricNames.set(row.propertyId, propertyMetricNames);
        }
        propertyMetricNames.push(row.sourceMetricName);
    }
    const ret = new Map<string, TDriverInfo>();
    for (const [propertyId, propertyMetricNames] of Array.from(metricNames)) {
        const propertyMetricNamesSorted = propertyMetricNames.sort();
        let metricNamesString = "";
        for (let i = 0; i < Math.min(2, propertyMetricNamesSorted.length); i++) {
            if (i === 0) {
                metricNamesString = opMetricDisplay(propertyMetricNamesSorted[i] ?? "");
            }
            else if (i === 1) {
                metricNamesString += ` +${propertyMetricNamesSorted.length - 1} more`;
            }
        }
        const propertyDriverInfo = {
            hash: metricNamesString,
            content: metricNamesString
        };
        ret.set(propertyId, propertyDriverInfo);
    }
    return ret;
}

export function buildPageData(params: {
    data: TData[],
    page: number,
    pageSize: number,
    exclude: TData|undefined,
    sortColumn: keyof TData | undefined,
    sortType: SortType | undefined
}): TData[] {
    const {data, page, pageSize, exclude, sortColumn, sortType} = params;

    let dataForProcessing = data;

    if (sortColumn && sortType) {
        dataForProcessing = [...data];
        const descMultiplier = sortType == "asc" ? 1 : -1;
        dataForProcessing = dataForProcessing.sort((a, b) => {
            let ret = 0;
            if (sortColumn === "property") {
                ret = a.property.name < b.property.name ? -1 :
                    a.property.name > b.property.name ? 1 : 0;
                ret = ret * descMultiplier;
            }
            else {
                // always move empty values to start
                const aVal = a[sortColumn]?.content ?? "";
                const bVal = b[sortColumn]?.content ?? "";
                if (aVal === "" && bVal === "") {
                    ret = a.property.name < b.property.name ? -1 :
                        a.property.name > b.property.name ? 1 : 0;
                }
                else if (aVal === "") {
                    ret = -1;
                }
                else if (bVal === "") {
                    ret = 1;
                }
                else if (aVal < bVal) {
                    ret = -1 * descMultiplier;
                }
                else if (aVal > bVal) {
                    ret = 1 * descMultiplier;
                }
                else {
                    ret = a.property.name < b.property.name ? -1 :
                        a.property.name > b.property.name ? 1 : 0;
                }
            }
            return ret;
        });
    }

    const start = (page - 1) * pageSize;
    if (exclude) {
        return dataForProcessing.filter(row => row.property.id !== exclude.property.id).slice(start, start + pageSize);
    }
    return dataForProcessing.slice(start, start + pageSize);
}

export function rowKey(row: TData): string | number {
    return row.property.id;
}

export function buildFilteredData(
    data: TData[],
    benchmarkProperty: TData | undefined,
    keys: (keyof Omit<TData, "property">)[],
): {propertyRows: TData[], diffData: Map<string, TDiffData> | undefined} {
    let filteredData = data;
    let diffData: Map<string, TDiffData> | undefined = undefined;
    if (benchmarkProperty) {
        const builtDiffData = new Map<string, TDiffData>();
        filteredData = filteredData.filter(row => {
            if (row.property.id === benchmarkProperty.property.id) {
                return false;
            }
            let isDifferent = false;
            for (const key of keys) {
                const hasKeyDiff = benchmarkProperty[key]?.hash !== row[key]?.hash;
                isDifferent = isDifferent || hasKeyDiff;
                if (hasKeyDiff) {
                    let propertyDiff = builtDiffData.get(row.property.id);
                    if (!propertyDiff) {
                        propertyDiff = {
                            monthlyAverage: false,
                            growthPercent: false,
                            annualTargetAmount: false,
                            payroll: false,
                            accountPercent: false,
                            worksheet: false,
                            operational: false,
                            revenue: false,
                            customDriver: false,
                        };
                        builtDiffData.set(row.property.id, propertyDiff);
                    }
                    propertyDiff[key] = true;
                }
            }
            return isDifferent;
        });
        diffData = builtDiffData;
    }
    return {propertyRows: filteredData, diffData};
}
