import {Autocomplete, Dropdown, Field, Item, Menu} from "@zendeskgarden/react-dropdowns";
import {ReactElement, useEffect, useMemo, useRef, useState} from "react";
import * as css from "./accountdrivers.module.scss";

export interface AccountSelectorParams {
    selectedAccountId: string;
    accounts: {id: string, fullName: string;}[];
    onSelect: (selected: string) => void;
}

export function AccountSelector(props: AccountSelectorParams): ReactElement | null {
    const [nameFilter, setNameFilter] = useState<string>("");
    const inputDebounce = useRef<ReturnType<typeof setTimeout> | null>(null);
    const [matchedAccounts, setMatchedAccounts] = useState(props.accounts);
    useEffect(() => {
        if (inputDebounce.current) {
            clearTimeout(inputDebounce.current);
        }
        inputDebounce.current = setTimeout(() => {
            if (nameFilter.length == 0) {
                setMatchedAccounts(props.accounts);
            }
            const nameFilterLowerCase = nameFilter.toLowerCase();
            setMatchedAccounts(props.accounts.filter(acc => acc.fullName.toLowerCase().includes(nameFilterLowerCase)));
        }, 300);
    }, [nameFilter]);


    const selectedAccountName = useMemo(() => {
        return props.accounts.find(acc => acc.id == props.selectedAccountId)?.fullName ?? "";
    }, [props.selectedAccountId, props.accounts]);

    return (
        <Dropdown
            inputValue={nameFilter}
            onInputValueChange={(v) => setNameFilter(v)}
            selectedItem={props.selectedAccountId}
            onSelect={(v) => v && props.onSelect(v)}
            downshiftProps={{defaultHighlightedIndex: 0}}
        >
            <Field>
                <Autocomplete className={css.autocomplete}>
                    {selectedAccountName}
                </Autocomplete>
            </Field>
            <Menu>
                {
                    matchedAccounts.map(account => (
                        <Item key={account.id} value={account.id}>{account.fullName}</Item>
                    ))
                }
            </Menu>
        </Dropdown>
    );
}