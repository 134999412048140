import HotTable from "@handsontable/react";
import { ReactElement, useEffect, useRef, useState } from "react";
import css from "./metric-table.module.scss";
import { LICENSES } from "../../../../constants/Licenses";
import Handsontable from "handsontable";
import { MONTHS } from "../../../../constants/Months";
import { Property } from "../../../../contexts/properties/PropertiesContext";
import cn from "classnames";
import { RevenueSource } from "../../../../constants/RevenueWorkflow";


export interface IMetricTableProps {
    reforecastStartMonthIndex: number;
    reforecastYear: number;
    metricType: RevenueSource;
    onUpdate: (rowData: (number | null)[][]) => void;
}

export default function MetricTable({ reforecastStartMonthIndex, reforecastYear, metricType, onUpdate }: IMetricTableProps): ReactElement {
    const columnSettings: Handsontable.ColumnSettings = {
        type: 'numeric',
    };

    if (metricType == RevenueSource.RENEWALS_RATE) {
        columnSettings.numericFormat = {
            pattern: '$0,0',
            culture: 'en-US'
        }
    }

    const columns: Handsontable.ColumnSettings[] = new Array(24).fill(columnSettings).slice(reforecastStartMonthIndex);

    const hotRef = useRef<HotTable | null>(null);
    const updateTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
    const [tableData, setTableData] = useState<(number | null)[][]>(() => {
        if (metricType === RevenueSource.RENEWALS_RATIO) {
            return [
                new Array(columns.length).fill(50),
                new Array(columns.length).fill(50),
            ];
        }
        return [new Array(columns.length).fill(0)];
    });

    const monthHeadersRfcst = MONTHS.map(month => `${month} ${reforecastYear % 100}`).slice(reforecastStartMonthIndex);
    const monthHeadersBdgt = MONTHS.map(month => `${month} ${(reforecastYear + 1) % 100}`);
    const colHeaders = [
        ...monthHeadersRfcst,
        ...monthHeadersBdgt,
    ];

    const tableConfig: Handsontable.GridSettings = {
        licenseKey: LICENSES.HandsOnTable,
        height: 75,
        width: "100%",
        stretchH: "all",
        rowHeights: 35,
        columnHeaderHeight: 35,
        colHeaders: colHeaders,
        columns: columns,
        startRows: 1,
        data: tableData,
    };

    let metricClassName = "";
    switch (metricType) {
        case RevenueSource.OCCUPANCY:
        case (RevenueSource.AVG_MARKET_RENTS):
            metricClassName = css.percentageCell;
            break;
        case (RevenueSource.RENEWALS_RATIO):
            metricClassName = css.percentageCell;
            tableConfig.startRows = 2;
            tableConfig.height = 110;
            tableConfig.rowHeaders = ["Renewal Ratio", "Move Out Ratio"];
            tableConfig.rowHeaderWidth = 120;
            break;
        case RevenueSource.RENEWALS_RATE:
            metricClassName = css.currencyCell;
    }

    useEffect(() => {
        onUpdate(tableData);
    }, [tableData]);

    return (
        <HotTable
            ref={hotRef}
            className={cn(css.table, metricClassName)}
            settings={{
                ...tableConfig,
                beforeChange(changes, source) {
                    if (String(source) == "moveOutUpdate") {
                        return;
                    }

                    changes?.forEach(change => {
                        if (!change) {
                            return;
                        }

                        const [row, prop, _oldValue, newValue] = change;
                        let clampedVal = newValue;

                        if (metricType != RevenueSource.AVG_MARKET_RENTS && metricType != RevenueSource.RENEWALS_RATE) {
                            if (newValue < 0) {
                                clampedVal = 0;
                            } else if (newValue > 100) {
                                clampedVal = 100;
                            }
                        }

                        const colIndex = hotRef.current?.hotInstance?.propToCol(prop)

                        if (colIndex != null) {
                            change[3] = clampedVal;

                            if (metricType == RevenueSource.RENEWALS_RATIO) {
                                const moveOutVal = 100 - clampedVal;
                                hotRef.current?.hotInstance?.setDataAtCell([[row == 0 ? 1 : 0, colIndex, moveOutVal]], "moveOutUpdate");
                            }
                        }
                    });
                },
                afterChange: (_changes, _source) => {
                    if (String(_source) == "loadData" || String(_source) == "updateData") {
                        return;
                    }

                    if (updateTimeoutRef.current != null) {
                        clearTimeout(updateTimeoutRef.current);
                    }

                    updateTimeoutRef.current = setTimeout(() => {
                        const rowData = hotRef.current?.hotInstance?.getSourceData() as (number | null)[][];
                        setTableData(rowData);
                    }, 1000);
                }
            }}
        />
    );
}
