import "native-injects";
import {ListPropertyRevenueAccountsQuery} from "../../../../__generated__/generated_types";
import {FinancialEntity} from "../../../../contexts/chartofaccounts/ChartOfAccountsContext";
import {Property} from "../../../../contexts/properties/PropertiesContext";
import {formatterGlName} from "../../../../utils/formatters";
import {SortType} from "../../../../utils/sorting";

export type TData = {
    property: {
        name: string;
        id: string;
    }
} & Omit<ListPropertyRevenueAccountsQuery["listPropertyRevenueAccounts"][0], "propertyId" | "__typename">

export type TDataKey = keyof TData;
type TRawDataKey = keyof Omit<ListPropertyRevenueAccountsQuery["listPropertyRevenueAccounts"][0], "__typename">;

export function buildPropertyRevenueDriverAccountsDisplayData(
    rawData: ListPropertyRevenueAccountsQuery["listPropertyRevenueAccounts"],
    properties: Property[],
    accounts: FinancialEntity[]
): {data: TData[], nonEmptyColumns: Set<TDataKey>} {
    const propertiesMap = properties.toIdMap("id");
    const accountsMap = accounts.toIdMap("id");

    const transformed: TData[] = [];
    const nonEmptyColumns = new Set<TDataKey>();
    for (const dataRow of rawData) {
        const propertyName = extractName("propertyId", dataRow, propertiesMap, accountsMap);
        if (!propertyName) {
            continue;
        }
        const transformedRow: TData = {property: {name: propertyName, id:dataRow.propertyId}};
        for (const key in dataRow) {
            if (key === "propertyId") {
                continue;
            }
            const typedRawDataKey:TRawDataKey = key as TRawDataKey;
            const value = extractName(typedRawDataKey, dataRow, propertiesMap, accountsMap);
            if (value) {
                const typedDataKey:Exclude<TDataKey, "property"> = key as Exclude<TDataKey, "property">;
                nonEmptyColumns.add(typedDataKey);
                transformedRow[typedDataKey] = value;
            }
        }
        transformed.push(transformedRow);
    }

    return {
        data: transformed.sortBy((row) => row.property.name),
        nonEmptyColumns
    };
}

function extractName(
    key: TRawDataKey,
    data: ListPropertyRevenueAccountsQuery["listPropertyRevenueAccounts"][0],
    propertiesMap: Record<string, Property>,
    accountsMap: Record<string, FinancialEntity>
): string | undefined {
    let ret = undefined;
    if (key === "propertyId") {
        const property = propertiesMap[data[key]];
        if (property) {
            ret = property.name;
        }
    }
    else {
        const accountId = data[key];
        if (accountId) {
            const account = accountsMap[accountId];
            if (account) {
                ret = formatterGlName(account.name, account.number);
            }
        }
    }

    return ret;
}

export function buildPageData(params: {
    data: TData[],
    page: number,
    pageSize: number,
    exclude: TData|undefined,
    sortColumn: TDataKey | undefined,
    sortType: SortType | undefined
}): TData[] {
    const {data, page, pageSize, exclude, sortColumn, sortType} = params;

    let dataForProcessing = data;

    if (sortColumn && sortType) {
        dataForProcessing = [...data];
        const descMultiplier = sortType == "asc" ? 1 : -1;
        dataForProcessing = dataForProcessing.sort((a, b) => {
            let ret = 0;
            if (sortColumn === "property") {
                ret = a.property.name < b.property.name ? -1 :
                    a.property.name > b.property.name ? 1 : 0;
                ret = ret * descMultiplier;
            }
            else {
                // always move empty values to start
                const aVal = a[sortColumn] ?? "";
                const bVal = b[sortColumn] ?? "";
                if (aVal === "" && bVal === "") {
                    ret = a.property.name < b.property.name ? -1 :
                        a.property.name > b.property.name ? 1 : 0;
                }
                else if (aVal === "") {
                    ret = -1;
                }
                else if (bVal === "") {
                    ret = 1;
                }
                else if (aVal < bVal) {
                    ret = -1 * descMultiplier;
                }
                else if (aVal > bVal) {
                    ret = 1 * descMultiplier;
                }
                else {
                    ret = a.property.name < b.property.name ? -1 :
                        a.property.name > b.property.name ? 1 : 0;
                }
            }
            return ret;
        });
    }

    const start = (page - 1) * pageSize;
    if (exclude) {
        return dataForProcessing.filter(row => row.property.id !== exclude.property.id).slice(start, start + pageSize);
    }
    return dataForProcessing.slice(start, start + pageSize);
}

export function rowKey(row: TData): string | number {
    return row.property.id;
}

export function buildFilteredData(data: TData[], benchmarkProperty: TData | undefined, keys: Exclude<TDataKey, "property">[]): TData[] {
    let filteredData = data;
    if (benchmarkProperty) {
        filteredData = filteredData.filter(row => {
            if (row.property.id === benchmarkProperty.property.id) {
                return false;
            }
            let isDifferent = false;
            for (const key of keys) {
                isDifferent = benchmarkProperty[key] !== row[key];
                if (isDifferent) {
                    break;
                }
            }
            return isDifferent;
        });
    }
    return filteredData;
}