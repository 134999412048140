import {Property} from "../../../../contexts/properties/PropertiesContext";
import {ReactElement, useMemo, useState} from "react";
import {Body, Close, Footer, FooterItem, Header, Modal} from "@zendeskgarden/react-modals";
import {Button} from "@zendeskgarden/react-buttons";
import {Input} from "@zendeskgarden/react-forms";
import * as css from "./bulk-assumptions-modal.module.scss";
import { Spinner } from "@zendeskgarden/react-loaders";

export interface BulkAssumptionsModalProps {
    selectedProperties: Property[];
    selectedMetrics: string[];
    updateInProgress: boolean;
    accept: () => void;
    cancel: () => void;
}

const ExpectedText = "overwrite metrics";

export function BulkAssumptionsModal({selectedProperties, selectedMetrics, updateInProgress, accept, cancel}: BulkAssumptionsModalProps): ReactElement {
    const sortedProperties = useMemo(() => {
        return selectedProperties.sort((one, two) => one.name.localeCompare(two.name));
    }, [selectedProperties]);

    const [inputVal, setInputVal] = useState<string>("");

    return <Modal className={css.wrapper}>
        <Header isDanger className={css.header}>
            <span>Update Revenue Assumptions?</span>
        </Header>
        <Body>
            <h5>{sortedProperties.length} Properties Being Updated</h5>
            <ul className={css.list}>
                {sortedProperties.map(p => <li>{p.name}</li>)}
            </ul>

            <h5>{selectedMetrics.length} Metrics Being Updated</h5>
            <ul className={css.list}>
                {selectedMetrics.map(m => <li>{m}</li>)}
            </ul>

            <h5>Enter "{ExpectedText}" to confirm.</h5>
            <Input type="text" aria-label={`Enter "${ExpectedText}" to confirm.`} placeholder={ExpectedText} value={inputVal} onChange={(e) => setInputVal(e.target.value)} />
            <div className={css.warningWrapper}>
                <h5 className={css.warningText}>THIS ACTION CANNOT BE UNDONE</h5>
            </div>
        </Body>
        <Footer>
            <FooterItem>
                <Button onClick={cancel}>Cancel</Button>
            </FooterItem>
            <FooterItem>
                <Button
                        isPrimary
                        isDanger
                        onClick={accept}
                        disabled={inputVal !== ExpectedText || updateInProgress}
                >
                    {
                        updateInProgress
                            ? <Spinner />
                            : "Perform Updates"
                    }
                </Button>
            </FooterItem>
        </Footer>
        <Close onClick={cancel} aria-label="Cancel revenue assumption update." />
    </Modal>;
}