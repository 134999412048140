import {FinancialEntityType, GetMultiPropertyAccountDriversPropertyExplorerQuery} from "../../../../../__generated__/generated_types";
import {FinancialEntity} from "../../../../../contexts/chartofaccounts/ChartOfAccountsContext";
import {formatterGlName} from "../../../../../utils/formatters";
import {customDriverDispay, opMetricDisplay} from "../logic";

// just a convinience trick here
// https://dev.to/hansott/how-to-check-if-string-is-member-of-union-type-1j4m
const FEATURE_KEYS = ["accountPercent", "worksheet", "operational", "customDriver"] as const;
type FEATURE_KEY_TUPLE = typeof FEATURE_KEYS;
export type TFeatureKey = FEATURE_KEY_TUPLE[number];

export function isFeatureKey(value: string): value is TFeatureKey {
  return FEATURE_KEYS.includes(value as TFeatureKey);
}

export type TComparisionData = {
    feature: {
        id: string,
        name: string
    },
    prop1: boolean,
    prop2: boolean
}

export function parseComparisionData(
    featureKey: TFeatureKey,
    prop1Id: string,
    prop2Id: string,
    chartOfAccountsFlat: FinancialEntity[],
    rawData: GetMultiPropertyAccountDriversPropertyExplorerQuery["multiPropertyAccountDrivers"]
): TComparisionData[] {
    let ret: TComparisionData[] = [];
    if (featureKey === "accountPercent") {
        ret = parseComparisionDataAccountPercentage(
            rawData.acctPercentage,
            prop1Id,
            prop2Id,
            chartOfAccountsFlat
        );
    }
    else if (featureKey === "customDriver") {
        ret = parseComparisionDataCustomDriver(
            rawData.customDriver,
            prop1Id,
            prop2Id
        );
    }
    else if (featureKey === "operational") {
        ret = parseComparisionDataOperational(
            rawData.operational,
            prop1Id,
            prop2Id
        );
    }
    else if (featureKey === "worksheet") {
        ret = parseComparisionDataWorksheet(
            rawData.worksheet,
            prop1Id,
            prop2Id
        );
    }
    return ret;
}


export function buildPageData(params: {
    data: TComparisionData[],
    page: number,
    pageSize: number,
}): TComparisionData[] {
    const {data, page, pageSize} = params;
    const start = (page - 1) * pageSize;
    return data.slice(start, start + pageSize);
}

function parseComparisionDataAccountPercentage(
    rawData: GetMultiPropertyAccountDriversPropertyExplorerQuery["multiPropertyAccountDrivers"]["acctPercentage"],
    prop1Id: string,
    prop2Id: string,
    chartOfAccountsFlat: FinancialEntity[],
): TComparisionData[] {
    const byAccountId = rawData.groupBy("sourceAccountId", "propertyId");
    const ret: TComparisionData[] = [];
    for (const account of chartOfAccountsFlat) {
        if (account.type !== FinancialEntityType.Account) {
            continue;
        }
        const accountData = byAccountId[account.id];
        if (!accountData) {
            continue;
        }
        const prop1Found = accountData.includes(prop1Id);
        const prop2Found = accountData.includes(prop2Id);
        if (prop1Found && prop2Found) {
            continue;
        }
        ret.push({
            feature: {
                id: account.id,
                name: formatterGlName(account.name, account.number)
            },
            prop1: prop1Found,
            prop2: prop2Found
        });
    }
    return ret;
}

function parseComparisionDataCustomDriver(
    rawData: GetMultiPropertyAccountDriversPropertyExplorerQuery["multiPropertyAccountDrivers"]["customDriver"],
    prop1Id: string,
    prop2Id: string,
): TComparisionData[] {
    const byItemName = rawData.groupBy("itemName", "propertyId");
    const ret: TComparisionData[] = Object.entries(byItemName)
        .filter(([_, propertyIds]) => propertyIds.length == 1)
        .sort(([a], [b]) => a.localeCompare(b))
        .map(([itemName, propertyIds]) => ({
            feature: {
                id: itemName,
                name: customDriverDispay(itemName),
            },
            prop1: propertyIds.includes(prop1Id),
            prop2: propertyIds.includes(prop2Id)
        }));
    return ret;
}

function parseComparisionDataOperational(
    rawData: GetMultiPropertyAccountDriversPropertyExplorerQuery["multiPropertyAccountDrivers"]["operational"],
    prop1Id: string,
    prop2Id: string,
): TComparisionData[] {
    const byItemName = rawData.groupBy("sourceMetricName", "propertyId");
    const ret: TComparisionData[] = Object.entries(byItemName)
        .filter(([_, propertyIds]) => propertyIds.length == 1)
        .sort(([a], [b]) => a.localeCompare(b))
        .map(([itemName, propertyIds]) => ({
            feature: {
                id: itemName,
                name: opMetricDisplay(itemName),
            },
            prop1: propertyIds.includes(prop1Id),
            prop2: propertyIds.includes(prop2Id)
        }));
    return ret;
}
function parseComparisionDataWorksheet(
    rawData: GetMultiPropertyAccountDriversPropertyExplorerQuery["multiPropertyAccountDrivers"]["worksheet"],
    prop1Id: string,
    prop2Id: string,
): TComparisionData[] {
    const byLineId = rawData.groupBy("description", "propertyId");
    const ret: TComparisionData[] = Object.entries(byLineId)
        .filter(([_, propertyIds]) => propertyIds.length == 1)
        .sort(([a], [b]) => a.localeCompare(b))
        .map(([description, propertyIds]) => {
            return {
                feature: {
                    id: description,
                    name: description
                },
                prop1: propertyIds.includes(prop1Id),
                prop2: propertyIds.includes(prop2Id)
            };
        });
    return ret;
}