import { IFormulaBarUpdates } from "../../../../../contexts/account/data/useFormulaBarUpdates";
import { IFinancialEntityWithDepth } from "../../../../../utils/account";
import {
    DriverMetricsMap,
    DriverMetricsMapObject,
    DriverMetricsTypes,
} from "./useFormulaBarMenu";
import { ModelingMethodName } from "./utils";

export const disabledStrings = {
    noCombine: "Cannot be combined with other modeling methods",
    noPermissions: "Current user cannot edit this modeling method",
    noRevenueInBulkUpdate: "The same revenue driver can’t be applied to multiple accounts",
    noIndividualAccPctUpdatesInBulkUpdate: "Use bulk update bar to set % Of Account",
    noCustomItemsSetup: "Ask Administrator to setup Custom Driver Items",
    onlyGrowth: "A growth driver can be the only one driver applied to a formula bar",
    alreadyApplied: "Already applied",
};

interface IDisabledConfig {
    disabled: boolean;
    disabledMessage: string;
}

export const shouldMetricBeDisabled = (
    currentMetric: DriverMetricsTypes,
    formulaBarUpdates: IFormulaBarUpdates,
    isWorksheetDriven: boolean,
    canUserEditModelingMethod: boolean,
    isBulkUpdateBar: boolean,
    disabledMenuItems: ModelingMethodName[],
    selectedAccountIds: string[],
): IDisabledConfig => {
    /**
     * First gate - if the user doesn't have permissions to edit the modeling method, then disable the menu item
     */
    if (!canUserEditModelingMethod) {
        return {
            disabled: true,
            disabledMessage: disabledStrings.noPermissions,
        };
    }

    if(disabledMenuItems.includes(currentMetric)) {
        return {
            disabled: true,
            disabledMessage: disabledStrings.noRevenueInBulkUpdate
        };
    }

    /**
     * The same revenue driver cannot be applied to multiple accounts within the same property
     * When the user is working with the bulk formula bar in the property drivers UI, we do the following check:
     * 1. Is exactly 1 account selected? -> Allow user to add revenue driver to the bulk update bar
     * 2. Are more than 1 accounts selected? -> Disable the revenue driver menu item
     */
    if (isBulkUpdateBar && currentMetric === DriverMetricsTypes.revenue && selectedAccountIds.length > 1) {
        return {
            disabled: true,
            disabledMessage: disabledStrings.noRevenueInBulkUpdate,
        };
    }

    if ([DriverMetricsTypes.monthlyAverage, DriverMetricsTypes.percentGrowth, DriverMetricsTypes.annualTargetValue].includes(currentMetric)) {
        let doOtherDriversExist = false;
        for (const driver of Object.values(formulaBarUpdates.parsedFormDrivers)) {
            if (driver instanceof Array && driver.length > 0) {
                doOtherDriversExist = true;
                break;
            }
        }
        return {
            disabled: doOtherDriversExist,
            disabledMessage: disabledStrings.noCombine,
        };
    } else {
        // Doing this as I don't want to return false if this condition isn't met
        if (formulaBarUpdates.parsedFormDrivers.monthlyAverage.length > 0
            || formulaBarUpdates.parsedFormDrivers.percentGrowth.length > 0
            || formulaBarUpdates.parsedFormDrivers.annualTargetValue.length > 0) {
            return {
                disabled: true,
                disabledMessage: disabledStrings.onlyGrowth,
            };
        }
    }
    if (currentMetric === DriverMetricsTypes.revenue) {
        const alreadyAddedRevenueDrivers = formulaBarUpdates.parsedFormDrivers[
            DriverMetricsTypes.revenue
        ].map((each) => each.revenueType);

        return {
            disabled: alreadyAddedRevenueDrivers.length > 0,
            disabledMessage: disabledStrings.alreadyApplied,
        }
    }

    if (currentMetric === DriverMetricsTypes.worksheet) {
        return {
            disabled: isWorksheetDriven || formulaBarUpdates.parsedFormDrivers[DriverMetricsTypes.worksheet].length > 0,
            disabledMessage: disabledStrings.alreadyApplied,
        };
    }

    if (currentMetric === DriverMetricsTypes.account) {
        return {
            disabled: selectedAccountIds.length > 1 && !isBulkUpdateBar,
            disabledMessage: disabledStrings.noIndividualAccPctUpdatesInBulkUpdate
        }
    }

    return {
        disabled: false,
        disabledMessage: '',
    };
};

export const getRootLevelMenuItems = (
    driverMetricsMap: DriverMetricsMap,
    fxBarUpdatesContext: IFormulaBarUpdates,
    isWorksheetDriven: boolean,
    editableFxBarChecker: ((modelingMethod: ModelingMethodName) => boolean) | undefined,
    isBulkUpdateBar: boolean,
    disabledMenuItems: ModelingMethodName[],
    selectedAccountIds: string[],
): DriverMetricsMapObject[] =>
    Object.values(driverMetricsMap).map(
        (driverMetric: DriverMetricsMapObject) => {
            // If editableFxBarChecker is undefined, then assume the user can edit all modeling methods
            const canUserEditModelingMethod = editableFxBarChecker ? editableFxBarChecker(driverMetric.name as ModelingMethodName) : true;
            return ({
                ...driverMetric,
                ...shouldMetricBeDisabled(driverMetric.name as DriverMetricsTypes, fxBarUpdatesContext, isWorksheetDriven, canUserEditModelingMethod, isBulkUpdateBar, disabledMenuItems, selectedAccountIds),
            });
        }
    );

export const getFilteredAccountDrivers = (flattenedCoA: IFinancialEntityWithDepth[], searchQuery: string): IFinancialEntityWithDepth[] => {

    const findAccountById = (accountId: string) => {
        for (let i = 0; i < flattenedCoA.length; i++) {
          if (flattenedCoA[i]?.id === accountId) {
            return flattenedCoA[i];
          }
        }
        return undefined;
    };

    const findAncestors = (node: IFinancialEntityWithDepth): IFinancialEntityWithDepth[] => {
        if (node.depth === 0) {
            return [node];
        }

        const parentId = node.parentId;

        if (parentId) {
            const parent = findAccountById(parentId);

            if (parent) {
                return [...findAncestors(parent), parent];
            }
        }

        return [];
    };

    const findChildren = (node: IFinancialEntityWithDepth, currentIndex: number): IFinancialEntityWithDepth[] => {
        const children: IFinancialEntityWithDepth[] = [];

        for (let i = currentIndex + 1; i < flattenedCoA.length; i++) {
            const child = flattenedCoA[i];
            if (child !== undefined) {
                if (child.depth === node.depth + 1) {
                    children.push(child);
                    children.push(...findChildren(child, i));
                } else if (child.depth <= node.depth) {
                    break;
                }
            }
        }

        return children;
    };

    const searchResults = new Set<IFinancialEntityWithDepth>();

    flattenedCoA.forEach((account, index) => {
        if (account.name.toLowerCase().includes(searchQuery.toLowerCase())
            || account.number.includes(searchQuery)) {
            const ancestors = findAncestors(account).dedupe(ancestor => ancestor.id);
            const children = findChildren(account, index).dedupe(child => child.id);

            // Add the matching node, its ancestors, and its children to the search results
            [...ancestors, account, ...children].forEach((item) => {
                searchResults.add(item);
            });
        }
    });

    // Convert the Set back to an array
    return Array.from(searchResults);
};

export const getTooltipPlacementProps = (isPropertyDriversUI: boolean | undefined, isBulkUpdateBar: boolean | undefined) => {
    // Getting around placement's type-safety
    type PlacementProps = "auto" | "top" | "bottom" | "top-start" | "top-end" | "bottom-start" | "bottom-end" | "end" | "end-top" | "end-bottom" | "start" | "start-top" | "start-bottom" | undefined;

    if (isPropertyDriversUI && isBulkUpdateBar) {
        return { placement: 'top' as PlacementProps };
    }
    if (!isPropertyDriversUI) {
        return { placement: 'bottom' as PlacementProps};
    }

    return undefined;
};

export const getPopperModifiers = (isPropertyDriversUI: boolean | undefined, isBulkUpdateBar: boolean | undefined, defaultPopperModifiers?: any) => {
    if (isPropertyDriversUI && !isBulkUpdateBar) {
        return [
            {
                name: 'preventOverflow',
                options: {
                    boundary: 'viewport',
                    padding: 300,
                    altBoundary: true,
                }
            }
        ];
    }
    return defaultPopperModifiers ? defaultPopperModifiers : [];
};
