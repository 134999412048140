// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace COLORS {

    export const GREY_50 = '#F7F8F9';
    export const GREY_75 = '#F0F1F3';
    export const GREY_100 = '#E8EAED';
    export const GREY_200 = '#D1D4DA';
    export const GREY_250 = '#C2C8CC;'
    export const GREY_300 = '#B3B8C2';
    export const GREY_400 = '#858D9D';
    export const GREY_500 = '#667085';
    export const GREY_600 = '#525A6A';
    export const GREY_700 = '#474E5D';
    export const GREY_800 = '#333843';

    export const GREEN_500 = '#007A56';

    export const RED_100 = '#FFF0F1';
    export const RED_500 = '#D93F4C';

    export const WHITE_SMOKE = '#f0f0f0';

    export const PRIMARY_30 = '#F1FBF7';
    export const PRIMARY_50 = '#F3F7F8';
    export const PRIMARY_70 = '#EEF6F7';
    export const PRIMARY_75 = '#E7F0F0';
    export const PRIMARY_100 = '#DBE8E9';
    export const PRIMARY_150 = "#B7D9DB";
    export const PRIMARY_200 = '#AAC9CC';
    export const PRIMARY_300 = '#6EA3A7';
    export const PRIMARY_400 = '#3D858A';
    export const PRIMARY_500 = '#0D666D';
    export const PRIMARY_600 = '#0A5257';
    export const PRIMARY_700 = '#09474C';
    export const PRIMARY_800 = '#083D41';
    export const PRIMARY_900 = '#062E31';

    export const ROYAL_25 = '#F0F6FF';
    export const ROYAL_50 = '#CEE1FE';
    export const ROYAL_75 = '#BAD1F5';
    export const ROYAL_100 = '#A5C1EB';
    export const ROYAL_200 = '#7DA2D9';
    export const ROYAL_300 = '#5482C6';
    export const ROYAL_400 = '#2C63B4';
    export const ROYAL_500 = '#0343A1';
    export const ROYAL_600 = '#023681';
    export const ROYAL_700 = '#022861';
    export const ROYAL_800 = '#011B40';
    export const ROYAL_900 = '#010D20';

    export const LAVENDER_200 = '#BEC4E0';
    export const LAVENDER_300 = '#9EA7D0';
    export const LAVENDER_400 = '#7D89C1';

    export const MAGENTA_400 = '#9C4D81';

    export const PINK_400 = '#E9B9D3';

    export const TRANSPARENT = "#00000000";

    export const POSITIVE_COLOR = '#007A56';
    export const NEGATIVE_COLOR = '#D93F4C';

    export const YELLOW_100 = "#FCD6A7";
    export const YELLOW_200 = "#FBC687";
    export const YELLOW_300 = "#FABC71";
    export const YELLOW_400 = "#F9AD50";
    export const YELLOW_500 = "#F79824";
}
