import { useHistory } from "react-router-dom";
import { parseCurrentDestination } from "../logic/parseCurrentDestination";
import { ReportsNavMenuConfig } from "../ReportsNavMenuConfig";

export enum Destination {
    Overview = "overview",
    LineItems = "line_items",
    PropertyExecutiveSummary = "property_executive_summary",
    MVR = "monthly_variance_report",
    FinancialReports = "financial_reports",
    OperationalReports = "operational_reports",
    PortfolioAnalysis = "portfolio_analysis",
    ModelingAnalysis = "modeling_analysis"
}

export type UseNavigationAPI = {
    goto: (destination: Destination) => void;
    currentDestination: Destination | undefined;
}

export function useNavigation(): UseNavigationAPI {
    const history = useHistory();

    const currentDestination = parseCurrentDestination(history.location.pathname);

    const goto = (destination: Destination) => {
        let newPath = `/${ReportsNavMenuConfig.OVERVIEW_PATH}`;
        if (destination === Destination.Overview) {
            newPath = `/${ReportsNavMenuConfig.OVERVIEW_PATH}`;
        }
        else if (destination == Destination.LineItems) {
            newPath = `/${ReportsNavMenuConfig.LINE_ITEMS_PATH}`;
        }
        else if (destination == Destination.PropertyExecutiveSummary) {
            newPath = `/${ReportsNavMenuConfig.PROPERTY_EXECUTIVE_SUMMARY}`;
        }
        else if (destination == Destination.MVR){
            newPath = `/${ReportsNavMenuConfig.MVR_PATH}`;
        }
        else if (destination == Destination.FinancialReports){
            newPath = `/${ReportsNavMenuConfig.FINANCIAL_REPORTS_PATH}`;
        }
        else if (destination == Destination.OperationalReports){
            newPath = `/${ReportsNavMenuConfig.OPERATIONAL_REPORTS_PATH}`;
        }
        else if (destination == Destination.PortfolioAnalysis) {
            newPath = `/${ReportsNavMenuConfig.PORTFOLIO_ANALYSIS}`;
        }
        else if (destination == Destination.ModelingAnalysis) {
            newPath = `/${ReportsNavMenuConfig.MODELING_ANALYSIS}`;
        }
        history.push(newPath);
    };

    return {
        goto: goto,
        currentDestination: currentDestination
    };
}
