import {ReactElement, ReactNode, useEffect, useState} from "react";
import {useListPropertyRevenueAccountsQuery, VersionType} from "../../../../__generated__/generated_types";
import {
    buildPropertyRevenueDriverAccountsDisplayData,
    buildPageData,
    rowKey,
    TData,
    TDataKey,
    buildFilteredData
} from "./logic";
import {useProperties} from "../../../../contexts/properties/PropertiesContext";
import {useChartOfAccounts} from "../../../../contexts/chartofaccounts/ChartOfAccountsContext";
import {DataDisplayTable} from "../../../../components/data-table/DataDisplayTable";
import {PAGE_SIZE, PaginationParams, usePagination} from "../../../../components/data-table/usePagination";
import {Button, ToggleButton} from "@zendeskgarden/react-buttons";
import {SortingParams, useSorting} from "../../../../components/data-table/useSorting";
import * as css from "./revenueaccs.module.scss";
import {Close, ToggleOffOutlined, ToggleOn} from "@material-ui/icons";
import {ReactComponent as CompareIcon} from '@zendeskgarden/svg-icons/src/16/duplicate-stroke.svg';
import {Tooltip} from "@zendeskgarden/react-tooltips";

const DISPLAY_COLUMNS: TDataKey[] = ["property", "GPR", "RENT", "RENT_SUBSIDY", "VACANCY", "LOSS_TO_LEASE", "GPR_AND_VACANCY"];
const BENCHMARKING_COLUMNS = DISPLAY_COLUMNS.slice(1) as Exclude<TDataKey, "property">[];
export interface PropertyRevenueDriverAccountsParams {
    budgetYear: number;
    versionType: VersionType.Reforecast | VersionType.Budget;
    headerRightComponent?: ReactNode;
    customLabelComponent?: ReactNode;
}

export function PropertyRevenueDriverAccounts(props: PropertyRevenueDriverAccountsParams): ReactElement | null {
    const {data: rawData, loading: rawDataLoading} = useListPropertyRevenueAccountsQuery({
        variables: {
            year: props.versionType == VersionType.Budget ? props.budgetYear : props.budgetYear - 1,
            versionType: props.versionType
        },
        fetchPolicy: "no-cache"
    });
    const {properties} = useProperties();
    const {chartOfAccountsFlat, isReady: coaReady} = useChartOfAccounts();

    const pagination = usePagination();
    const sorting = useSorting<TDataKey>();
    const [parsedData, setParsedData] = useState<TData[]>();
    const [filteredData, setFilteredData] = useState<TData[]>();
    const [benchmarkProperty, setBenchmarkProperty] = useState<TData>();
    const [displayColumns, setDisplayColumns] = useState<TDataKey[]>(DISPLAY_COLUMNS);
    const [nonEmptyColumns, setNonEmptyColumns] = useState<Set<TDataKey>>(new Set<TDataKey>(["property"]));
    const [hideEmptyColumns, setHideEmptyColumns] = useState(false);

    // displayData holds all data that needs to change atomically in a single render cycle
    const [displayData, setDisplayData] = useState<{
        rows: TData[] | undefined,
        benchmarkProperty: TData | undefined,
        paginationParams: PaginationParams,
        sortingParams: SortingParams<TDataKey>;
    }>({
        rows: undefined,
        benchmarkProperty: undefined,
        paginationParams: pagination.paginationParams,
        sortingParams: sorting.sortingParams
    });

    useEffect(() => {
        if (!rawData || rawDataLoading || !properties || !chartOfAccountsFlat || !coaReady) {
            return;
        }
        const {data, nonEmptyColumns} = buildPropertyRevenueDriverAccountsDisplayData(
            rawData.listPropertyRevenueAccounts,
            properties,
            chartOfAccountsFlat
        );
        setParsedData(data);
        setNonEmptyColumns(prev => new Set([...Array.from(prev), ...Array.from(nonEmptyColumns)]));
        pagination.set({total: data.length, pageSize: PAGE_SIZE});
        setBenchmarkProperty(undefined);
    }, [rawData, rawDataLoading, properties, chartOfAccountsFlat, coaReady]);


    useEffect(() => {
        if (!parsedData) {
            return;
        }
        const filteredData = buildFilteredData(parsedData, benchmarkProperty, BENCHMARKING_COLUMNS);
        pagination.set({total: filteredData.length});
        setFilteredData(filteredData);

    }, [parsedData, benchmarkProperty]);

    useEffect(() => {
        if (!filteredData) {
            return;
        }

        setDisplayData({
            rows: buildPageData({
                data: filteredData,
                page: pagination.paginationParams.currentPage,
                pageSize: pagination.paginationParams.pageSize,
                exclude: benchmarkProperty,
                sortColumn: sorting.sortingParams.sortColumn,
                sortType: sorting.sortingParams.sortType
            }),
            benchmarkProperty,
            paginationParams: pagination.paginationParams,
            sortingParams: sorting.sortingParams
        });
    }, [
        filteredData,
        pagination.paginationParams.totalPages,
        pagination.paginationParams.currentPage,
        pagination.paginationParams.pageSize,
        sorting.sortingParams.sortColumn,
        sorting.sortingParams.sortType
    ]);

    // NOTE: selecting and unselecting benchmark property resets current page to 1
    // If we really wanted to - we'd find out new value for currentPage based on changed page size
    // and the most of the items visible before benchmark property selection/unselection
    function handleSetBenchmarkProperty(row: TData) {
        if (!filteredData) {
            return;
        }
        pagination.set({total: filteredData.length, pageSize: PAGE_SIZE - 1, currentPage: 1});
        setBenchmarkProperty(row);
    }

    function handleClearBenchmarkProperty() {
        if (!filteredData) {
            return;
        }
        pagination.set({total: filteredData.length, pageSize: PAGE_SIZE, currentPage: 1});
        setBenchmarkProperty(undefined);
    }

    function hadnleToggleSort(column: TDataKey | undefined) {
        sorting.toggleSort(column);
        pagination.set({currentPage: 1});
    }

    function handleToggleEmptyColumns() {
        if (hideEmptyColumns) {
            setHideEmptyColumns(false);
            setDisplayColumns(DISPLAY_COLUMNS);
        }
        else {
            setHideEmptyColumns(true);
            setDisplayColumns(DISPLAY_COLUMNS.filter(c => nonEmptyColumns.has(c)));
        }
    }

    function cellDataToDisplay(key: TDataKey, row: TData): string | ReactElement | number {
        if (key === "property") {
            return (
                <div className={css.propertyCell}>
                    <span>{row.property.name}</span>
                    <Tooltip content="Compare To" placement="end">
                        <Button isBasic size="small" onClick={() => handleSetBenchmarkProperty(row)}><CompareIcon style={{minHeight: "18px", minWidth: "18px"}} /></Button>
                    </Tooltip>
                </div>
            );
        }
        return row[key as Exclude<TDataKey, "property">] ?? "";
    }

    function cellSecondHeaderDataToDisplay(key: TDataKey, row: TData): string | ReactElement | number {
        if (key === "property") {
            return (
                <div className={css.propertyCell}>
                    {row.property.name} <Button isBasic size="small" onClick={() => handleClearBenchmarkProperty()}><Close /></Button>
                </div>
            );
        }
        return row[key as Exclude<TDataKey, "property">] ?? "";
    }

    return (
        <div className={css.wrapper}>

            <div className={css.header}>
                {props.customLabelComponent ?? <h4>{PropertyRevenueDriverAccounts.label}</h4>}
                <div className={css.headerButtons}>
                    {props.headerRightComponent}
                    {nonEmptyColumns.size > 0 ?
                        <ToggleButton
                            isBasic
                            onClick={() => handleToggleEmptyColumns()}
                        >
                            {hideEmptyColumns ?
                                <ToggleOn fontSize="large" />
                                : <ToggleOffOutlined fontSize="large" />
                            }
                            <span style={{marginLeft: ".5rem"}}>Hide Empty Columns</span>
                        </ToggleButton>
                        :
                        null
                    }
                </div>
            </div>
            <div className={css.container}>
                <DataDisplayTable
                    className={css.tableWrapper}
                    dataProvider={{
                        rows: displayData?.rows,
                        totalRows: !parsedData ? undefined : Math.min(pagination.paginationParams.pageSize, parsedData.length),
                        columns: displayColumns,
                        secondHeaderRow: displayData?.benchmarkProperty
                    }}
                    headers={{
                        property: "Property",
                        GPR: "GPR",
                        RENT: "Rent",
                        LOSS_TO_LEASE: "Loss to Lease",
                        VACANCY: "Vacancy",
                        GPR_AND_VACANCY: "GPR & Vacancy",
                        RENT_SUBSIDY: "Rent Subsidy"
                    }}
                    paginationParams={{
                        params: displayData.paginationParams,
                        setPage: pagination.setPage
                    }}
                    sortingParams={{
                        columns: DISPLAY_COLUMNS,
                        params: displayData.sortingParams,
                        toggleSort: hadnleToggleSort,
                        currentSortForColumn: sorting.currentSortForColumn
                    }}
                    customCellRenderers={{
                        cellDataToDisplay: cellDataToDisplay,
                        cellSecondHeaderDataToDisplay: cellSecondHeaderDataToDisplay,
                        rowKey: rowKey
                    }}
                />
            </div>
        </div>
    );
}

PropertyRevenueDriverAccounts.label = "EXPLORE REVENUE CONFIGURATION";