import { Button } from "@zendeskgarden/react-buttons";
import { Col as GridCol, Grid, Row as GridRow } from "@zendeskgarden/react-grid";
import { Body, Cell, Head, HeaderCell, HeaderRow, Row, Table } from "@zendeskgarden/react-tables";
import {useCallback, useMemo} from "react";
import { ThemeProvider } from "@zendeskgarden/react-theming";
import { ViziblyTheme } from "../../../pages/analyst/ViziblyZDGTheme";
import {buildDummyData, IDriversAndWorksheetData} from "../../../contexts/account/data/useDriversAndWorksheetData";
import FormulaBar, {FORMULA_BAR_DUMMY_ACCOUNT_ID} from "../../workflows/account/formula-bar/FormulaBar";
import { ActiveView, IStashFxBarDirtyStateByAccountId, IUsePropertyDriversReturn } from "../logic/usePropertyDrivers";
import PropertyDriversFormulaBar from "./PropertyDriversFormulaBar";

import * as css from './styles/css.module.scss';
import LockButton from "./LockButton";
import BulkLockButton from "./BulkLockButton";

interface IEditFormulas {
    pd: IUsePropertyDriversReturn,
}

export default function EditFormulas({
    pd,
}: IEditFormulas): React.ReactElement {
    const handleClickOnCancel = () => {
        pd.setIsConfigureModelingMethodsModalOpen(false);
    };

    const handleClickOnNext = () => {
        pd.setShouldFxBarStashChanges(true);
        pd.setActiveView(ActiveView.editAssumptions);
    };

    // The entry-point to applying bulk-updates across all selected formula bars
    const handleApplyBulkUpdate = () => pd.setWillBulkUpdate(true);

    const handleStash = useCallback(
        ({
            accountId,
            dirtyDrivers,
            originalDrivers,
            isWorksheetDeleted,
        }: IStashFxBarDirtyStateByAccountId) => {
            pd.stashFxBarDirtyStateByAccountId({
                accountId,
                dirtyDrivers,
                originalDrivers,
                isWorksheetDeleted,
            });
        },
        [pd.stashFxBarDirtyStateByAccountId]
    );

    const dummyDWD = useMemo(() => {
        return buildDummyData(pd.propertyId, pd.year, pd.versionType, FORMULA_BAR_DUMMY_ACCOUNT_ID);
    }, [pd.year, pd.versionType, pd.propertyId]);


    return (
        <ThemeProvider theme={ViziblyTheme}>
            <Grid className={css.noPadding}>
                <div className={css.tableWrapper}>
                <GridRow className={`${css.tableTitleWrapper} ${css.noMargin}`}>
                    <GridCol className={css.tableTitle}>
                        Modeling Methods
                    </GridCol>
                    <GridCol className={css.forecastHeaderCol}>
                        <GridRow className={css.forecastPeriod}>
                            Forecast Period
                        </GridRow>
                        <GridRow className={css.year}>
                            {pd.versionType.toLowerCase()} {pd.year}
                        </GridRow>
                    </GridCol>
                </GridRow>
                <Table isReadOnly className={css.editFxBarTable}>
                    <Head className={css.tableHeader}>
                        <HeaderRow>
                            <HeaderCell width="75px">
                                <BulkLockButton
                                    bulkUpdateAccountLocks={pd.bulkUpdateAccountLocksDraft}
                                />
                            </HeaderCell>
                            <HeaderCell width="24%">
                                <div className={css.tableHeaderTextCell}>
                                    GL Account
                                </div>
                            </HeaderCell>
                            <HeaderCell>
                                <div className={css.tableHeaderTextCell}>
                                    Formula
                                </div>
                            </HeaderCell>
                        </HeaderRow>
                    </Head>
                    <Body>
                        {pd.selectedAccounts.map((account) => (
                            <Row className={css.tableBodyRow} key={account.id}>
                                <Cell className={css.tableBodyCell} width="75px">
                                    {pd.budgetYear && (
                                        <LockButton
                                            accountId={account.id}
                                            propertyId={pd.propertyId}
                                            budgetYear={pd.budgetYear}
                                            versionType={pd.versionType}
                                            isConfigureModalOpen={pd.isConfigureModelingMethodsModalOpen}
                                            updateAccountLocksByAccountId={pd.updateAccountLocksByAccountId}
                                            updateAccountLocksDraftByAccountId={pd.updateAccountLocksDraftByAccountId}
                                            isLocked={pd.isPropertyLocked || pd.accountLocksDraft.get(account.id)}
                                        />
                                    )}
                                </Cell>
                                <Cell className={css.tableBodyCell} width="24%">
                                    {account.number} {account.name}
                                </Cell>
                                <Cell className={`${css.tableBodyCell} ${css.editableFxBarCell}`}>
                                    <PropertyDriversFormulaBar
                                        accountId={account.id}
                                        isFxBarReadOnly={false}
                                        handleStash={handleStash}
                                        pd={pd}
                                        propertyId={pd.propertyId}
                                    />
                                </Cell>
                            </Row>
                        ))}
                    </Body>
                </Table>
                </div>
                <GridRow className={css.tableFooter}>
                    <GridCol className={css.bulkUpdateText}>
                        Bulk update {pd.selectedAccounts.length} GL account{pd.selectedAccounts.length > 1 ? 's' : ''}
                    </GridCol>
                    <GridCol>
                        {pd.budgetYear && (
                            <FormulaBar
                                budgetYear={pd.budgetYear}
                                year={pd.year}
                                versionType={pd.versionType}
                                currentPropertyId={pd.propertyId}
                                // TODO (Sahil): Either make accountId conditional or find another way
                                accountId={FORMULA_BAR_DUMMY_ACCOUNT_ID}
                                readOnly={false}
                                driversAndWorksheetData={dummyDWD}
                                isBulkUpdateBar
                                isCommitDisabled
                                isPropertyDriversUI
                                willBulkUpdate={pd.willBulkUpdate}
                                shareBulkUpdateState={pd.saveBulkUpdateState}
                                allAvailableDrivers={pd.allAvailableDrivers}
                                lineItemsMenuOptionsByAccountId={pd.lineItemsMenuOptionsByAccountId}
                                setLineItemsMenuOptionsByAccountId={pd.setLineItemsMenuOptionsByAccountId}
                                activeView={pd.activeView}
                                selectedAccountIds={pd.selectedAccounts.map((each) => each.id)}
                            />
                        )}
                    </GridCol>
                    <GridCol md="auto">
                        <Button onClick={handleApplyBulkUpdate}>
                            Apply
                        </Button>
                    </GridCol>
                </GridRow>
                <GridRow justifyContent="end" className={css.actionsRow}>
                    <GridCol md="auto">
                        <Button isBasic onClick={handleClickOnCancel}>
                            Cancel
                        </Button>
                    </GridCol>
                    <GridCol md="auto">
                        <Button isPrimary onClick={handleClickOnNext}>
                            Next
                        </Button>
                    </GridCol>
                </GridRow>
            </Grid>
        </ThemeProvider>
    );
}
