import {useState} from "react";

// Default page size
export const PAGE_SIZE = 9;

export type TPagination = ReturnType<typeof usePagination>;
export interface PaginationParams {
    currentPage: number;
    totalPages: number;
    pageSize: number;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function usePagination(initialPageSize?: number) {
    const [paginationParams, setPaginationParams] = useState<PaginationParams>({totalPages: 1, currentPage: 1, pageSize: initialPageSize ?? PAGE_SIZE});

    function set(params: {total?: number, pageSize?: number, currentPage?: number}) {
        setPaginationParams(prev => {
            let {total, pageSize, currentPage} = params;
            if (currentPage === undefined) {
                currentPage = prev.currentPage;
            }
            if (pageSize === undefined) {
                pageSize = prev.pageSize;
            }
            if (total === undefined) {
                total = prev.totalPages * pageSize;
            }
            let totalPages = Math.ceil(total / pageSize);
            if (totalPages === 0) {
                totalPages = 1;
            }
            return {
                totalPages: totalPages,
                currentPage: currentPage,
                pageSize: pageSize
            };
        });
    }

    function setPage(page: number) {
        if (paginationParams) {
            setPaginationParams({
                ...paginationParams,
                currentPage: page
            });
        }
    }

    return {
        paginationParams,
        set,
        setPage
    };
}