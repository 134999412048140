import { useEffect, useState } from "react";

import {
    GetSinglePropertyDriversAndWorksheetItemsQuery,
    PendingUpdates,
    useGetSinglePropertyDriversAndWorksheetItemsLazyQuery,
    useSetDriversMutation,
    VersionType
} from "../../../__generated__/generated_types";

import { hydrateDriverAssumptions, TDriverMetrics } from "./logic/driversAndWorksheetData";


export interface ISaveData {
    isUserRequestingWorksheetDeletion: boolean,
    pendingUpdates: PendingUpdates,
    accountId: string,
    propertyId: string,
    year: number,
    versionType: VersionType,
}

export interface IDriversAndWorksheetData {
    isReady: boolean,

    setPropertyId: (propertyId: string) => void,
    propertyId: string|undefined,

    setAccountId: (accountId: string) => void,
    accountId: string|undefined,

    fetch: (year: number, versionType:VersionType) => void,

    saveData: (args: ISaveData) => void,

    rawData: GetSinglePropertyDriversAndWorksheetItemsQuery|undefined,
    parsedData: TDriverMetrics|undefined,
    dataLoading: boolean,
    resetData: () => void,
}

export interface IAccountDriversAndWorksheetDataParams {
    year: number|undefined,
    versionType: VersionType|undefined,
    currentPropertyId: string|undefined,
    accountId: string,
}

interface IUseDriversAndWorksheetDataProps {
    propertyId: string|undefined,
    accountId: string|undefined,
}

export function buildDummyData(
    propertyId: string,
    year: number,
    versionType: VersionType.Budget | VersionType.Reforecast,
    accountId: string
): IDriversAndWorksheetData {
    return {
        isReady: true,

        // eslint-disable-next-line
        setPropertyId: (_propertyId) => {},
        propertyId: propertyId,

        // eslint-disable-next-line
        setAccountId: (_accountId) => {},

        // TODO (Sahil): Either make accountId conditional or find another way
        accountId: accountId,

        // eslint-disable-next-line
        fetch: (_year, _versionType) => {},

        // eslint-disable-next-line
        saveData: (_args) => {},

        rawData: undefined,
        parsedData: {
            year: year || 0,
            versionType: versionType,
            isDriven: true,
            drivers: {
                account: [],
                accountPercentageAugment: {
                    minValue: null,
                    maxValue: null
                },
                operational: [],
                revenue: [],
                worksheet: [],
                monthlyAverage: [],
                percentGrowth: [],
                annualTargetValue: [],
                payroll: [],
                renovations: [],
                customDriver: []
            },
        },
        dataLoading: false,

        // eslint-disable-next-line
        resetData: () => {},
    };
}

export default function useDriversAndWorksheetData(props: IUseDriversAndWorksheetDataProps): IDriversAndWorksheetData {
    const [isReady, setIsReady] = useState<boolean>(false);

    const [propertyId, _setPropertyId] = useState<string|undefined>(props.propertyId);
    const [accountId, _setAccountId] = useState<string|undefined>(props.accountId);

    const [SetDrivers, {
        data,
        loading,
    }] = useSetDriversMutation({
        notifyOnNetworkStatusChange: true
    });

    /**
     * isReady will be set to true when all dependencies checked here are available.
     */
    useEffect(
        () => {
            if(
                accountId == undefined
                || propertyId == undefined
            ){
                return;
            }
            setIsReady(true);
        },
        [propertyId, accountId]
    );

    useEffect(
        () => {
            if(!props.accountId){
                resetData();
                return;
            }
            setAccountId(props.accountId);
        },
        [props.accountId]
    );

    useEffect(
        () => {
            if(!props.propertyId){
                resetData();
                return;
            }
            setAccountId(props.propertyId);
        },
        [props.propertyId]
    );

    // Drivers and Worksheet Line Items data
    const [_fetchDriverAndWorksheetData, {
        data: rawData,
        loading: dataLoading,
        // error: dataError
    }] = useGetSinglePropertyDriversAndWorksheetItemsLazyQuery({
        fetchPolicy: "network-only",
    });

    const setPropertyId = (propertyId: string): void => {
        _setPropertyId(propertyId);
    };

    const setAccountId = (accountId: string): void => {
        _setAccountId(accountId);
    };

    const fetch = (year: number, versionType:VersionType): void => {
        if(
            accountId === undefined
            || propertyId === undefined
            || !isReady
            || year === undefined
            || versionType === undefined
        ){
            return;
        }

        _fetchDriverAndWorksheetData({
            variables: {
                propertyId,
                accountId,
                year,
                versionType,
            }
        });
    };

    const resetData = (): void => {
        setParsedData(undefined);
    };

    /**
     * Re-fetch drivers after SetDrivers() executes successfully
     */
    useEffect(() => {
        if (data === undefined || data === null) {
            return;
        }

        fetch(data.setDrivers.year, data.setDrivers.versionType);
    }, [data]);

    const saveData = (args: ISaveData): void => {
        SetDrivers({
            variables: {
                accountId: args.accountId,
                propertyId: args.propertyId,
                year: args.year,
                versionType: args.versionType,
                isUserRequestingWorksheetDeletion: args.isUserRequestingWorksheetDeletion,
                pendingUpdates: args.pendingUpdates,
            },
        });
    };

    const [parsedData, setParsedData] = useState<TDriverMetrics>();
    useEffect(
        () => {
            if(dataLoading || rawData === undefined){
                return;
            }

            setParsedData( prevState => {
                return {
                    ...prevState ?? {},
                    ...hydrateDriverAssumptions(rawData),
                };
            });

        },
        [dataLoading, rawData]
    );

    return {
        isReady,

        setPropertyId,
        propertyId,

        setAccountId,
        accountId,

        fetch,

        resetData,

        saveData,

        rawData,
        parsedData,
        dataLoading,
    };
}
