import {ReactElement, ReactNode, useEffect, useMemo, useState} from "react";
import {
    buildPageData,
    COLUMN_NAMES,
    DISPLAY_COLUMNS,
    rowKey,
    TData,
    TDataKey,
} from "./logic";
import {useProperties} from "../../../../contexts/properties/PropertiesContext";
import {useChartOfAccounts} from "../../../../contexts/chartofaccounts/ChartOfAccountsContext";
import {DataDisplayTable} from "../../../../components/data-table/DataDisplayTable";
import {PAGE_SIZE, PaginationParams, usePagination} from "../../../../components/data-table/usePagination";
import {Button, ToggleButton} from "@zendeskgarden/react-buttons";
import {SortingParams, useSorting} from "../../../../components/data-table/useSorting";
import * as css from "./accountdrivers.module.scss";
import {Close, ToggleOffOutlined, ToggleOn} from "@material-ui/icons";
import {ReactComponent as CompareIcon} from '@zendeskgarden/svg-icons/src/16/duplicate-stroke.svg';
import {ReactComponent as AlertIcon} from '@zendeskgarden/svg-icons/src/16/alert-warning-stroke.svg';
import {Tooltip} from "@zendeskgarden/react-tooltips";
import {useDriverDataLoader} from "./DriverDataLoader";
import {useDriverDataTransformer} from "./DriverDataTransformer";
import {FinancialEntityType, VersionType} from "../../../../__generated__/generated_types";
import {formatterGlName} from "../../../../utils/formatters";
import {AccountSelector, AccountSelectorParams} from "./AccountSelector";
import {usePositionsAndCompItemsDataProvider} from "../shared/PositionsAndDisplayColumnsProvider";
import {DiffModalSingle, IDiffModalSingle} from "./modals/DiffModalSingle";
import {DiffModalMulti, IDiffModalMulti} from "./modals/DiffModalMulti";
import {isFeatureKey} from "./modals/DiffModalMultiLogic";
import {DiffModalPayroll, IDiffModalPayroll} from "./modals/DiffModalPayroll";

export interface AccountDriversParams {
    budgetYear: number;
    versionType: VersionType;
    headerRightComponent?: ReactNode;
    customLabelComponent?: ReactNode;
}

export function AccountDrivers(props: AccountDriversParams): ReactElement | null {
    const [benchmarkProperty, setBenchmarkProperty] = useState<TData>();

    const {properties} = useProperties();
    const {chartOfAccountsFlat, isReady: coaReady} = useChartOfAccounts();
    const [selectedAccountId, setSelectedAccountId] = useState<string>();

    const [settings, setSettings] = useState({
        hideEmptyColumns: false,
        displayColumns: DISPLAY_COLUMNS
    });

    const [showDiffModal, setShowDiffModal] = useState<{
        type: "single" | "multi" | "payroll",
        data: Omit<IDiffModalSingle, "onClose">
        | Omit<IDiffModalMulti, "onClose">
        | Omit<IDiffModalPayroll, "onClose">;
    }>();

    const {
        positions,
        compItemIds,
        compItemNames
    } = usePositionsAndCompItemsDataProvider();

    const {parsedData, parsedDataLoading} = useDriverDataLoader({
        properties: properties,
        year: props.versionType === VersionType.Budget ? props.budgetYear : props.budgetYear - 1,
        versionType: props.versionType,
        accountId: selectedAccountId,
        chartOfAccountsFlat: coaReady ? chartOfAccountsFlat : undefined,
        positions,
        compItemIds,
        compItemNames
    });

    const {transformedData, columnDiffs, nonEmptyColumns} = useDriverDataTransformer({
        parsedData,
        parsedDataLoading,
        displayColumns: DISPLAY_COLUMNS,
        benchmarkProperty
    });

    const pagination = usePagination();
    const sorting = useSorting<TDataKey>();

    // displayPageData holds all data that needs to change atomically in a single render cycle
    const [displayPageData, setDisplayPageData] = useState<{
        rows: TData[] | undefined,
        benchmarkProperty: TData | undefined,
        paginationParams: PaginationParams,
        sortingParams: SortingParams<TDataKey>;
    }>({
        rows: undefined,
        benchmarkProperty: undefined,
        paginationParams: pagination.paginationParams,
        sortingParams: sorting.sortingParams
    });

    useEffect(() => {
        if (!transformedData) {
            return;
        }
        pagination.set({total: transformedData.length, currentPage: 1});
    }, [transformedData]);

    useEffect(() => {
        if (!transformedData) {
            return;
        }

        setDisplayPageData({
            rows: buildPageData({
                data: transformedData,
                page: pagination.paginationParams.currentPage,
                pageSize: pagination.paginationParams.pageSize,
                exclude: benchmarkProperty,
                sortColumn: sorting.sortingParams.sortColumn,
                sortType: sorting.sortingParams.sortType
            }),
            benchmarkProperty,
            paginationParams: pagination.paginationParams,
            sortingParams: sorting.sortingParams
        });
        setSettings(prev => ({
            ...prev,
            displayColumns: prev.hideEmptyColumns ? DISPLAY_COLUMNS.filter(c => nonEmptyColumns.has(c)) : DISPLAY_COLUMNS
        }));
    }, [
        transformedData,
        nonEmptyColumns,
        pagination.paginationParams.currentPage,
        pagination.paginationParams.pageSize,
        pagination.paginationParams.totalPages,
        sorting.sortingParams.sortColumn,
        sorting.sortingParams.sortType
    ]);

    useEffect(() => {
        setBenchmarkProperty(undefined);
    }, [props.versionType]);


    useEffect(() => {
        if (!chartOfAccountsFlat || !coaReady) {
            return;
        }
        setSelectedAccountId(chartOfAccountsFlat.find(acc => acc.type === FinancialEntityType.Account)?.id);
        setBenchmarkProperty(undefined);
        sorting.reset();
    }, [chartOfAccountsFlat, coaReady]);


    // NOTE: selecting and unselecting benchmark property resets current page to 1
    // If we really wanted to - we'd find out new value for currentPage based on changed page size
    // and the most of the items visible before benchmark property selection/unselection
    function handleSetBenchmarkProperty(row: TData) {
        if (!transformedData) {
            return;
        }
        pagination.set({total: transformedData.length, pageSize: PAGE_SIZE - 1, currentPage: 1});
        setBenchmarkProperty(row);
    }

    function handleClearBenchmarkProperty() {
        if (!transformedData) {
            return;
        }
        pagination.set({total: transformedData.length, pageSize: PAGE_SIZE, currentPage: 1});
        sorting.reset();
        setBenchmarkProperty(undefined);
    }

    function hadnleToggleSort(column: TDataKey | undefined) {
        sorting.toggleSort(column);
        pagination.set({currentPage: 1});
    }

    function handleSelectAccount(accountId: string) {
        setSelectedAccountId(accountId);
        setBenchmarkProperty(undefined);
    }

    function handleToggleEmptyColumns() {
        if (settings.hideEmptyColumns) {
            setSettings(prev => ({
                ...prev,
                hideEmptyColumns: false,
                displayColumns: DISPLAY_COLUMNS
            }));
        }
        else {
            setSettings(prev => ({
                ...prev,
                hideEmptyColumns: true,
                displayColumns: DISPLAY_COLUMNS.filter(c => nonEmptyColumns.has(c))
            }));
        }
    }

    function handleShowDiffModal(benchmarkProperty: TData | undefined, compareWithProperty: TData, column: Exclude<TDataKey, "property">) {
        if (!benchmarkProperty || !selectedAccountId || !chartOfAccountsFlat) {
            return;
        }
        if (["monthlyAverage", "growthPercent", "annualTargetAmount", "revenue"].includes(column)) {
            const data: Omit<IDiffModalSingle, "onClose"> = {
                label: `${COLUMN_NAMES[column]} Configuration Difference`,
                first: {
                    property: benchmarkProperty.property,
                    driverData: benchmarkProperty[column]
                },
                second: {
                    property: compareWithProperty.property,
                    driverData: compareWithProperty[column]
                },
            };

            setShowDiffModal({type: "single", data});
        }
        else if (isFeatureKey(column)) {
            let featureLabel = "Account";
            let modalHeader = "Compare Source Accounts Between Properties";
            if (column === "worksheet") {
                featureLabel = "Line Item";
                modalHeader = "Compare Line Items Between Properties";
            }
            else if (column === "operational") {
                featureLabel = "Operational Metric";
                modalHeader = "Compare Operational Metrics Between Properties";
            }
            else if (column === "customDriver") {
                featureLabel = "Item Name";
                modalHeader = "Compare Custom Items Between Properties";
            }
            const data: Omit<IDiffModalMulti, "onClose"> = {
                featureLabel,
                modalHeader,
                featureKey: column,
                chartOfAccountsFlat,
                firstProperty: benchmarkProperty,
                secondProperty: compareWithProperty,
                accountId: selectedAccountId,
                year: props.versionType === VersionType.Budget ? props.budgetYear : props.budgetYear - 1,
                versionType: props.versionType
            };
            setShowDiffModal({type: "multi", data});
        }
        else if (column === "payroll") {
            const data: Omit<IDiffModalPayroll, "onClose"> = {
                firstProperty: benchmarkProperty,
                secondProperty: compareWithProperty,
                accountId: selectedAccountId,
                positions,
                compItemIds,
                compItemNames,
                year: props.versionType === VersionType.Budget ? props.budgetYear : props.budgetYear - 1,
                versionType: props.versionType
            };

            setShowDiffModal({type: "payroll", data});
        }
    }

    function cellDataToDisplay(key: TDataKey, row: TData): string | ReactElement | number {
        if (key === "property") {
            return (
                <div className={css.propertyCell}>
                    <span>{row.property.name}</span>
                    <Tooltip content="Compare To" placement="end">
                        <Button isBasic size="small" onClick={() => handleSetBenchmarkProperty(row)}><CompareIcon style={{minHeight: "18px", minWidth: "18px"}} /></Button>
                    </Tooltip>
                </div>
            );
        }
        if (columnDiffs?.get(row.property.id)?.[key]) {
            return (
                <div className={css.diffCell}
                    onClick={() => handleShowDiffModal(benchmarkProperty, row, key)}
                >
                    <Tooltip content="Show Difference" placement="end">
                        <AlertIcon style={{minHeight: "18px", minWidth: "18px"}} />
                    </Tooltip>
                    <span>{row[key]?.content ?? <>&nbsp;</>}</span>
                </div>
            );
        }
        return row[key]?.content ?? "";
    }

    function cellSecondHeaderDataToDisplay(key: TDataKey, row: TData): string | ReactElement | number {
        if (key === "property") {
            return (
                <div className={css.propertyCell}>
                    {row.property.name} <Button isBasic size="small" onClick={() => handleClearBenchmarkProperty()}><Close /></Button>
                </div>
            );
        }
        return row[key]?.content ?? "";
    }

    const accountsForDropdown: AccountSelectorParams["accounts"] | undefined = useMemo(() => {
        if (!chartOfAccountsFlat || !coaReady) {
            return undefined;
        }
        return chartOfAccountsFlat
            .filter(account => account.type === FinancialEntityType.Account)
            .map(account => ({id: account.id, fullName: formatterGlName(account.name, account.number)}));
    }, [chartOfAccountsFlat, coaReady]);

    return (
        <div className={css.wrapper}>
            <div className={css.header}>
                {props.customLabelComponent ?
                    <div className={css.customLabelWrapper}>
                        {props.customLabelComponent}<h4>&nbsp;|&nbsp;Account&nbsp;</h4>
                    </div>
                    :
                    <h4>{AccountDrivers.label}&nbsp;|&nbsp;Account&nbsp;</h4>
                }
                {accountsForDropdown && selectedAccountId &&
                    <AccountSelector
                        accounts={accountsForDropdown}
                        selectedAccountId={selectedAccountId}
                        onSelect={(id) => handleSelectAccount(id)}
                    />
                }
                <div className={css.headerButtons}>
                    {props.headerRightComponent}
                    <ToggleButton
                        isBasic
                        onClick={() => handleToggleEmptyColumns()}
                    >
                        {settings.hideEmptyColumns ?
                            <ToggleOn fontSize="large" />
                            : <ToggleOffOutlined fontSize="large" />
                        }
                        <span style={{marginLeft: ".5rem"}}>Hide Empty Columns</span>
                    </ToggleButton>
                </div>
            </div>
            <div className={css.container}>
                <DataDisplayTable
                    className={css.tableWrapper}
                    dataProvider={{
                        rows: displayPageData?.rows,
                        totalRows: !transformedData ? undefined : Math.max(pagination.paginationParams.pageSize, transformedData.length),
                        columns: settings.displayColumns,
                        secondHeaderRow: displayPageData?.benchmarkProperty
                    }}
                    headers={COLUMN_NAMES}
                    paginationParams={{
                        params: displayPageData.paginationParams,
                        setPage: pagination.setPage
                    }}
                    sortingParams={{
                        columns: settings.displayColumns,
                        params: displayPageData.sortingParams,
                        toggleSort: hadnleToggleSort,
                        currentSortForColumn: sorting.currentSortForColumn
                    }}
                    customCellRenderers={{
                        cellDataToDisplay: cellDataToDisplay,
                        cellSecondHeaderDataToDisplay: cellSecondHeaderDataToDisplay,
                        rowKey: rowKey
                    }}
                />
            </div>
            {showDiffModal && showDiffModal.type === "single" &&
                <DiffModalSingle {...(showDiffModal.data as IDiffModalSingle)} onClose={() => setShowDiffModal(undefined)} />
            }
            {showDiffModal && showDiffModal.type === "multi" &&
                <DiffModalMulti {...(showDiffModal.data as IDiffModalMulti)} onClose={() => setShowDiffModal(undefined)} />
            }
            {showDiffModal && showDiffModal.type === "payroll" &&
                <DiffModalPayroll {...(showDiffModal.data as IDiffModalPayroll)} onClose={() => setShowDiffModal(undefined)} />
            }
        </div>
    );
}

AccountDrivers.label = "EXPLORE ACCOUNT CONFIGURATION";