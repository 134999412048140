import {ReactElement, useEffect, useState} from "react";
import * as css from "./diffmodalpayroll.module.scss";
import {DataDisplayTable} from "../../../../../components/data-table/DataDisplayTable";
import {Body, Close, Header, Modal} from "@zendeskgarden/react-modals";
import {TData} from "../logic";
import {useGetMultiPropertyAccountDriversPropertyExplorerLazyQuery, VersionType} from "../../../../../__generated__/generated_types";
import {buildPageData, hidrateHeaderRows, parseComparisionData, TComparisionData} from "./DiffModalPayrollLogic";
import {PaginationParams, usePagination} from "../../../../../components/data-table/usePagination";
import {TPositionsAndCompItemsDataProvider} from "../../shared/PositionsAndDisplayColumnsProvider";

export interface IDiffModalPayroll {
    firstProperty: Pick<TData, "property">;
    secondProperty: Pick<TData, "property">;
    accountId: string;
    positions: TPositionsAndCompItemsDataProvider["positions"],
    compItemIds: TPositionsAndCompItemsDataProvider["compItemIds"],
    compItemNames: TPositionsAndCompItemsDataProvider["compItemNames"],
    year: number;
    versionType: VersionType;
    onClose: () => void;
}

const PAGE_SIZE = 14;

export function DiffModalPayroll(props: IDiffModalPayroll): ReactElement {
    const [fetchData, {data, loading}] = useGetMultiPropertyAccountDriversPropertyExplorerLazyQuery({fetchPolicy: "no-cache"});

    const pagination = usePagination(PAGE_SIZE);

    const [comparisionData, setComparisionData] = useState<TComparisionData[]>();
    // displayPageData holds all data that needs to change atomically in a single render cycle
    const [displayPageData, setDisplayPageData] = useState<{
        rows: TComparisionData[] | undefined,
        paginationParams: PaginationParams,
    }>({
        rows: undefined,
        paginationParams: pagination.paginationParams,
    });

    useEffect(() => {
        fetchData({
            variables: {
                yearsVersionTypes: [{
                    year: props.year,
                    versionType: props.versionType
                }],
                accountId: props.accountId,
                propertyIds: [props.firstProperty.property.id, props.secondProperty.property.id],
            }
        });
    }, [
        props.firstProperty.property.id,
        props.secondProperty.property.id,
        props.year,
        props.versionType
    ]);

    useEffect(() => {
        if (!data
            || loading
            || !props.positions
            || !props.compItemIds
            || !props.compItemNames
        ) {
            return;
        }

        let comparisionData = parseComparisionData(
            props.firstProperty.property.id,
            props.secondProperty.property.id,
            data.multiPropertyAccountDrivers.payroll,
            props.positions,
            props.compItemIds,
            props.compItemNames
        );
        comparisionData = hidrateHeaderRows(comparisionData, PAGE_SIZE);
        setComparisionData(comparisionData);
        pagination.set({total: comparisionData.length, currentPage: 1});
    }, [data, loading, props.positions, props.compItemIds, props.compItemNames]);

    useEffect(() => {
        if (!comparisionData) {
            return;
        }
        setDisplayPageData({
            rows: buildPageData({
                data: comparisionData,
                page: pagination.paginationParams.currentPage,
                pageSize: pagination.paginationParams.pageSize,
            }),
            paginationParams: pagination.paginationParams,
        });

    }, [
        comparisionData,
        pagination.paginationParams.currentPage,
        pagination.paginationParams.pageSize,
        pagination.paginationParams.totalPages,
    ]);

    function cellDataToDisplay(key: "compItem" | "prop1" | "prop2", row: TComparisionData): string | ReactElement | number {
        if (key === "compItem") {
            if (row.propData === undefined) {
                return <div className={css.compItemRow}>{row.compItem.name}</div>;
            }
            return "";
        }

        if (row.propData && row.propData[key]) {
            return row.propData.position.name;
        }

        return "";
    }

    return (
        <Modal onClose={props.onClose} isLarge className={css.modal}>
            <Header>
                <span>Compare Payroll Configuration</span>
            </Header>
            <Body>
                {displayPageData.rows &&
                    <div className={css.tableWrapper}>
                        <DataDisplayTable
                            className={css.tableWrapper}
                            dataProvider={{
                                rows: displayPageData?.rows,
                                totalRows: !comparisionData ? undefined : Math.min(pagination.paginationParams.pageSize, comparisionData.length),
                                columns: ["compItem", "prop1", "prop2"],
                                secondHeaderRow: undefined
                            }}
                            headers={{
                                compItem: "Compensation Item",
                                prop1: props.firstProperty.property.name,
                                prop2: props.secondProperty.property.name
                            }}
                            paginationParams={{
                                params: displayPageData.paginationParams,
                                setPage: pagination.setPage
                            }}
                            sortingParams={undefined}
                            customCellRenderers={{
                                cellDataToDisplay: cellDataToDisplay,
                                cellSecondHeaderDataToDisplay: undefined,
                                rowKey: (data) => data.compItem.id + (data.propData?.position.id ?? "")
                            }}
                        />
                    </div>
                }
            </Body>
            <Close aria-label="Close modal" />
        </Modal>
    );
}