import "native-injects";
import {FinancialEntity} from "../../../../contexts/chartofaccounts/ChartOfAccountsContext";
import {Property} from "../../../../contexts/properties/PropertiesContext";
import {formatterGlName} from "../../../../utils/formatters";
import {SortType} from "../../../../utils/sorting";
import {PayrollDriversQueryData} from "./DriverDataLoader";
import {GetPayrollCompensationItemsQuery, PayrollCompensationItemType, PayrollPrimaryCompensationItem} from "../../../../__generated__/generated_types";


export type TAccountInfo = {
    accountId: string,
    name: string
}

export type TCompItemData = Record<string, // Comp Item (one of PayrollPrimaryCompensationItem) or comp item id
                                    TAccountInfo[]>;

export type TData = {
    property: {
        name: string;
        id: string;
    },
    compensationItems: TCompItemData
}

export type TDataKey = string;

export function buildPropertyPayrollDriverAccountsDisplayData(
    rawData: PayrollDriversQueryData,
    properties: Property[],
    accounts: FinancialEntity[],
    compItemKeys: string[]
): {data: TData[]} {
    const propertiesMap = properties.toIdMap("id");
    const accountsMap = accounts.toIdMap("id");

    const transformed: TData[] = [];
    for (const dataRow of rawData) {
        const property = propertiesMap[dataRow.propertyId];
        if (!property) {
            continue;
        }
        const compensationItems:TCompItemData = {};
        for (const compItemKey of compItemKeys) {
            compensationItems[compItemKey] = [];
        }
        const transformedRow: TData = {
            property: {name: property.name, id:dataRow.propertyId},
            compensationItems: compensationItems
        };
        for (const primaryCompAccount of dataRow.primaryCompensationAccounts) {
            const compItemAccounts = compensationItems[primaryCompAccount.primaryCompensationItem];
            if (!compItemAccounts) {
                continue;
            }
            const acc = accountsMap[primaryCompAccount.accountId];
            if (!acc) {
                continue;
            }
            compItemAccounts.push({
                accountId: primaryCompAccount.accountId,
                name: formatterGlName(acc.name, acc.number)
            });
        }
        for (const compItemAccount of dataRow.compensationItemAccounts) {
            const compItemAccounts = compensationItems[compItemAccount.compensationItemId];
            if (!compItemAccounts) {
                continue;
            }
            const acc = accountsMap[compItemAccount.accountId];
            if (!acc) {
                continue;
            }
            compItemAccounts.push({
                accountId: compItemAccount.accountId,
                name: formatterGlName(acc.name, acc.number)
            });
        }
        const sortedCompItems:TCompItemData = {};
        for (const [key, values] of Object.entries(transformedRow.compensationItems)) {
            sortedCompItems[key] = values.sortBy("name");
        }
        transformedRow.compensationItems = sortedCompItems;
        transformed.push(transformedRow);
    }

    return {
        data: transformed.sortBy((row) => row.property.name),
    };
}

export function buildPageData(params: {
    data: TData[],
    page: number,
    pageSize: number,
    exclude: TData|undefined,
    sortColumn: string | undefined,
    sortType: SortType | undefined
}): TData[] {
    const {data, page, pageSize, exclude, sortColumn, sortType} = params;

    let dataForProcessing = data;

    if (sortColumn && sortType) {
        dataForProcessing = [...data];
        const descMultiplier = sortType == "asc" ? 1 : -1;
        dataForProcessing = dataForProcessing.sort((a, b) => {
            let ret = 0;
            if (sortColumn === "property") {
                ret = a.property.name < b.property.name ? -1 :
                    a.property.name > b.property.name ? 1 : 0;
                ret = ret * descMultiplier;
            }
            else {
                // always move empty values to start
                const aVal = a.compensationItems[sortColumn]?.[0]?.name ?? "";
                const bVal = b.compensationItems[sortColumn]?.[0]?.name ?? "";
                if (aVal === "" && bVal === "") {
                    ret = a.property.name < b.property.name ? -1 :
                        a.property.name > b.property.name ? 1 : 0;
                }
                else if (aVal === "") {
                    ret = -1;
                }
                else if (bVal === "") {
                    ret = 1;
                }
                else if (aVal < bVal) {
                    ret = -1 * descMultiplier;
                }
                else if (aVal > bVal) {
                    ret = 1 * descMultiplier;
                }
                else {
                    ret = a.property.name < b.property.name ? -1 :
                        a.property.name > b.property.name ? 1 : 0;
                }
            }
            return ret;
        });
    }

    const start = (page - 1) * pageSize;
    if (exclude) {
        return dataForProcessing.filter(row => row.property.id !== exclude.property.id).slice(start, start + pageSize);
    }
    return dataForProcessing.slice(start, start + pageSize);
}

export function rowKey(row: TData): string | number {
    return row.property.id;
}

export function buildFilteredData(
    data: TData[],
    benchmarkProperty: TData | undefined,
    keys: string[],
    filterMultiassigned: boolean,
    filterUnassigned: boolean
): TData[] {
    let filteredData = data;
    if (keys.length > 0 && (filterMultiassigned || filterUnassigned)) {
        filteredData = filteredData.filter(row => {
            const reduced = Object.entries(row.compensationItems)
                .filter(([key]) => keys.includes(key));

            return reduced.some(([_, accs]) => (!filterMultiassigned || filterMultiassigned && accs.length > 1))
                || reduced.some(([_, accs]) => (!filterUnassigned || filterUnassigned && accs.length === 0));
        });
    }
    if (benchmarkProperty) {
        filteredData = filteredData.filter(row => {
            if (row.property.id === benchmarkProperty.property.id) {
                return false;
            }
            let isDifferent = false;
            for (const key of keys) {
                isDifferent = benchmarkProperty.compensationItems[key]?.[0]?.name !== row.compensationItems[key]?.[0]?.name;
                if (isDifferent) {
                    break;
                }
            }
            return isDifferent;
        });
    }
    return filteredData;
}