import {useEffect, useMemo, useState} from "react";
import {LoadPayrollDriversByPositionQuery, useLoadPayrollDriversByPositionLazyQuery} from "../../../../__generated__/generated_types";
import {ApolloError} from "@apollo/client";
import {Property} from "../../../../contexts/properties/PropertiesContext";

const TAKE = 200;

export interface DriverDataLoaderParams {
    budgetYear: number | undefined;
    positionId: string | undefined;
    properties: Property[] | undefined;
}

export type PayrollDriversQueryData = LoadPayrollDriversByPositionQuery["payrollDriversByPosition"]["items"];
export type TDriverDataLoader = ReturnType<typeof useDriverDataLoader>;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useDriverDataLoader(props: DriverDataLoaderParams) {
    const [data, setData] = useState<PayrollDriversQueryData | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<ApolloError | undefined>(undefined);
    const [partialData, setPartialData] = useState<PayrollDriversQueryData>([]);

    const [getDriversData, {data: driversData, loading: driversLoading, error: driversError}] = useLoadPayrollDriversByPositionLazyQuery({
        fetchPolicy: "no-cache"
    });

    const propertyIds = useMemo(() => {
        if (!props.properties) {
            return undefined;
        }
        return props.properties.map(p => p.id);
    }, [props.properties]);

    useEffect(() => {
        if(!driversLoading && driversData) {
            if(driversData.payrollDriversByPosition.page < driversData.payrollDriversByPosition.maxPage
                && props.budgetYear
                && props.positionId
                && propertyIds
            ) {
                setPartialData([...partialData, ...driversData.payrollDriversByPosition.items]);
                getDriversData({
                    variables: {
                        budgetYear: props.budgetYear,
                        positionId: props.positionId,
                        propertyIds: propertyIds,
                        take: TAKE,
                        page: driversData.payrollDriversByPosition.page + 1
                    }
                });
            } else {
                setData([...partialData, ...driversData.payrollDriversByPosition.items]);
                setLoading(false);
                setPartialData([]);
            }
        }
    }, [driversLoading, driversData]);

    useEffect(() => {
        if(!driversLoading && driversError) {
            setLoading(false);
            setError(driversError);
        }
    }, [driversLoading, driversError]);

    useEffect(() => {
        if(props.budgetYear
            && props.positionId
            && propertyIds
        ){
            setData(undefined);
            setLoading(true);
            getDriversData({
                variables: {
                    budgetYear: props.budgetYear,
                    positionId: props.positionId,
                    propertyIds: propertyIds,
                    take: TAKE,
                    page: 1
                }
            });
        }
    }, [props.budgetYear, props.positionId, propertyIds]);

    return {
        data,
        loading,
        error,
    };
}