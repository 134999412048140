import { Chart, CategoryScale, registerables, ChartEvent, ActiveElement } from "chart.js";
import { Line } from "react-chartjs-2";
import dragDataPlugin from "chartjs-plugin-dragdata";
import { ReactElement, useRef, useState, useEffect, useMemo } from "react";
import { MONTHS, MONTHS_LONG } from "../../../../constants/Months";
import { COLORS } from "../../../../constants/Colors";
import { Button } from "@zendeskgarden/react-buttons";
import { DragDataEvent } from "chartjs-plugin-dragdata/dist/types/EventTypes";
import cn from "classnames";
import css from "./lease-expiration-curve.module.scss";
import { ReactComponent as CurveBell } from "../../../../assets/icons/curve_bell.svg";
import { ReactComponent as CurveFlat } from "../../../../assets/icons/curve_flat.svg";
import { Input } from "@zendeskgarden/react-forms";
import { calculateBellCurve } from "../../BulkAssumptionMathHelpers";

Chart.register(CategoryScale, dragDataPlugin, ...registerables);

export interface LeaseExpirationCurveChart {
    onValueChange?: (data: number[]) => void;
}

export default function LeaseExpirationCurveChart({
    onValueChange,
}: LeaseExpirationCurveChart): ReactElement {
    const changedValueIndex = useRef<number>(0); // tracks the currently active month index
    const yScaleMax = useRef<number>(16); // dynamic as user drags a point
    const chartInstance = useRef<Chart<"line", number[], string>>();

    const [expirationCurve, setExpirationCurve] = useState<number[]>(
        new Array(12).fill(8.3)
    );
    const [menuVisible, setMenuVisible] = useState(false);
    const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
    const menuRef = useRef<HTMLDivElement>(null);
    const chartData = useMemo(
        () => ({
            labels: MONTHS,
            datasets: [
                {
                    data: expirationCurve,
                    pointBackgroundColor: new Array(12).fill(COLORS.PRIMARY_200),
                },
            ],
        }),
        [expirationCurve]
    );

    useEffect(() => {
        function handleClickOutside(evt: MouseEvent) {
            if (menuRef.current && !menuRef.current.contains(evt.target as Node)) {
                setMenuVisible(false);
                if (chartInstance.current) {
                    const dataset = chartInstance.current.data.datasets[0];
                    if (dataset) {
                        dataset.pointBackgroundColor = new Array(12).fill(COLORS.PRIMARY_200);
                        chartInstance.current.update();
                    }
                }
            }
        }

        if (menuVisible) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [menuVisible]);

    function updateChartRender() {
        const dataset = chartInstance.current?.data.datasets[0];
        if (!dataset) {
            return;
        }

        const highestValue = Math.max(...dataset.data);
        const newMax = Math.floor(highestValue + 8);
        const clampedMax = Math.min(Math.max(newMax, 0), 100);
        yScaleMax.current = clampedMax;

        if (
            chartInstance.current &&
            chartInstance.current.options &&
            chartInstance.current.options.scales &&
            chartInstance.current.options.scales["y"]
        ) {
            chartInstance.current.options.scales["y"].max = clampedMax;
        }
        chartInstance.current?.update();
    }

    // helper to update the active point color.
    function setActivePoint(index: number) {
        const dataset = chartInstance.current?.data.datasets[0];
        if (!dataset) {
            return;
        }

        const updatedPointColors = new Array(12).fill(COLORS.PRIMARY_200);
        updatedPointColors[index] = COLORS.PRIMARY_500;
        dataset.pointBackgroundColor = updatedPointColors;
        chartInstance.current?.update();
    }

    function handleDragStart(index: number) {
        if (chartInstance.current) {
            chartInstance.current.options.animation = false;
        }
        setActivePoint(index);
        setMenuVisible(false);
    }

    function handleDrag() {
        const dataset = chartInstance.current?.data.datasets[0];
        if (!dataset) {
            return;
        }

        updateChartRender();

        if (onValueChange) {
            onValueChange([...dataset.data] as number[]);
        }
    }

    function handleDragEnd(evt: DragDataEvent, index: number) {
        changedValueIndex.current = index;
        const dataset = chartInstance.current?.data.datasets[0];
        if (!dataset) {
            return;
        }

        if (chartInstance.current) {
            delete chartInstance.current.options.animation;
        }

        setExpirationCurve([...dataset.data] as number[]);
        if (onValueChange) {
            onValueChange([...dataset.data] as number[]);
        }

        updateChartRender();

        // reapply the active point color after a slight delay.
        setTimeout(() => {
            setActivePoint(index);
        }, 150);

        // position the menu based on the dragged/clicked point
        if (chartInstance.current) {
            const element = chartInstance.current.getElementsAtEventForMode(
                evt,
                "nearest",
                { intersect: false },
                true
            );
            const pointElement = element[0]?.element;
            if (pointElement) {
                let xPosition = pointElement.x;
                let yPosition = pointElement.y;
                if (menuRef.current) {
                    if (changedValueIndex.current >= 6) {
                        xPosition -= menuRef.current.scrollWidth;
                    }
                    yPosition -= menuRef.current.scrollHeight / 2 + 10;
                }
                setMenuPosition({
                    x: xPosition,
                    y: yPosition,
                });
                setMenuVisible(true);
            }
        }
    }

    function handleChartOnClick(evt: ChartEvent, elements: ActiveElement[]) {
        const pointElement = elements[0];
        if (pointElement) {
            const index = pointElement.index;
            changedValueIndex.current = index;
            setActivePoint(index);
            setMenuVisible(true);
        } else if (
            menuVisible &&
            evt.native &&
            menuRef.current &&
            !menuRef.current.contains(evt.native.target as Node)
        ) {
            setMenuVisible(false);
            if (chartInstance.current) {
                const dataset = chartInstance.current.data.datasets[0];
                if (dataset) {
                    dataset.pointBackgroundColor = new Array(12).fill(COLORS.PRIMARY_200);
                    chartInstance.current.update();
                }
            }
        }
    }

    // evenly distributes all points (except the last adjusted) so that total equals 100
    function redistributeEvenly() {
        const dataset = chartInstance.current?.data.datasets[0];
        if (!dataset) {
            return;
        }

        const anchorValue = dataset.data[changedValueIndex.current];
        if (typeof anchorValue !== "number") {
            return;
        }

        const totalAvailableForOtherMonths = 100 - anchorValue;
        const distributionAmount = totalAvailableForOtherMonths / 11;

        const newCurveValues = [];
        for (let i = 0; i < dataset.data.length; i++) {
            const val =
                i === changedValueIndex.current ? anchorValue : distributionAmount;
            newCurveValues.push(parseFloat(val.toFixed(1)));
        }

        dataset.data = newCurveValues;
        chartInstance.current?.update();

        setExpirationCurve(newCurveValues);
        if (onValueChange) {
            onValueChange(newCurveValues);
        }
    }

    // scales all points proportionally so that their total equals 100
    function normalizeToHundred() {
        const dataset = chartInstance.current?.data.datasets[0];
        if (!dataset) {
            return;
        }

        const total = dataset.data.reduce((acc, num) => acc + num, 0);
        const ratio = 100 / total;

        dataset.data = dataset.data.map((num) => {
            if (typeof num === "number") {
                return parseFloat((num * ratio).toFixed(1));
            }
            return 0;
        });

        updateChartRender();

        setExpirationCurve([...dataset.data] as number[]);
        if (onValueChange) {
            onValueChange([...dataset.data] as number[]);
        }
    }

    function setBellCurve() {
        const dataset = chartInstance.current?.data.datasets[0];
        if (!dataset) {
            return;
        }

        const anchorValue = dataset.data[changedValueIndex.current];
        if (typeof anchorValue !== "number") {
            return;
        }

        let newValues = calculateBellCurve(anchorValue / 100, changedValueIndex.current);
        let adjustedNewValues = newValues.map(x => parseFloat((x * 100).toFixed(1)));

        dataset.data = adjustedNewValues;
        updateChartRender();

        setExpirationCurve(adjustedNewValues);

        if (onValueChange) {
            onValueChange(adjustedNewValues);
        }
    }

    function handleInputChange(
        index: number,
        evt: React.ChangeEvent<HTMLInputElement>
    ) {
        const newValue = parseFloat(evt.target.value);
        if (isNaN(newValue)) {
            return;
        }
        const newCurve = [...expirationCurve];
        newCurve[index] = newValue;
        setExpirationCurve(newCurve);

        if (chartInstance.current?.data.datasets[0]) {
            chartInstance.current.data.datasets[0].data = newCurve;
            chartInstance.current.update();
            updateChartRender(); // recalc yMax so the updated point stays visible
        }
        if (onValueChange) {
            onValueChange(newCurve);
        }
    }

    return (
        <div className={css.curveWrapper}>
            <div className={css.curveChartWrapper}>
                <Line
                    ref={chartInstance}
                    data={chartData}
                    options={{
                        elements: {
                            line: {
                                borderWidth: 3,
                                borderColor: COLORS.PRIMARY_150,
                            },
                            point: {
                                radius: 10,
                                hoverRadius: 10,
                                borderWidth: 3,
                                borderColor: "#ffffff",
                            },
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                        onClick: (evt, elements) => {
                            handleChartOnClick(evt, elements);
                        },
                        plugins: {
                            tooltip: {
                                displayColors: false,
                                callbacks: {
                                    title: () => "",
                                    label: (tooltipItem) => `${tooltipItem.raw}%`,
                                },
                            },
                            legend: {
                                display: false,
                            },
                            dragData: {
                                round: 1,
                                onDragStart: (_e, _datasetIndex, index, _value) => {
                                    handleDragStart(index);
                                },
                                onDrag: handleDrag,
                                onDragEnd: (evt, _datasetIndex, index, _value) => {
                                    handleDragEnd(evt, index);
                                },
                            },
                        },
                        scales: {
                            y: {
                                afterFit(scaleInstance) {
                                    scaleInstance.width = 50;
                                },
                                min: 0,
                                max: yScaleMax.current,
                                grid: {
                                    borderColor: COLORS.GREY_100,
                                },
                                ticks: {
                                    callback(tickValue) {
                                        return `${tickValue}%`;
                                    },
                                },
                            },
                            x: {
                                grid: {
                                    borderColor: COLORS.GREY_100,
                                },
                                ticks: {
                                    display: false,
                                },
                            },
                        },
                    }}
                />

                <div
                    ref={menuRef}
                    className={cn(css.curvePopupMenu, menuVisible && css.isVisible)}
                    style={{
                        top: menuPosition.y,
                        left: menuPosition.x,
                    }}
                >
                    <h5 className={css.title}>
                        Auto-adjust other months?
                        <span>{MONTHS_LONG[changedValueIndex.current]}</span>
                    </h5>
                    <Button className={css.curveDistButton} onClick={redistributeEvenly}>
                        Distribute Evenly <CurveFlat className={css.curveIcon} />
                    </Button>
                    <Button className={css.curveDistButton} onClick={setBellCurve}>
                        Create Bell Curve <CurveBell className={css.curveIcon} />
                    </Button>
                </div>
            </div>

            <div className={css.curveCellWrapper}>
                {MONTHS.map((month, i) => (
                    <div key={i} className={css.curveCell}>
                        <div>{month}</div>
                        <Input
                            type="number"
                            value={expirationCurve[i]}
                            onChange={evt => handleInputChange(i, evt)}
                        />
                    </div>
                ))}
            </div>

            <div className={css.footer}>
                <h5>Current total:
                    <span className={cn(css.total, (expirationCurve.sum() < 98 || expirationCurve.sum() > 102) && css.inError)}>
                        {Math.round(expirationCurve.sum())}%
                    </span>
                </h5>
                <Button onClick={normalizeToHundred}>
                    Auto-Adjust to Total 100%
                </Button>
            </div>
        </div>
    );
}
