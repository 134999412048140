import { ReactElement, useEffect, useRef, useState } from "react";
import { MONTHS } from "../../../../../../constants/Months";

import { TDriverGrowthMetric } from "../../../../../../contexts/account/data/logic/driversAndWorksheetData";
import { MonthlyAdjustmentType, MonthlyAverageBasePeriod } from "../../../../../../__generated__/generated_types";
import MonthlyAverageMenu from "../formula-menu/MonthlyAverageMenu";

import { deleteNodeKeys, TFormulaNodeProps } from "./logic/formulaNode";
import * as css from "./styles/css.module.scss";
import {IFormulaBarUpdates} from "../../../../../../contexts/account/data/useFormulaBarUpdates";


type TGrowthDriverFxNodeProps = TFormulaNodeProps&{
    driver: TDriverGrowthMetric,
}

export const MonthlyAverageBasePeriodInverseMap = {
    [MonthlyAverageBasePeriod.LastMonth]: 1,
    [MonthlyAverageBasePeriod.LastThreeMonthsActuals]: 3,
    [MonthlyAverageBasePeriod.LastSixMonthsActuals]: 6,
    [MonthlyAverageBasePeriod.LastTwelveMonthsActuals]: 12,
};


export function renderBasePeriod(
    driver: TDriverGrowthMetric,
    asJSX: undefined | {
        locked: boolean,
        fbUpdates: IFormulaBarUpdates
    }
): string | JSX.Element {
    let basePeriodNodeText = '';
    if (driver.monthlyAverageBasePeriod === MonthlyAverageBasePeriod.CustomLookbackPeriod) {
        if (driver.lookbackPeriodStart && driver.lookbackPeriodEnd) {
            const startMonth = driver.lookbackPeriodStart.split("-")[1];
            const endMonth = driver.lookbackPeriodEnd.split("-")[1];
            const startYear = driver.lookbackPeriodStart.split("-")[0];
            const endYear = driver.lookbackPeriodEnd.split("-")[0];
            if (startMonth && endMonth && startYear && endYear) {
                const periodStart = `${MONTHS[parseInt(startMonth) - 1]} ${parseInt(startYear)}`;
                const periodEnd = `${MONTHS[parseInt(endMonth) - 1]} ${parseInt(endYear)}`;
                basePeriodNodeText = `${periodStart} to ${periodEnd}`;
            }
        }
    } else {
        if (driver.monthlyAverageBasePeriod) {
            basePeriodNodeText = `last ${MonthlyAverageBasePeriodInverseMap[driver.monthlyAverageBasePeriod]} months of actuals`;
        }
    }

    if (!asJSX) {
        return basePeriodNodeText;
    }
    else if (asJSX.locked) {
        return <>&nbsp;{basePeriodNodeText}</>;
    }
    return (
        <MonthlyAverageMenu
            fbUpdates={asJSX.fbUpdates}
            level={0}
            triggerNode={<a>{basePeriodNodeText}</a>}
        />
    );
}

export function renderMonthlyAdjustment(
    driver: TDriverGrowthMetric,
    asJSX: undefined | {
        locked: boolean,
        fbUpdates: IFormulaBarUpdates
    }
): string | JSX.Element {
    let monthlyAdjustmentNodeText = '';
    // '!=' on purpose to check for null and undefined
    if (driver.monthlyAdjustmentType === MonthlyAdjustmentType.Dollar && driver.monthlyAdjustmentValue != null) {
        monthlyAdjustmentNodeText = `$${driver.monthlyAdjustmentValue} monthly adjustment`;
    }
    if (driver.monthlyAdjustmentType === MonthlyAdjustmentType.Percent && driver.monthlyAdjustmentValue != null) {
        monthlyAdjustmentNodeText = `${driver.monthlyAdjustmentValue}% monthly adjustment`;
    }

    if (!asJSX) {
        return monthlyAdjustmentNodeText;
    }
    else if (asJSX.locked) {
        return <>&nbsp;{monthlyAdjustmentNodeText}</>;
    }
    return (
        <MonthlyAverageMenu
            fbUpdates={asJSX.fbUpdates}
            level={1}
            triggerNode={<a>{monthlyAdjustmentNodeText}</a>}
        />
    );
}

export default function(props: TGrowthDriverFxNodeProps): ReactElement {
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [childNodeHovered, setChildNodeHovered] = useState<boolean>(false);

    const wrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (isFocused && wrapperRef.current) {
            wrapperRef.current.focus();
        }
    }, [isFocused]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                wrapperRef.current &&
                (wrapperRef.current === event.target ||
                wrapperRef.current.contains(event.target as Node))
            ) {
                return;
            }
            setIsFocused(false);
        };

        // Clicking outside the current node should remove focus
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleClick = () => {
        setIsFocused(!isFocused);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (
            isFocused && deleteNodeKeys.includes(e.key) && !childNodeHovered
        ) {
            props.fbUpdates.removeMonthlyAverageGrowthDriver();
        }
    };

    const nodeClassName = props.locked ? css.fxNodeLocked : css.fxNode;
    const className = `${nodeClassName} ${props.className ? props.className : ''} ${css.deletableNode} ${isFocused ? css.focusedNode : ''} ${childNodeHovered ? css.disableHover : ''}`;

    return (
        <div
            className={className}
            onClick={handleClick}
            onKeyDown={handleKeyDown}
            onMouseEnter={() => setChildNodeHovered(false)}
            ref={wrapperRef}
            tabIndex={0}
        >
            (Monthly Average:
            <div
                className={css.fxNode}
                onMouseEnter={() => setChildNodeHovered(true)}
                onMouseLeave={() => setChildNodeHovered(false)}
            >
                {renderBasePeriod(props.driver, {...props})}
            </div>
            &nbsp;with&nbsp;
            <div
                className={css.fxNode}
                onMouseEnter={() => setChildNodeHovered(true)}
                onMouseLeave={() => setChildNodeHovered(false)}
            >
                {renderMonthlyAdjustment(props.driver, {...props})}
            </div>)
        </div>
    );
}
