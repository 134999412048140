import { useChartOfAccounts } from "../../../contexts/chartofaccounts/ChartOfAccountsContext";
import { useMemo } from "react";
import {
    buildDummyData,
    IDriversAndWorksheetData,
    ISaveData
} from "../../../contexts/account/data/useDriversAndWorksheetData";
import { VersionType } from "../../../__generated__/generated_types";
import { FORMULA_BAR_DUMMY_ACCOUNT_ID } from "../../workflows/account/formula-bar/FormulaBar";
import { TDriverMetrics, TDriverMetricsYear } from "../../../contexts/account/data/logic/driversAndWorksheetData";
import { v4 as uuid } from "uuid";

export interface IFormulaBarAccountTableMapper {
    updateFormulaBarData: (oldData: IDriversAndWorksheetData, data: ISaveData) => IDriversAndWorksheetData;
}

export interface IFormulaBarAccountTableMapperProps {
    propertyId: string;
    year: number;
    versionType: VersionType.Reforecast | VersionType.Budget;
}

export function useFormulaBarAccountTableMapper(props: IFormulaBarAccountTableMapperProps): IFormulaBarAccountTableMapper {
    const {accounts} = useChartOfAccounts();
    const accountMap = useMemo(() => {
        return accounts.toIdMap(a => a.id);
    }, [accounts]);


    function mapFormulaBarToAccountTable(oldData: IDriversAndWorksheetData, data: ISaveData): IDriversAndWorksheetData {
        const dummyData = buildDummyData(props.propertyId, props.year, props.versionType, FORMULA_BAR_DUMMY_ACCOUNT_ID);
        const oldDrivers = oldData.parsedData?.drivers as TDriverMetricsYear;
        const parsedData = dummyData.parsedData as TDriverMetrics;
        parsedData.isDriven = true;

        // Revenue
        // This isn't done because it won't matter for multiple accounts. A revenue driver is 1-to-1 given
        // the revenue model. As such, we don't multiple assignments, and we can't use them in Bulk Add Drivers.

        // Map Account Percentage
        const accountIdsToRemove = new Set(data.pendingUpdates.remove.account.map(a => a.accountId));
        const accountUpdates = data.pendingUpdates.update.account.toIdMap(a => a.accountId);
        parsedData.drivers.account = oldDrivers.account.filter(a => !accountIdsToRemove.has(a.accountId)).map(a => {
            const update = accountUpdates[a.accountId];
            if(update) {
                a.lookbackPeriod = update.lookbackPeriod;
            }
            return a;
        });
        parsedData.drivers.account = parsedData.drivers.account.concat(
            data.pendingUpdates.add.account.map(a => {
                const account = accountMap[a.accountId];
                const id = uuid();
                return {
                    accountId: a.accountId,
                    lookbackPeriod: a.lookbackPeriod,
                    assumptionId: id,
                    glName: account?.name ?? "UNKNOWN",
                    glNumber: account?.accountNumber ?? "UNKNOWN",
                    locked: false,
                    values: new Array(12).fill(null),
                    percentage: new Array(12).fill(null),
                    acctPercentageDriverAccountId: id
                };
            })
        );

        // Map Operational
        const operationalKey = (data: {type: string, sourceMetricId: string}) => `${data.type}^${data.sourceMetricId}`;
        const operationalToRemove = new Set(data.pendingUpdates.remove.operational.map(o => operationalKey(o)));
        const operationalUpdates = data.pendingUpdates.update.operational.toIdMap(o => operationalKey(o));
        parsedData.drivers.operational = oldDrivers.operational.filter(o => !operationalToRemove.has(operationalKey(o))).map(o => {
            const update = operationalUpdates[operationalKey(o)];
            if(update) {
                o.lookbackPeriod = update.lookbackPeriod;
            }
            return o;
        });
        parsedData.drivers.operational = parsedData.drivers.operational.concat(
            data.pendingUpdates.add.operational.map(o => {
                return {
                    type: o.type,
                    sourceMetricId: o.sourceMetricId,
                    driverMetricTypeId: uuid(),
                    lookbackPeriod: o.lookbackPeriod,
                    locked: false,
                    amount: undefined,
                    values: new Array(12).fill(null),
                    percentage: new Array(12).fill(null)
                };
            })
        );

        // Line Items
        const lineItemsToRemove = new Set(data.pendingUpdates.remove.worksheet.map(w => w.description));
        parsedData.drivers.worksheet = oldDrivers.worksheet.filter(w => !lineItemsToRemove.has(w.description));
        parsedData.drivers.worksheet = parsedData.drivers.worksheet.concat(
            data.pendingUpdates.add.worksheet.map(w => {
                return {
                    description: w.description,
                    values: new Array(12).fill(null)
                };
            })
        );

        // Custom Driver
        const customToRemove = new Set(data.pendingUpdates.remove.customItems ?? []);
        parsedData.drivers.customDriver = oldDrivers.customDriver.filter(c => !customToRemove.has(c.itemName));
        parsedData.drivers.customDriver = parsedData.drivers.customDriver.concat(
            (data.pendingUpdates.add.customItems ?? []).map(c => {
                return {
                    itemName: c,
                    amount: new Array(12).fill(null),
                    percentage: new Array(12).fill(null),
                    id: uuid(),
                    count: new Array(12).fill(null)
                };
            })
        );

        // Payroll
        const payrollToRemove = data.pendingUpdates.remove.payroll.toIdMap(p => p.itemType);
        const payrollToUpdate = data.pendingUpdates.update.payroll.toIdMap(p => p.itemType);
        parsedData.drivers.payroll = oldDrivers.payroll.map(p => {
            // Updates and Removals can't happen at the same time, so this is safe.
            const removals = payrollToRemove[p.itemType];
            const updates = payrollToUpdate[p.itemType];
            if(removals) {
                const removePosIds = new Set(removals.positions.map(pos => pos.id));
                p.positions = p.positions.filter(pos => !removePosIds.has(pos.id));
            }
            if(updates) {
                p.positions = updates.positions;
            }
            return p;
        }).filter(p => p.positions.length !== 0);
        parsedData.drivers.payroll = parsedData.drivers.payroll.concat(
            data.pendingUpdates.add.payroll.map(p => ({
                itemType: p.itemType,
                positions: p.positions
            }))
        );

        // BEGIN: Growth Drivers
        // Multiple types exist and they must be handled independently.
        // Since growth drivers are exclusive, we're doing special adjustments here.

        // Annual Target Value
        if(data.pendingUpdates.remove.annualTargetValue.length > 0) {
            parsedData.drivers.annualTargetValue = [];
        } else if(data.pendingUpdates.update.annualTargetValue.length > 0) {
            parsedData.drivers.annualTargetValue = data.pendingUpdates.update.annualTargetValue.map(a => {
                return {
                    ...a,
                    id: uuid()
                };
            });
        } else if(data.pendingUpdates.add.annualTargetValue.length > 0){
            parsedData.drivers.annualTargetValue = data.pendingUpdates.add.annualTargetValue.map(a => {
                return {
                    ...a,
                    id: uuid()
                };
            });
        }

        // Monthly Average
        if(data.pendingUpdates.remove.monthlyAverage.length > 0) {
            parsedData.drivers.monthlyAverage = [];
        } else if(data.pendingUpdates.update.monthlyAverage.length > 0) {
            parsedData.drivers.monthlyAverage = data.pendingUpdates.update.monthlyAverage.map(m => {
                return {
                    ...m,
                    id: uuid()
                };
            });
        } else if(data.pendingUpdates.add.monthlyAverage.length > 0) {
            parsedData.drivers.monthlyAverage = data.pendingUpdates.add.monthlyAverage.map(m => {
                return {
                    ...m,
                    id: uuid()
                };
            });
        }

        // Percent Growth
        if(data.pendingUpdates.remove.percentGrowth.length > 0) {
            parsedData.drivers.percentGrowth = [];
        } else if(data.pendingUpdates.update.percentGrowth.length > 0) {
            parsedData.drivers.percentGrowth = data.pendingUpdates.update.percentGrowth.map(m => {
                return {
                    ...m,
                    id: uuid()
                };
            });
        } else if(data.pendingUpdates.add.percentGrowth.length > 0) {
            parsedData.drivers.percentGrowth = data.pendingUpdates.add.percentGrowth.map(m => {
                return {
                    ...m,
                    id: uuid()
                };
            });
        }
        // END: Growth Drivers

        return dummyData;
    }

    return {
        updateFormulaBarData: mapFormulaBarToAccountTable
    };
}