import { useEffect, useState } from "react";
import { useConfig } from "../../../../../../hooks/useConfig";
import { useGetWorksheetLinesForWorksheetLinesReportLazyQuery, VersionType } from "../../../../../../__generated__/generated_types";
import { buildWorksheetLines } from "./logic/buildWorksheetLines";
import { WorksheetLinesReportLine } from "./types";

export type UseLineItemsDataResult = {
    worksheetLines: WorksheetLinesReportLine[];
    loadForProperties: (propertyIds: string[]) => void;
    reforecastYear: number;
    isReady: boolean;
}

export function useLineItemsData(): UseLineItemsDataResult {
    const config = useConfig();
    const [loadData, { data, loading }] = useGetWorksheetLinesForWorksheetLinesReportLazyQuery({
        fetchPolicy: "no-cache"
    });

    const [worksheetLines, setWorksheetLines] = useState<WorksheetLinesReportLine[]>([]);

    const loadForProperties = (propertyIds: string[]) => {
        if (!config.isReady) {
            return;
        }
        loadData({
            variables: {
                propertyIds: propertyIds,
                yearsVersionTypes: [
                    {
                        year: config.year,
                        versionType: VersionType.Reforecast
                    },
                    {
                        year: config.year + 1,
                        versionType: VersionType.Budget
                    }
                ]
            }
        });
    };

    useEffect(
        () => {
            if (data
                && !loading
                && config.properties.properties
                && config.chartOfAccountsConfig.chartOfAccountsFlat
            ) {
                const worksheetLines = buildWorksheetLines(
                    data,
                    config.properties.properties,
                    config.chartOfAccountsConfig.chartOfAccountsFlat
                );

                setWorksheetLines(worksheetLines);
            }

        },
        [data, loading, config.isReady]
    );


    return {
        worksheetLines: worksheetLines,
        loadForProperties: loadForProperties,
        reforecastYear: config.year,
        isReady: config.isReady
    }
}