import { ReactElement, useEffect, useRef, useState } from "react";
import { TDriverGrowthMetric } from "../../../../../../contexts/account/data/logic/driversAndWorksheetData";

import * as css from "./styles/css.module.scss";
import { deleteNodeKeys, TFormulaNodeProps } from "./logic/formulaNode";
import { DistributionMethod, PercentGrowthBasePeriod, VersionType } from "../../../../../../__generated__/generated_types";
import PercentGrowthMenu from "../formula-menu/PercentGrowthMenu";
import { distributionMethodMap } from "../../../../../../contexts/account/data/utils";
import { useProperties } from "../../../../../../contexts/properties/PropertiesContext";
import { getBaseToGrowOptions } from "../../logic/utils";
import {IFormulaBarUpdates} from "../../../../../../contexts/account/data/useFormulaBarUpdates";


type TGrowthDriverFxNodeProps = TFormulaNodeProps&{
    drivers: TDriverGrowthMetric[],
    versionType: VersionType.Budget | VersionType.Reforecast,
}

export function analyzePercentGrowthBasePeriod(
    driver: TDriverGrowthMetric | undefined,
    versionType: VersionType.Budget | VersionType.Reforecast,
    reforecastYear: number
): {
    percentGrowthBasePeriod: string,
    showDistributionNode: boolean
} {
    let percentGrowthBasePeriod: string = driver?.percentGrowthBasePeriod || PercentGrowthBasePeriod.SameMonthLastYear || PercentGrowthBasePeriod.BudgetSameMonthCurrentYear;
    percentGrowthBasePeriod = getBaseToGrowOptions(versionType, reforecastYear).find((e) => e.value === percentGrowthBasePeriod)?.label || '';

    // TODO probably need to refactor this to have a more direct comparison of selected driver to corresponding enum value
    const showDistributionNode = (percentGrowthBasePeriod.toLowerCase() !== PercentGrowthBasePeriod.SameMonthLastYear.replaceAll("_", " ").toLowerCase()
                                 && percentGrowthBasePeriod !== `Same month ${reforecastYear} budget amount`);

    return {percentGrowthBasePeriod, showDistributionNode};
}

export function renderBasePeriod(
    percentGrowthBasePeriod: string,
    asJSX: undefined | {
        versionType: VersionType.Budget | VersionType.Reforecast,
        fbUpdates: IFormulaBarUpdates,
        locked: boolean
    }
): string | JSX.Element {
    if (!asJSX) {
        return percentGrowthBasePeriod;
    }
    else if (asJSX.locked) {
        return <>&nbsp;{percentGrowthBasePeriod}</>;
    }
    const basePeriodNode = <a style={{marginLeft: "6px"}}>{percentGrowthBasePeriod}</a>;
    return (
        <PercentGrowthMenu
            fbUpdates={asJSX.fbUpdates}
            level={0}
            triggerNode={basePeriodNode}
            versionType={asJSX.versionType}
        />
    );
}

export function renderAdjustmentValueNode(
    driver: TDriverGrowthMetric | undefined,
    asJSX: undefined | {
        versionType: VersionType.Budget | VersionType.Reforecast,
        fbUpdates: IFormulaBarUpdates,
        locked: boolean
    }
): string | JSX.Element {
    const percentGrowthAdjustmentValue = driver?.percentGrowthAdjustmentValue || 0;
    if (!asJSX) {
        return `${percentGrowthAdjustmentValue}% growth`;
    }
    else if (asJSX.locked) {
        return <>&nbsp;{percentGrowthAdjustmentValue}% growth</>;
    }
    const adjustmentValueNode = <a style={{marginLeft: "6px"}}>{percentGrowthAdjustmentValue}% growth</a>;
    return (
        <PercentGrowthMenu
            fbUpdates={asJSX.fbUpdates}
            level={1}
            triggerNode={adjustmentValueNode}
            versionType={asJSX.versionType}
        />
    );
}

export function renderDistributionMethodNode(
    driver: TDriverGrowthMetric | undefined,
    asJSX: undefined | {
        versionType: VersionType.Budget | VersionType.Reforecast,
        fbUpdates: IFormulaBarUpdates,
        locked: boolean
    }
): string | JSX.Element {
    const distributionMethod = driver?.distributionMethod || DistributionMethod.Flat;
    if (!asJSX) {
        return distributionMethodMap[distributionMethod];
    }
    else if (asJSX.locked) {
        return <>&nbsp;{distributionMethodMap[distributionMethod]}</>;
    }
    const distributionMethodNode = <a style={{marginLeft: "6px"}}>{distributionMethodMap[distributionMethod]}</a>;
    return (
        <PercentGrowthMenu
            fbUpdates={asJSX.fbUpdates}
            level={2}
            triggerNode={distributionMethodNode}
            versionType={asJSX.versionType}
        />
    );
}

export default function PercentGrowthDriverFxNode(props: TGrowthDriverFxNodeProps): ReactElement {
    const { currentProperty } = useProperties();

    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [childNodeHovered, setChildNodeHovered] = useState<boolean>(false);

    const wrapperRef = useRef<HTMLDivElement>(null);


    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                wrapperRef.current &&
                (wrapperRef.current === event.target ||
                wrapperRef.current.contains(event.target as Node))
            ) {
                return;
            }
            setIsFocused(false);
        };

        // Clicking outside the current node should remove focus
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleClick = () => {
        setIsFocused(!isFocused);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (
            isFocused && deleteNodeKeys.includes(e.key)
        ) {
            props.fbUpdates.removePercentGrowthDriver();
        }
    };

    if (!currentProperty) {
        return <></>;
    }

    const nodeClassName = props.locked ? css.fxNodeLocked : css.fxNode;
    const className = `${nodeClassName} ${props.className ? props.className : ''} ${css.deletableNode} ${isFocused ? css.focusedNode : ''} ${childNodeHovered ? css.disableHover : ''}`;


    const {percentGrowthBasePeriod, showDistributionNode} = analyzePercentGrowthBasePeriod(
        props.drivers[0],
        props.versionType,
        currentProperty.reforecastYear
    );

    return (
        <div
            className={className}
            onClick={handleClick}
            onKeyDown={handleKeyDown}
            onMouseEnter={() => setChildNodeHovered(false)}
            ref={wrapperRef}
            tabIndex={0}
        >
            (% Growth:
            <div
                className={css.fxNode}
                onMouseEnter={() => setChildNodeHovered(true)}
                onMouseLeave={() => setChildNodeHovered(false)}
            >
                {renderBasePeriod(percentGrowthBasePeriod, {...props})}
            </div>
            &nbsp;+
            <div
                className={css.fxNode}
                onMouseEnter={() => setChildNodeHovered(true)}
                onMouseLeave={() => setChildNodeHovered(false)}
            >
                {renderAdjustmentValueNode(props.drivers[0], {...props})}
            </div>
            {showDistributionNode && (
                <>
                    &nbsp;with
                    <div
                        className={css.fxNode}
                        onMouseEnter={() => setChildNodeHovered(true)}
                        onMouseLeave={() => setChildNodeHovered(false)}
                    >
                        {renderDistributionMethodNode(props.drivers[0], {...props})}
                    </div>
                </>
            )}
            )
        </div>
    );
}
