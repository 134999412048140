import {useEffect, useState} from "react";
import {
    GetPayRolPositionsQuery,
    useGetPayrollCompensationItemsQuery,
    useGetPayRolPositionsQuery
} from "../../../../__generated__/generated_types";
import {buildCompItemsDisplayData} from "./logic";

export type TPositionsAndCompItemsDataProvider = ReturnType<typeof usePositionsAndCompItemsDataProvider>;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function usePositionsAndCompItemsDataProvider() {
    const {data: compItemsData, loading: compItemsLoading} = useGetPayrollCompensationItemsQuery({fetchPolicy: "no-cache"});
    const {data: positionsData, loading: positionsLoading} = useGetPayRolPositionsQuery({fetchPolicy: "no-cache"});
    const [positions, setPositions] = useState<GetPayRolPositionsQuery["listPayrollPositions"]>();
    const [compItemIds, setCompItemIds] = useState<string[]>();
    const [compItemNames, setCompItemNames] = useState<Record<string, string>>();

    useEffect(() => {
        if (!compItemsData || compItemsLoading) {
            return;
        }
        const {compItemIds, compItemNames} = buildCompItemsDisplayData(compItemsData);
        setCompItemIds(compItemIds);
        setCompItemNames(compItemNames);
    }, [compItemsData, compItemsLoading]);

    useEffect(() => {
        if (!positionsData || positionsLoading) {
            return;
        }
        const positions = positionsData.listPayrollPositions.sortBy("name");
        if (positions.length === 0) {
            positions.push({
                id: "No Positions",
                name: "No Payroll Positions"
            });
        }
        setPositions(positions);
    }, [positionsData, positionsLoading]);

    return {positions, compItemIds, compItemNames};
}