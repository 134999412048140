import HotTable from "@handsontable/react";
import {ReactElement, useEffect, useMemo, useRef, useState} from "react";
import {LICENSES} from "../../../../../constants/Licenses";
import Handsontable from "handsontable";
import {Property} from "../../../../../contexts/properties/PropertiesContext";
import "native-injects";
import {GetOriginalRevenueMarketRentDataQuery, OriginalRevenueMarketRentAdjustmentType, UnitTypeWithOriginalrevenueMarketRentOverridesGranularInput, UpdateOriginalRevenueMarketRentDataMutationVariables, useGetOriginalRevenueMarketRentDataLazyQuery, useUpdateOriginalRevenueMarketRentDataMutation, VersionType} from "../../../../../__generated__/generated_types";
import {toast} from "react-toastify";
import {NUMERIC_TYPE, TEXT_TYPE} from "handsontable/cellTypes";
import * as css from "./AverageRentGrowth.module.scss";
import {GridSettings} from "handsontable/settings";
import {parseCurrency, parsePercent, parseNumber} from "../../../../../utils/string-helpers";
import {formatterDecimalUnlimitedFractionDigits} from "../../../../../utils/formatters";
import {Body, Close, Footer, FooterItem, Header, Modal} from "@zendeskgarden/react-modals";
import {Button} from "@zendeskgarden/react-buttons";

export interface IAverageRentGrowthProps {
    property: Property,
    versionType: VersionType.Reforecast | VersionType.Budget,
}

type PendingUpdates = {
    adjustments: (number | null | undefined)[];
    adjustmentTypes: (OriginalRevenueMarketRentAdjustmentType | null | undefined)[];
    overrides: Map<string, (number | null | undefined)[]>;
};

type OverridesByUnitTypeId = Record<string/*unitTypeId*/, (number | null)[]>;

type OverrideUpdate = {unitTypeId: string, monthIndex: number, val: number | null;};

type HOTData = {
    adjustments: (number | null)[],
    adjustmentTypes: ("dollar" | "percent" | null)[],
    rowToUnitTypeIdMap: Record<number, string>,
    overridesByUnitTypeId: OverridesByUnitTypeId,
    tableData: Handsontable.RowObject[];
};

type TypedVal = {
    type: "dollar" | "percent" | "number";
    val: number;
}

function parseVal(val: string): TypedVal | null {
    let result: TypedVal | null = null;
    let parsedNum = null;
    if ((parsedNum = parseCurrency(val)) !== null) {
        result = {
            type: "dollar",
            val: parsedNum
        };
    }
    else if ((parsedNum = parsePercent(val)) !== null) {
        result = {
            type: "percent",
            val: parsedNum
        };
    }
    else if ((parsedNum = parseNumber(val)) !== null) {
        result = {
            type: "number",
            val: parsedNum
        };
    }
    return result;
}

function buildHOTSettings(hotData: HOTData, versionType: VersionType, property: Property): Handsontable.GridSettings {
    let forecastStartIndex = 0;
    if (versionType == VersionType.Reforecast) {
        forecastStartIndex = property.reforecastStartMonthIndex;
    }
    return {
        data: hotData.tableData,
        colWidths: [200, 86.4, 86.4, 86.4, 86.4, 86.4, 86.4, 86.4, 86.4, 86.4, 86.4, 86.4, 86.4, 90],
        className: "htCenter htMiddle",
        rowHeights: 50,
        colHeaders: ["Unit type", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", "Average"],
        manualRowResize: false,
        manualColumnResize: false,
        stretchH: "none",
        autoColumnSize: false,
        autoRowSize: false,
        height: 500,
        width: 1327,
        disableVisualSelection: ["header"],
        cells(this, row, col) {
            const classNames = [];
            if (col > 0) {
                classNames.push("htRight", "htMiddle");
            }
            if (col - 1 >= forecastStartIndex && col - 1 < 12) {
                classNames.push(css.editableCell);
            }
            // adjustments row
            if (row == 0) {
                if (col - 1 >= forecastStartIndex && col - 1 < 12) {
                    this.type = TEXT_TYPE;
                    this.allowInvalid = false,
                    this.validator = function (value, callback) {
                        if (value === null) {
                            callback(true);
                        }
                        else if (typeof value !== "string") {
                            callback(false);
                        }
                        else if (value.trim().length == 0) {
                            callback(true);
                        }
                        else {
                            const parsed = parseVal(value);
                            if (parsed !== null) {
                                callback(true);
                            } else {
                                callback(false);
                            }
                        }
                    };
                }
                classNames.push(css.growthBlockBottom, css.uselessAdjustmentValue);
            }
            // unit type market rent rows
            else if (row > 0) {
                if (col - 1 >= forecastStartIndex && col - 1 < 12) {
                    this.type = NUMERIC_TYPE;
                    this.numericFormat = {
                        pattern: "0,0",
                        culture: "en-US"
                    };
                    this.strict = true;
                    this.allowInvalid = false;
                }
                if (col - 1 >= forecastStartIndex && col - 1 < 12 && hotData.overridesByUnitTypeId[hotData.rowToUnitTypeIdMap[row] ?? ""]?.[col - 1] != null) {
                    classNames.push(css.overridenValue);
                }
            }
            if (col - 1 < forecastStartIndex || col - 1 >= 12) {
                this.readOnly = true;
            }
            else {
                this.readOnlyCellClassName = css.editableCellReadonly;
            }
            if (col - 1 == forecastStartIndex) {
                classNames.push(css.forecastedBlockLeft);
            }
            if (classNames.length > 0) {
                if (this.className) {
                    classNames.push(this.className);
                }
                this.className = classNames.join(" ");
            }
            return this;
        }
    };
}

function buildHOTData(
    rawData: GetOriginalRevenueMarketRentDataQuery,
    versionType: VersionType,
    property: Property,
    // pendingUpdates are applied on top of data received through network
    // this is required for short period of time when user made changes while previous changes were going through UI -> backend -> UI roundtrip
    // this way I avoid flickering data in the table when update from server clashes with the update by user just made
    pendingUpdates: PendingUpdates | undefined
): HOTData {
    const data: HOTData = {
        adjustments: [],
        adjustmentTypes: new Array(12).fill(null),
        rowToUnitTypeIdMap: {},
        overridesByUnitTypeId: {},
        tableData: []
    };
    let forecastStartIndex = 0;
    if (versionType == VersionType.Reforecast) {
        forecastStartIndex = property.reforecastStartMonthIndex;
    }
    data.adjustments = [...rawData.originalRevenueMarketRentAdjustments.adjustments];
    const typedAdjustments: (TypedVal | null)[] = new Array(12).fill(null);
    for (let i = forecastStartIndex; i < 12; i++) {
        const val = rawData.originalRevenueMarketRentAdjustments.adjustments[i];
        const type = rawData.originalRevenueMarketRentAdjustments.adjustmentTypes[i];
        const parsedAdjustmentType = type === OriginalRevenueMarketRentAdjustmentType.Dollar ? "dollar" : "percent";
        const parsedVal = val !== undefined && val !== null ? val : 0;
        typedAdjustments[i] = {
            type: parsedAdjustmentType,
            val: parsedVal
        };
        data.adjustmentTypes[i] = parsedAdjustmentType;
    }

    data.tableData.push(["Rent Growth",
                        ...Array(forecastStartIndex)
                            .fill(null)
                            .concat(typedAdjustments
                                .slice(forecastStartIndex, 12)
                                .map(val => val === null ? "" :
                                            val.type === "percent" ? `${formatterDecimalUnlimitedFractionDigits.format(val.val)}%`
                                            : `$${formatterDecimalUnlimitedFractionDigits.format(val.val)}`) // we always default to %
                            ),
                        ""]);
    let rowNum = 1; // row 0 is filled above
    for (const row of rawData.originalRevenueMarketRentValues.sortBy("unitTypeName")) {
        let forecast = row.budget;
        if (versionType == VersionType.Reforecast) {
            forecast = row.reforecast;
        }
        let values = row.actuals.slice(0, forecastStartIndex).concat(forecast.slice(forecastStartIndex, 12));
        let average: number | null = values.average();
        values = values.map(v => v === null ? v : +v.toFixed(0));
        if (pendingUpdates) {
            const pendingOverrides = pendingUpdates.overrides.get(row.unitTypeId);
            if (pendingOverrides) {
                for (let i = forecastStartIndex; i < 12; i++) {
                    const overridenValue = pendingOverrides[i];
                    if (overridenValue !== undefined && overridenValue !== null) {
                        values[i] = overridenValue;
                    }
                }
            }
        }
        average = Number.isNaN(average) ? null : +average.toFixed(0);
        data.tableData.push([row.unitTypeName,
                            ...values,
                            average]);
        data.rowToUnitTypeIdMap[rowNum] = row.unitTypeId;
        rowNum++;
    }
    data.overridesByUnitTypeId = rawData.originalRevenueMarketRentOverrides.toIdMap("unitTypeId", "overrides");

    if (pendingUpdates) {
        const dataRowAdjustmentTypes = data.tableData[1];
        const dataRowAdjustments = data.tableData[0];
        for (let i = forecastStartIndex; i < 12; i++) {
            if (pendingUpdates.adjustmentTypes[i] !== undefined && dataRowAdjustmentTypes) {
                dataRowAdjustmentTypes[i+1] = pendingUpdates.adjustmentTypes[i];
            }
            const adjustmentValue = pendingUpdates.adjustments[i];
            if (adjustmentValue !== undefined) {
                data.adjustments[i] = adjustmentValue;
                if (dataRowAdjustments) {
                    dataRowAdjustments[i+1] = adjustmentValue;
                }
            }
        }
    }
    return data;
}

function isGrowthInSelection(hotRef: React.RefObject<HotTable>,
    reforecastStartMonthIndex: number,
    versionType: VersionType): boolean {

    const hot = hotRef.current?.hotInstance;
    if (!hot) {
        return false;
    }
    const selectedRange = hot.getSelectedRange()?.flatMap(range => range.getAll()) ?? [];
    let result = false;
    let forecastStartIndex = reforecastStartMonthIndex;
    if (versionType == VersionType.Budget) {
        forecastStartIndex = 0;
    }
    for (const {row, col} of selectedRange) {
        if (row > 0 || col - 1 < forecastStartIndex || col - 1 >= 12) {
            continue;
        }
        result = true;
        break;
    }
    return result;
}

function buildClearOverridesUpdates(hotRef: React.RefObject<HotTable>,
    rowToUnitTypeIdMap: Record<number, string>,
    overridesByUnitTypeId: OverridesByUnitTypeId,
    reforecastStartMonthIndex: number,
    versionType: VersionType): OverrideUpdate[] {
    const hot = hotRef.current?.hotInstance;
    if (!hot) {
        return [];
    }

    const selectedRange = hot.getSelectedRange()?.flatMap(range => range.getAll()) ?? [];
    const ret: OverrideUpdate[] = [];
    let forecastStartIndex = reforecastStartMonthIndex;
    if (versionType == VersionType.Budget) {
        forecastStartIndex = 0;
    }
    for (const {row, col} of selectedRange) {
        // overrides can be found in this range only
        if (row < 1 || col - 1 < forecastStartIndex || col - 1 >= 12) {
            continue;
        }
        const unitTypeId = rowToUnitTypeIdMap[row];
        if (!unitTypeId) {
            continue;
        }
        const overrideVal = overridesByUnitTypeId[unitTypeId]?.[col - 1];
        if (overrideVal !== null && overrideVal !== undefined) {
            ret.push({
                unitTypeId: unitTypeId,
                monthIndex: col - 1,
                val: null // this removes override
            });
        }
    }

    return ret;
}

const EMPTY_UPDATES = (): PendingUpdates => ({
    adjustments: new Array(12).fill(undefined),
    adjustmentTypes: new Array(12).fill(undefined),
    overrides: new Map()
});


export function AverageRentGrowth(props: IAverageRentGrowthProps): ReactElement {
    const [refetch, {data: marketRentsData, loading: marketRentsLoading}] = useGetOriginalRevenueMarketRentDataLazyQuery({
        fetchPolicy: "no-cache"
    });
    const [saveChanges] = useUpdateOriginalRevenueMarketRentDataMutation({
        notifyOnNetworkStatusChange: true,
        onCompleted: data => {
            if (data?.setOriginalRevenueMarketRentAdjustmentsGranular === false || data?.setOriginalRevenueMarketRentOverridesGranular === false) {
                toast.error(`Failed To Update`);
            }
            refetch({
                variables: {
                    propertyId: props.property.id,
                    budgetYear: props.property.budgetYear,
                    versionType: props.versionType
                }
            });
        },
    });
    const [tableSettings, setTableSettings] = useState<Handsontable.GridSettings>();
    const [pendingUpdates, setPendingUpdates] = useState<PendingUpdates>();
    const [rowToUnitTypeIdMap, setRowToUnitTypeIdMap] = useState<HOTData["rowToUnitTypeIdMap"]>({});
    const [overridesByUnitTypeId, setOverridesByUnitTypeId] = useState<HOTData["overridesByUnitTypeId"]>({});
    const [loadedAdjustmentTypes, setLoadedAdjustmentTypes] = useState<("dollar" | "percent" | null)[]>(new Array(12).fill(null));
    const [showPasteBlockedAlert, setShowPasteBlockedAlert] = useState(false);
    const hotRef = useRef<HotTable>(null);

    useEffect(() => {
        refetch({
            variables: {
                propertyId: props.property.id,
                budgetYear: props.property.budgetYear,
                versionType: props.versionType
            }
        });
    }, [props.property.id, props.versionType]);

    useEffect(() => {
        if (!marketRentsData || marketRentsLoading) {
            return;
        }
        const hotData = buildHOTData(marketRentsData, props.versionType, props.property, pendingUpdates);
        const settings = buildHOTSettings(
            hotData,
            props.versionType,
            props.property
        );
        setRowToUnitTypeIdMap(hotData.rowToUnitTypeIdMap);
        setOverridesByUnitTypeId(hotData.overridesByUnitTypeId);
        setLoadedAdjustmentTypes(hotData.adjustmentTypes);
        setTableSettings(settings);
    }, [marketRentsData, marketRentsLoading]);

    useEffect(() => {
        const debounce: ReturnType<typeof setTimeout> = setTimeout(() => {
            handleSaveChanges();
        }, 750);

        return () => {
            clearTimeout(debounce);
        };
    }, [pendingUpdates]);

    const contextMenu = useMemo(() => {
        const ret: GridSettings["contextMenu"] = {
            items: {
                "copy": {
                    name: 'Copy',
                    key: 'copy',
                },
                "paste": {
                    name: 'Paste',
                    key: 'paste',
                    callback() {
                        navigator.clipboard
                            .readText()
                            .then(
                                (value: string) => {
                                    const copyPastePlugin = hotRef.current?.hotInstance?.getPlugin("copyPaste");
                                    if (copyPastePlugin) {
                                        copyPastePlugin.paste(value);
                                    }
                                },
                                (reason: any) => {
                                    const message = reason.message;
                                    if (JSON.stringify(reason).includes("permission denied") || message && message.includes("permission denied")) {
                                        setShowPasteBlockedAlert(true);
                                    }
                                }
                            );
                        this.listen();
                    },
                },
                "makeDollar": {
                    key: "makeDollar",
                    name: "Set Selected Growth to $ (Dollars)",
                    callback() {
                        setGrowthTypeInSelection("dollar");
                    },
                    disabled() {
                        const growthSelected = isGrowthInSelection(hotRef, props.property.reforecastStartMonthIndex, props.versionType);
                        return !growthSelected;
                    }
                },
                "makePercent": {
                    key: "makePercent",
                    name: "Set Selected Growth to % (Percent)",
                    callback() {
                        setGrowthTypeInSelection("percent");
                    },
                    disabled() {
                        const growthSelected = isGrowthInSelection(hotRef, props.property.reforecastStartMonthIndex, props.versionType);
                        return !growthSelected;
                    }
                },
                "clearOverrides": {
                    key: "clearOverrides",
                    name: "Undo Overrides in Selection",
                    callback() {
                        const reverts = buildClearOverridesUpdates(hotRef, rowToUnitTypeIdMap, overridesByUnitTypeId, props.property.reforecastStartMonthIndex, props.versionType);
                        clearOverrides(reverts);
                    },
                    disabled() {
                        const reverts = buildClearOverridesUpdates(hotRef, rowToUnitTypeIdMap, overridesByUnitTypeId, props.property.reforecastStartMonthIndex, props.versionType);
                        return reverts.length == 0;
                    },
                }
            },
        };

        return ret;
    }, [rowToUnitTypeIdMap, overridesByUnitTypeId, props.property.reforecastStartMonthIndex, props.versionType]);

    function updatePending(
        adjustmentUpdates: {monthIndex: number, val: number | null;}[],
        adjustmentTypeUpdates: {monthIndex: number, val: "$" | "%" | null;}[],
        overrideUpdates: OverrideUpdate[]
    ) {
        if (overrideUpdates.length == 0 && adjustmentTypeUpdates.length == 0 && adjustmentUpdates.length == 0) {
            return;
        }
        let updated = EMPTY_UPDATES();
        if (pendingUpdates) {
            updated = {
                adjustments: [...pendingUpdates.adjustments],
                adjustmentTypes: [...pendingUpdates.adjustmentTypes],
                overrides: new Map(pendingUpdates.overrides)
            };
        }
        for (const update of adjustmentUpdates) {
            updated.adjustments[update.monthIndex] = update.val;
        }
        for (const update of adjustmentTypeUpdates) {
            let val = null;
            if (update.val === "$") {
                val = OriginalRevenueMarketRentAdjustmentType.Dollar;
            }
            else if (update.val === "%") {
                val = OriginalRevenueMarketRentAdjustmentType.Percent;
            }
            updated.adjustmentTypes[update.monthIndex] = val;
        }
        for (const update of overrideUpdates) {
            let utData = updated.overrides.get(update.unitTypeId);
            if (!utData) {
                utData = new Array(12).fill(undefined);
                updated.overrides.set(update.unitTypeId, utData);
            }
            utData[update.monthIndex] = update.val;
        }
        setPendingUpdates(updated);
    }

    function handleSaveChanges() {
        if (!pendingUpdates) {
            return;
        }

        const unitTypeOverrides = [];
        const variables: UpdateOriginalRevenueMarketRentDataMutationVariables = {
            propertyId: props.property.id,
            budgetYear: props.property.budgetYear,
            versionType: props.versionType,
            unitTypeOverrides: []
        };
        variables.adjM0 = pendingUpdates.adjustments[0];
        variables.adjM1 = pendingUpdates.adjustments[1];
        variables.adjM2 = pendingUpdates.adjustments[2];
        variables.adjM3 = pendingUpdates.adjustments[3];
        variables.adjM4 = pendingUpdates.adjustments[4];
        variables.adjM5 = pendingUpdates.adjustments[5];
        variables.adjM6 = pendingUpdates.adjustments[6];
        variables.adjM7 = pendingUpdates.adjustments[7];
        variables.adjM8 = pendingUpdates.adjustments[8];
        variables.adjM9 = pendingUpdates.adjustments[9];
        variables.adjM10 = pendingUpdates.adjustments[10];
        variables.adjM11 = pendingUpdates.adjustments[11];
        variables.adjTpM0 = pendingUpdates.adjustmentTypes[0];
        variables.adjTpM1 = pendingUpdates.adjustmentTypes[1];
        variables.adjTpM2 = pendingUpdates.adjustmentTypes[2];
        variables.adjTpM3 = pendingUpdates.adjustmentTypes[3];
        variables.adjTpM4 = pendingUpdates.adjustmentTypes[4];
        variables.adjTpM5 = pendingUpdates.adjustmentTypes[5];
        variables.adjTpM6 = pendingUpdates.adjustmentTypes[6];
        variables.adjTpM7 = pendingUpdates.adjustmentTypes[7];
        variables.adjTpM8 = pendingUpdates.adjustmentTypes[8];
        variables.adjTpM9 = pendingUpdates.adjustmentTypes[9];
        variables.adjTpM10 = pendingUpdates.adjustmentTypes[10];
        variables.adjTpM11 = pendingUpdates.adjustmentTypes[11];
        for (const [unitTypeId, overrideValues] of Array.from(pendingUpdates.overrides)) {
            const input: UnitTypeWithOriginalrevenueMarketRentOverridesGranularInput = {
                unitTypeId: unitTypeId,
                ovM0: overrideValues[0],
                ovM1: overrideValues[1],
                ovM2: overrideValues[2],
                ovM3: overrideValues[3],
                ovM4: overrideValues[4],
                ovM5: overrideValues[5],
                ovM6: overrideValues[6],
                ovM7: overrideValues[7],
                ovM8: overrideValues[8],
                ovM9: overrideValues[9],
                ovM10: overrideValues[10],
                ovM11: overrideValues[11],
            };
            unitTypeOverrides.push(input);
        }
        variables.unitTypeOverrides = unitTypeOverrides;
        setPendingUpdates(undefined);
        saveChanges({
            variables: variables
        });
    }

    /*
        IMPORTANT to have after change re-defined on each render and not set once in the buildSettings.
        Doing that in buildSettings makes any component functions/data stale (google for react stale closure for more details)
    */
    const afterChange = (changes: Handsontable.CellChange[] | null, source: Handsontable.ChangeSource) => {
        if (!changes || source == "loadData" || !hotRef.current || !hotRef.current.hotInstance) {
            return;
        }
        const adjustmentUpdates: {monthIndex: number, val: number | null;}[] = [];
        const adjustmentTypeUpdates: {monthIndex: number, val: "$" | "%" | null;}[] = [];
        const overrideUpdates: {unitTypeId: string, monthIndex: number, val: number | null;}[] = [];
        let forecastStartIndex = props.property.reforecastStartMonthIndex;
        if (props.versionType == VersionType.Budget) {
            forecastStartIndex = 0;
        }
        for (const change of changes) {
            const [row, column, oldValue, newValue] = change;
            const col = typeof column === "number" ? column : parseInt(column);
            if (col - 1 < forecastStartIndex || col - 1 >= 12) {
                continue;
            }
            // adjustments
            if (row === 0) {
                if (oldValue == newValue) {
                    continue;
                }
                let parsed = null;
                if (newValue !== null) {
                    parsed = parseVal(newValue);
                }
                if (parsed === null) {
                    adjustmentUpdates.push({
                        monthIndex: col - 1,
                        val: null
                    });
                    adjustmentTypeUpdates.push({
                        monthIndex: col - 1,
                        val: null
                    });
                }
                else {
                    adjustmentUpdates.push({
                        monthIndex: col - 1,
                        val: parsed.val
                    });
                    let adjustmentType: "$" | "%" = "%"; // default to %
                    if (parsed.type === "dollar") {
                        adjustmentType = "$";
                    }
                    else if (parsed.type == "number") {
                        // the case where user would type in just number without specifying
                        // the type ($ or %)
                        // try preserve whatever type was there previously
                        const loadedAdjustmentType = loadedAdjustmentTypes[col-1];
                        if (loadedAdjustmentType !== null) {
                            adjustmentType = loadedAdjustmentType == "dollar" ? "$" : "%";
                        }
                    }
                    adjustmentTypeUpdates.push({
                        monthIndex: col - 1,
                        val: adjustmentType
                    });
                }
            }
            // unit type overrides
            else if (row > 0) {
                const unitTypeId = rowToUnitTypeIdMap[row];
                if (!unitTypeId) {
                    continue;
                }
                const casted = newValue === null ? 0 : +newValue;
                if (!Number.isNaN(casted)) {
                    overrideUpdates.push({
                        unitTypeId: unitTypeId,
                        monthIndex: col - 1,
                        val: casted
                    });
                }
            }
        }

        updatePending(adjustmentUpdates, adjustmentTypeUpdates, overrideUpdates);
    };

    const beforeChange = (changes: Array<Handsontable.CellChange | null>, source: Handsontable.ChangeSource) => {
        if (!changes || source == "loadData" || !hotRef.current || !hotRef.current.hotInstance) {
            return;
        }
        const adjustmentUpdates: {monthIndex: number, val: number | null;}[] = [];
        const adjustmentTypeUpdates: {monthIndex: number, val: "$" | "%" | null;}[] = [];
        const overrideUpdates: {unitTypeId: string, monthIndex: number, val: number | null;}[] = [];
        let forecastStartIndex = props.property.reforecastStartMonthIndex;
        if (props.versionType == VersionType.Budget) {
            forecastStartIndex = 0;
        }
        for (const change of changes) {
            if (change === null) {
                continue;
            }
            const [row, column, oldValue, newValue] = change;
            const col = typeof column === "number" ? column : parseInt(column);
            if (col - 1 < forecastStartIndex || col - 1 >= 12) {
                continue;
            }
            // adjustments
            if (row === 0) {
                if (oldValue == newValue) {
                    continue;
                }
                let parsed = null;
                if (newValue !== null) {
                    parsed = parseVal(newValue);
                }
                if (parsed !== null) {
                    let adjustmentType: "$" | "%" = "%"; // default to %
                    if (parsed.type === "dollar") {
                        adjustmentType = "$";
                    }
                    else if (parsed.type == "number") {
                        // the case where user would type in just number without specifying
                        // the type ($ or %)
                        // try preserve whatever type was there previously
                        const loadedAdjustmentType = loadedAdjustmentTypes[col-1];
                        if (loadedAdjustmentType !== null) {
                            adjustmentType = loadedAdjustmentType == "dollar" ? "$" : "%";
                        }
                    }
                    if (adjustmentType === "$") {
                        change[3] = `$${parsed.val}`;
                    }
                    else {
                        change[3] = `${parsed.val}%`;
                    }
                }
            }
        }

        updatePending(adjustmentUpdates, adjustmentTypeUpdates, overrideUpdates);
    };

    function clearOverrides(overrideUpdates: OverrideUpdate[]) {
        updatePending([], [], overrideUpdates);
    }

    function setGrowthTypeInSelection(targetType: "dollar" | "percent") {
        const hot = hotRef.current?.hotInstance;
        if (!hot) {
            return;
        }
        const selectedRange = hot.getSelectedRange()?.flatMap(range => range.getAll()) ?? [];
        let forecastStartIndex = props.property.reforecastStartMonthIndex;
        if (props.versionType == VersionType.Budget) {
            forecastStartIndex = 0;
        }
        const adjustmentTypeUpdates: {monthIndex: number, val: "$" | "%" | null;}[] = [];
        for (const {row, col} of selectedRange) {
            if (row > 0 || col - 1 < forecastStartIndex || col - 1 >= 12) {
                continue;
            }
            const curVal = hot.getDataAtCell(row, col);
            if (curVal === null || typeof curVal === "string" && curVal.trim().length == 0) {
                continue;
            }
            const parsed = parseVal(curVal);
            if (parsed === null) {
                continue;
            }
            if (parsed.type === targetType) {
                continue;
            }
            adjustmentTypeUpdates.push({
                monthIndex: col - 1,
                val: targetType === "dollar" ? "$" : "%"
            });
        }
        updatePending([], adjustmentTypeUpdates, []);
    }

    return (
        <div className={css.tableWrapper}>
            <HotTable
                ref={hotRef}
                settings={tableSettings}
                licenseKey={LICENSES.HandsOnTable}
                beforeChange={beforeChange}
                afterChange={afterChange}
                contextMenu={contextMenu}
                style={{margin: "auto"}}
            />
            {showPasteBlockedAlert &&
            <Modal onClose={() => setShowPasteBlockedAlert(false)}>
                <Header isDanger>
                    Can't paste
                </Header>
                <Body>
                    To be able to paste into you need to enable clipboard access in your browser settings.
                </Body>
                <Footer>
                    <FooterItem>
                        <Button onClick={() => setShowPasteBlockedAlert(false)}>
                            Ok
                        </Button>
                    </FooterItem>
                </Footer>
                <Close aria-label="Close modal" />
            </Modal>
            }
        </div>
    );
}