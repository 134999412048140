import {useState} from "react";
import {nextSortType, SortType} from "../../utils/sorting";

export type Sorting = ReturnType<typeof useSorting>;
export type SortingParams<SortColumn> = {
    sortColumn: SortColumn | undefined;
    sortType: SortType | undefined;
}
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useSorting<SortColumn extends string>() {
    const [sortColumn, setSortColumn] = useState<SortColumn>();
    const [sortType, setSortType] = useState<SortType>();

    function toggleSort(column: SortColumn | undefined) {
        if (column !== sortColumn) {
            setSortColumn(column);
            setSortType(nextSortType(undefined));
        }
        else {
            setSortType(nextSortType(sortType));
        }
    }

    function reset() {
        setSortColumn(undefined);
        setSortType(undefined);
    }

    function currentSortForColumn(column: SortColumn | undefined): SortType | undefined {
        let type: SortType | undefined = undefined;
        if (!column) {
            type = undefined;
        }
        else if (sortColumn === column) {
            type = sortType;
        }

        return type;
    }

    return {
        toggleSort,
        reset,
        currentSortForColumn,
        sortingParams: {
            sortColumn,
            sortType
        }
    };
}