import { MutationSubmitBulkSetAssumptionsArgs } from '../../__generated__/generated_types';
import { MetricData } from './BulkAssumptions';


export function validateArray(arr: (number | null)[]): {isEmpty: boolean, isValid: boolean} {
    if(arr.every(v => v === null || v === undefined)) {
        return {
            isEmpty: true,
            isValid: true
        };
    }
    for(let i = 0; i < arr.length; i++) {
        const val = arr[i];
        if(val === null || val === undefined || !/^\.\d+|\d+(\.\d+)?$/.test(String(val))) {
            return {
                isEmpty: false,
                isValid: false
            };
        }
    }
    return {
        isEmpty: false,
        isValid: true
    };
}

export function getRenewalTradeOutTypeLabel(type: string) {
    switch(type) {
        case "growth":
            return "Grow In Place Rent";
        case "discount":
            return "Discount From Market";
        case "manual":
            return "Fixed $ Amount";
    }
}

export function getMetricDataLabel(metric: keyof MetricData): string {
    switch(metric) {
        case "marketRent":
            return "Market Rent";
        case "leaseExpirations":
            return "Lease Expirations";
        case "renewalPercent":
            return "Renewal %";
        case "moveOutPercent":
            return "Move Out %";
        case "renewalTradeOut":
            return "Renewal Trade Out $";
        case "renewalTradeOutPct":
            return "Renewal Trade Out $";
        case "occupancy":
            return "Occupancy %";
        default:
            return "MISSING LABEL";
    }
}
