import {ReactElement, useContext, useMemo, useRef, useState} from "react";
import YearMonthRange from "../../../components/year-month-range/YearMonthRange";
import FormulaBar, {FORMULA_BAR_DUMMY_ACCOUNT_ID} from "../../workflows/account/formula-bar/FormulaBar";
import {
    BulkAddDriversMutationVariables,
    DistributionMethod,
    DriverType,
    GrowthCalcMethod,
    MonthlyAdjustmentType,
    MonthlyAverageBasePeriod,
    OperationalMetricType,
    PayrollPrimaryCompensationItem,
    PercentGrowthBasePeriod,
    useBulkAddDriversMutation,
    VersionType
} from "../../../__generated__/generated_types";
import {Property, useProperties} from "../../../contexts/properties/PropertiesContext";
import {
    buildDummyData,
    IDriversAndWorksheetData,
    ISaveData
} from "../../../contexts/account/data/useDriversAndWorksheetData";
import * as css from "../../../pages/workflows/account/styles/styles.module.scss";
import * as bulkCss from "./styles/bulk-add-drivers.module.scss";
import {
    NonSavingAccountTable,
    TNonSavingAccountTableCombinedData
} from "../../../components/account-table/NonSavingAccountTable";
import {useFormulaBarAccountTableMapper} from "../logic/useFormulaBarAccountTableMapper";
import {AuthContext} from "../../../contexts/AuthContext";
import {Button} from "@zendeskgarden/react-buttons";
import {
    IDriverRow,
    IDriverRowAccountPercentage,
    IDriverRowCustom,
    IDriverRowLineItem,
    IDriverRowOperational
} from "../../../components/account-table/logic/DriverHelpers";
import {SelectableFinancialEntity} from "../PropertyDrivers";
import {Label} from "@zendeskgarden/react-forms";
import {CustomZDDropdown} from "../../../atoms/custom-zd-dropdown/CustomZDDropdown";
import {TDriverMetrics, TDriverPayrollMetric} from "../../../contexts/account/data/logic/driversAndWorksheetData";

// eslint-disable-function @typescript-eslint/no-non-null-assertion
function buildCombinedData(
    dwdData: IDriversAndWorksheetData,
    driverAssumptions: Map<string, IDriverRow>,
    opMetricTypes: Pick<OperationalMetricType, "id" | "name">[]
): TNonSavingAccountTableCombinedData {
    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    return{
        driversAndWorksheetData: {
            accountPercentageDriver: {
                isDriven: dwdData.parsedData!.drivers.account.length > 0,
                sourceAccounts: dwdData.parsedData!.drivers.account.map(a => {
                    // The assumptions table uses the source account id. This isn't so problematic here;
                    // it's just icky.
                    const assumptions = driverAssumptions.get(a.accountId);
                    let assumptionsAndValues;
                    if(assumptions && assumptions.driverType === DriverType.AccPercentage) {
                        const accAssumptions = assumptions as IDriverRowAccountPercentage;
                        assumptionsAndValues = accAssumptions.percentage.map(v => ({
                            percentage: v,
                            value: null,
                            lookbackPeriod: a.lookbackPeriod
                        }));
                    } else {
                        assumptionsAndValues = new Array(12).fill({
                            percentage: null,
                            value: null,
                            lookbackPeriod: a.lookbackPeriod
                        });
                    }

                    return {
                        ...a,
                        assumptionId: a.acctPercentageDriverAccountId,
                        assumptionsAndValues: assumptionsAndValues
                    };
                }),
                augments: {
                    maxValue: null,
                    minValue: null
                }
            },
            customDriver: {
                isDriven: dwdData.parsedData!.drivers.customDriver.length > 0,
                items: dwdData.parsedData!.drivers.customDriver.map(c => {
                    const assumptions = driverAssumptions.get(c.id);
                    let assumptionsAndValues;
                    if(assumptions) {
                        const customAssumptions = assumptions as IDriverRowCustom;
                        assumptionsAndValues = customAssumptions.amount.map((a, idx) => ({
                            amount: a,
                            count: customAssumptions.count[idx],
                            percentOf: customAssumptions.percentage[idx]
                        }));
                    } else {
                        assumptionsAndValues = new Array(12).fill({
                            amount: null,
                            count: null,
                            percentOf: null
                        });
                    }
                    return {
                        id: c.id,
                        itemName: c.itemName,
                        assumptions: assumptionsAndValues
                    };
                })
            },
            growthDriver: dwdData.parsedData!.drivers.annualTargetValue
                .concat(dwdData.parsedData!.drivers.percentGrowth)
                .concat(dwdData.parsedData!.drivers.monthlyAverage)
                .map(a => {
                    return {
                        ...a,
                        year: dwdData.parsedData!.year,
                        versionType: dwdData.parsedData!.versionType,
                        propertyId: "",
                        accountId: "",
                        annualValues: new Array(12).fill(null)
                    };
                }),
            operationalMetricDriver: {
                isDriven: dwdData.parsedData!.drivers.operational.length > 0,
                sourceMetrics: dwdData.parsedData!.drivers.operational.map(o => {
                    // This is fucked up, as the id used in the table is not the driver's id, but the
                    // source metric. This works when a source metric is allowed only once, but when that
                    // changes, BOOM!.
                    const assumptions = driverAssumptions.get(o.sourceMetricId);
                    let assumptionsAndValues;
                    if(assumptions && assumptions.driverType === DriverType.Operational) {
                        const opAssumptions = assumptions as IDriverRowOperational;
                        assumptionsAndValues = opAssumptions.percentage.map((v, idx) => ({
                            percentage: v,
                            amount: opAssumptions.fee[idx],
                            metricValue: null,
                            lookbackPeriod: o.lookbackPeriod
                        }));
                    } else {
                        assumptionsAndValues = new Array(12).fill({
                            percentage: null,
                            amount: null,
                            metricValue: null,
                            lookbackPeriod: o.lookbackPeriod
                        });
                    }

                    return {
                        ...o,
                        metricName: opMetricTypes.find(t => t.id === o.sourceMetricId)?.name ?? "UNKNOWN",
                        assumptionsAndValues: assumptionsAndValues
                    };
                })
            },
            payrollDriver: {
                isDriven: dwdData.parsedData!.drivers.payroll.length > 0,
                compensationItems: dwdData.parsedData!.drivers.payroll.map(p => {
                    return {
                        itemType: p.itemType,
                        positions: p.positions
                    };
                })
            },
            renovationsDriver: {
                isDriven: false,
                costCategories: []
            },
            revenueDriver: {
                isDriven: false,
                sourceRevenueMetrics: []
            },
            worksheetDriver: {
                isDriven: dwdData.parsedData!.drivers.worksheet.length > 0,
                worksheetLines: dwdData.parsedData!.drivers.worksheet.map(w => {
                    const assumptions = driverAssumptions.get(w.description);
                    let values;
                    if(assumptions) {
                        const lineItem = assumptions as IDriverRowLineItem;
                        values = lineItem.values;
                    } else {
                        values = new Array(12).fill(null);
                    }
                    return {
                        lineId: "lineId",
                        values: values,
                        description: w.description
                    };
                })
            }
        },
        financialYearValues: {
            years: {}
        }
    };
    /* eslint-enable @typescript-eslint/no-non-null-assertion */
}

function valuesOrZeros(value: string[] | undefined): string[] {
    if(value) {
        return value.map(v => v !== undefined && v !== null && v !== "" ? v : "0");
    }
    return new Array(12).fill("0");
}

function mapForSave(
        propertyIds: string[],
        accountIds: string[],
        versionType: VersionType.Budget | VersionType.Reforecast,
        year: number,
        dwd: TDriverMetrics,
        assumptionsMap: Map<string, IDriverRow>,
        payrollCompItemsMap: Map<string, string>
): BulkAddDriversMutationVariables {
    const partialVars: Partial<BulkAddDriversMutationVariables> = {};
    if(dwd.drivers.account.length > 0) {
        partialVars["accountPercentage"] = dwd.drivers.account.map(a => {
            const assumptions = assumptionsMap.get(a.accountId) as IDriverRowAccountPercentage | undefined;
            return {
                delayRecognition: a.lookbackPeriod ?? 0,
                monthlyPercents: valuesOrZeros(assumptions?.percentage),
                sourceAccountId: a.accountId,
                minValue: null,
                maxValue: null
            };
        });
    }
    if(dwd.drivers.customDriver.length > 0) {
        partialVars["custom"] = dwd.drivers.customDriver.map(c => {
            const assumptions = assumptionsMap.get(c.id) as IDriverRowCustom | undefined;
            return {
                itemName: c.itemName,
                monthlyPercents: valuesOrZeros(assumptions?.percentage),
                monthlyAmounts: valuesOrZeros(assumptions?.amount),
                monthlyCount: valuesOrZeros(assumptions?.count)
            };
        });
    }
    if(dwd.drivers.operational.length > 0) {
        partialVars["operational"] = dwd.drivers.operational.map(o => {
            const assumptions = assumptionsMap.get(o.sourceMetricId) as IDriverRowOperational | undefined;
            return {
                delayRecognition: o.lookbackPeriod ?? 0,
                operationalMetricTypeId: o.sourceMetricId,
                monthlyAmounts: valuesOrZeros(assumptions?.fee),
                monthlyPercents: valuesOrZeros(assumptions?.percentage)
            };
        });
    }
    if(dwd.drivers.worksheet.length > 0) {
        partialVars["lineItems"] = dwd.drivers.worksheet.map(w => {
            const assumptions = assumptionsMap.get(w.description) as IDriverRowLineItem | undefined;
            return {
                description: w.description,
                monthlyValues: valuesOrZeros(assumptions?.values)
            };
        });
    }
    if(dwd.drivers.payroll.length > 0) {
        const isPrimaryPayrollItem = (itemType: string) => itemType === PayrollPrimaryCompensationItem.Compensation ||
                itemType === PayrollPrimaryCompensationItem.Bonus ||
                itemType === PayrollPrimaryCompensationItem.Overtime;
        const primaryPayroll: TDriverPayrollMetric[] = [];
        const compItemsPayroll: TDriverPayrollMetric[] = [];
        for(const pItem of dwd.drivers.payroll) {
            if(isPrimaryPayrollItem(pItem.itemType)) {
                primaryPayroll.push(pItem);
            } else {
                compItemsPayroll.push(pItem);
            }
        }

        if(primaryPayroll.length > 0) {
            partialVars["payrollCompensation"] = primaryPayroll.flatMap(p => {
                return p.positions.map(pos => ({
                    primaryCompensationItem: p.itemType as PayrollPrimaryCompensationItem,
                    positionId: pos.id
                }));
            });
        }
        if(compItemsPayroll.length > 0) {
            partialVars["payrollCompensationItem"] = compItemsPayroll.flatMap(p => {
                const compItemId = payrollCompItemsMap.get(p.itemType);
                if(!compItemId) {
                    return [];
                }
                return p.positions.map(pos => ({
                    compensationItemId: compItemId,
                    positionId: pos.id
                }));
            });
        }
    }
    if(dwd.drivers.monthlyAverage.length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const monthlyAverage = dwd.drivers.monthlyAverage[0]!;
        partialVars["growth"] = {
            growthCalcMethod: GrowthCalcMethod.MonthlyAverage,
            monthlyAverage: {
                monthlyAdjustmentValue: monthlyAverage.monthlyAdjustmentValue?.toString() ?? "0",
                monthlyAdjustmentType: monthlyAverage.monthlyAdjustmentType ?? MonthlyAdjustmentType.Dollar,
                monthlyAverageBasePeriod: monthlyAverage.monthlyAverageBasePeriod ?? MonthlyAverageBasePeriod.LastThreeMonthsActuals,
                lookbackPeriodStart: monthlyAverage.lookbackPeriodStart,
                lookbackPeriodEnd: monthlyAverage.lookbackPeriodEnd
            }
        };
    }
    if(dwd.drivers.percentGrowth.length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const percentGrowth = dwd.drivers.percentGrowth[0]!;
        partialVars["growth"] = {
            growthCalcMethod: GrowthCalcMethod.PercentGrowth,
            percentGrowth: {
                percentGrowthAdjustmentValue: percentGrowth.percentGrowthAdjustmentValue?.toString() ?? "0",
                percentGrowthBasePeriod: percentGrowth.percentGrowthBasePeriod ?? PercentGrowthBasePeriod.SameMonthLastYear,
                distributionMethod: percentGrowth.distributionMethod ?? DistributionMethod.Flat
            }
        };
    }
    if(dwd.drivers.annualTargetValue.length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const annualTargetValue = dwd.drivers.annualTargetValue[0]!;
        partialVars["growth"] = {
            growthCalcMethod: GrowthCalcMethod.AnnualTargetValue,
            annualTargetValue: {
                annualTargetValue: annualTargetValue.annualTargetValueManualEntry?.toString() ?? "0",
                distributionMethod: annualTargetValue.distributionMethod ?? DistributionMethod.Flat
            }
        };
    }

    return {
        propertyIds: propertyIds,
        accountIds: accountIds,
        versionType: versionType,
        year: year,
        ...partialVars
    };
}

export interface IBulkAddDriversProps {
    property: Property;
    versionType: VersionType.Budget | VersionType.Reforecast;
    accounts: SelectableFinancialEntity[];
    cancel?: () => void;
}

export function BulkAddDrivers(props: IBulkAddDriversProps): ReactElement {
    const {properties} = useProperties();
    const authContext = useContext(AuthContext);

    const [selectedProperties, setSelectedProperties] = useState<Property[]>([props.property]);

    const yearAndMonth = useMemo(() => {
        let properties = selectedProperties;
        if(properties.length === 0) {
            properties = [props.property];
        }

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        let year = properties[0]!.reforecastYear;
        let month = properties.map(p => p.reforecastStartMonthIndex).min();
        if(props.versionType === VersionType.Budget) {
            year += 1;
            month = 0;
        }

        return {
            reforecastYear: props.property.reforecastYear,
            budgetYear: props.property.reforecastYear + 1,
            year: year,
            month: month
        };
    }, [selectedProperties, props.versionType]);

    const {updateFormulaBarData} = useFormulaBarAccountTableMapper({
        propertyId: props.property.id,
        year: yearAndMonth.year,
        versionType: props.versionType
    });

    const [dummyDWD, setDummyDWD] = useState<IDriversAndWorksheetData>(
        buildDummyData(props.property.id, yearAndMonth.year, props.versionType, FORMULA_BAR_DUMMY_ACCOUNT_ID)
    );
    const driverAssumptions = useRef<Map<string, IDriverRow>>(new Map());
    const payrollCompItemMappings = useRef<Map<string, string>>(new Map());

    const [bulkAddDrivers] = useBulkAddDriversMutation();
    const [saving, setSaving] = useState<boolean>(false);

    function handleSave(dwd: IDriversAndWorksheetData, assumptions: Map<string, IDriverRow>): void {
        if(!dwd.parsedData) {
            return;
        }
        const vars =  mapForSave(
            selectedProperties.map(p => p.id),
            props.accounts.map(a => a.id),
            props.versionType,
            yearAndMonth.year,
            dwd.parsedData,
            assumptions,
            payrollCompItemMappings.current
        );

        setSaving(true);
        bulkAddDrivers({
            variables: vars
        }).finally(() => {
            setSaving(false);
        });
    }

    function handleFormulaBarCommit(data: ISaveData): void {
        const newData = updateFormulaBarData(dummyDWD, data);
        data.pendingUpdates.add.payroll.forEach(p => {
            if(p.compId) {
                payrollCompItemMappings.current.set(p.itemType, p.compId);
            }
        });
        setDummyDWD(newData);
    }

    function handleTableUpdate(drivers: Map<string, IDriverRow>): void {
        driverAssumptions.current = drivers;
    }

    function hasDrivers(dummyDWD: IDriversAndWorksheetData): boolean {
        if(dummyDWD.parsedData) {
            const parsedData = dummyDWD.parsedData;
            const values = Object.values(parsedData.drivers);
            for(const v of values) {
                if(Array.isArray(v) && v.length > 0) {
                    return true;
                }
            }
        }
        return false;
    }

    return <div style={{minHeight: "716px"}}>
        <div className={bulkCss.propertySelector}>
            <Label style={{marginBottom: ".5rem"}}>
                <span>Properties</span>
            </Label>
            <CustomZDDropdown
                    initialSelectedItems={selectedProperties.map(p => p.id)}
                    applySelectedItems={function (items: string[]): void {
                        if(properties) {
                            const activeProperties = properties.filter(x => items.includes(x.id));
                            setSelectedProperties(activeProperties);
                        }
                    }}
                    openDropdownPlaceholder={"Properties"}
                    closedDropdownPlaceholder={"Properties"}
                    options={properties?.map(p => ({value: p.id, label: p.name})) ?? []}
                    isError={selectedProperties.length === 0}
                    isMulti
                    allOption
                    errorMessage="At least one property is required."
            />
        </div>
        <div className={css.accountFormulaBar}>
            <YearMonthRange
                    className={css.formulaDateRange}
                    startYear={yearAndMonth.year}
                    startMonthIdx={yearAndMonth.month}
                    endYear={yearAndMonth.year}
                    endMonthIdx={11}
            />
            <FormulaBar
                    year={yearAndMonth.reforecastYear}
                    budgetYear={yearAndMonth.budgetYear}
                    versionType={props.versionType}
                    currentPropertyId={props.property.id}
                    accountId={FORMULA_BAR_DUMMY_ACCOUNT_ID}
                    readOnly={false}
                    driversAndWorksheetData={{
                        ...dummyDWD,
                        saveData: handleFormulaBarCommit
                    }}
                    disabledMenuItems={["revenue"]}
                    disableCycleDetection={true}
                    editableFxBarChecker={() => true}
                    isPropertyDriversUI
            />
        </div>
        {hasDrivers(dummyDWD) ?
            <NonSavingAccountTable
                year={yearAndMonth.year}
                versionType={props.versionType}
                reforecastStartMonth={yearAndMonth.month}
                combinedData={buildCombinedData(dummyDWD, driverAssumptions.current, authContext.operationalMetricTypes)}
                editEnd={handleTableUpdate}
            /> :
            <div className={bulkCss.selectDrivers}>Add Drivers to Begin!</div>
        }
        <div className={bulkCss.buttons}>
            {props.cancel && <Button className={bulkCss.button} isBasic onClick={props.cancel}>Cancel</Button>}
            <Button
                    className={bulkCss.button}
                    isPrimary
                    disabled={!hasDrivers(dummyDWD) || saving}
                    onClick={() => handleSave(dummyDWD, driverAssumptions.current)}
            >Save</Button>
        </div>
    </div>;
}