// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

const CRITICAL_POINT = 1 / 12; // ~8.33333333333%
export function calculateBellCurve(rootValue: number, index: number): number[] {
    if(Math.abs(rootValue - CRITICAL_POINT) <= 0.0000001){
        return new Array(12).fill(CRITICAL_POINT);
    }

    const values: number[] = new Array(12).fill(0);
    // For calculation purposes, we always use 6 as the index
    // and just shift the array after the math is done. This
    // makes it easier to reason about.
    if(rootValue < CRITICAL_POINT) {
        // This calculation is slightly different in that
        // we set two values equal to the root value
        // (indices 5 and 6). This allows us to build a perfect
        // bell curve. This only works here because we are guaranteed
        // that (rootValue * 2) < 1.
        let toDistribute = 1 - (rootValue * 2);
        for(let j = 0; j < 5; j++) {
            const half = toDistribute / 2;
            toDistribute = half;
            const distribute = half / (10 - (2 * j));
            for(let i = 4 - j; i >= 0; i--) {
                values[i] += distribute;
            }
            for(let i = 7 + j; i <= 11; i++) {
                values[i] += distribute;
            }
        }
        values[5] = values[6] = rootValue;

        // We always have a little left over, so we just distribute it evenly.
        const distribute = toDistribute / 10;
        for(let i = 0; i < 5; i++) {
            values[i] += distribute;
        }
        for(let i = 7; i < 12; i++) {
            values[i] += distribute;
        }

        // As the numbers get really close to the critical point, the calculated values
        // around the rootValue will fall below the rootValue. We will pull from the
        // outside inward to ensure that we don't have an odd bump.
        // Our calculation produces symmetric values, so the left and right side around
        // the rootValue are have the same values.
        for(let i = 4, j = 7; i >= 1 && values[i] < values[i + 1]; i--, j++) {
            const missing = values[i + 1] - values[i];
            values[i] += missing;
            values[j] += missing;

            const stolen = missing / i;
            for(let ii = 0, jj = 11; ii < i; ii++, jj--) {
                values[ii] -= stolen;
                values[jj] -= stolen;
            }
        }

        // All the math we're doing isn't 100% numerically stable, so this corrects for that.
        const remainder = (1 - (values.reduce((a, b) => a + b, 0))) / 2;
        values[0] += remainder;
        values[11] += remainder;
    } else {
        let toDistribute = 1 - rootValue;

        // We calculate the divisor to give a flatter distribution
        // across as many ranges as we can.
        // This formula is just worked out based on what I liked at
        // the time.
        // The values I liked:
        //   rootValue >= 0.3, divisor = 2
        //   rootValue = 0.2, divisor = 5
        //   rootValue = 0.15, divisor = 15
        //   rootValue <= 0.1, divisor = 40
        let divisor = 1.085924 + 326.8495 * Math.exp(-21.26283 * rootValue);
        if(divisor > 40) {
            divisor = 40;
        } else if(divisor < 2) {
            divisor = 2;
        }
        divisor = Math.floor(divisor);

        for(let j = 0; j < 5; j++) {
            // Adjusting the divisor here will flatten or steepen the curve.
            const part = toDistribute / divisor;
            toDistribute -= part;
            const distribute = part / ((j + 1) * 2);
            for(let i = 5; i >= (5 - j); i--) {
                values[i] += distribute;
            }
            for(let i = 7; i <= (7 + j); i++) {
                values[i] += distribute;
            }
        }

        values[6] = rootValue;

        // This distributes the left-over from the divisor evenly.
        // This is what flattens the curve or makes is steeper.
        const distribute = toDistribute / 11;
        for(let i = 0; i < 6; i++) {
            values[i] += distribute;
        }
        for(let i = 7; i < 12; i++) {
            values[i] += distribute;
        }

        // As the numbers get really close to the critical point, the calculated values
        // around the rootValue will exceed the rootValue. We will distribute the extra
        // outward.
        // Our calculation produces symmetric values, so the left and right side around
        // the rootValue are have the same values.
        for(let i = 5, j = 7; i >= 1 && values[i] > rootValue; i--, j++) {
            const excess = values[i] - rootValue;
            values[i] -= excess;
            if((i - 1) >= 1) {
                values[i - 1] += excess;
            }
            values[j] -= excess;
            if((j + 1) <= 11) {
                values[j + 1] += excess;
            }
        }

        // All the math we're doing isn't 100% numerically stable, so this corrects for that.
        values[0] += (1 - values.reduce((a, b) => a + b, 0));
    }

    // Shift the values based on the index.
    const shifted = new Array(12).fill(0);
    const shift = index - 6;
    for(let i = 0; i < 12; i++) {
        let newIdx = i + shift;
        if(newIdx < 0) {
            newIdx += 12;
        } else {
            newIdx = newIdx % 12;
        }
        shifted[newIdx] = values[i];
    }

    return shifted;
}

export function adjustValuesTo100(values: (number | null)[]): number[] {
    const totalSum = values.reduce((sum, num) => sum + num, 0);

    if (totalSum === 100) {
        return values;
    }

    const adjustmentFactor = 100 / totalSum;
    let adjustedValues = values.map(num => num * adjustmentFactor);

    let finalValues = adjustedValues.map(num => Math.round(num * 100) / 100);

    let finalSum = finalValues.reduce((sum, num) => sum + num, 0);
    let difference = Math.round((100 - finalSum) * 100) / 100;

    if (difference !== 0) {
        let maxIndex = finalValues.indexOf(Math.max(...finalValues));
        finalValues[maxIndex] += difference;
    }

    return finalValues;
}
