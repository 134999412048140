import React, { ForwardRefRenderFunction, useEffect, useImperativeHandle, useRef, useState } from "react";

import { registerAllModules } from 'handsontable/registry';

registerAllModules();

import "../../../../../styles/handsontable/handsontable.vizibly.full.scss";
import { HotTable } from '@handsontable/react';

import Handsontable from "handsontable";
import { useLineItemsData } from "./data/useLineItemsData";
import { buildSpreadsheetData } from "./logic/buildSpreadsheetData";
import { useCsvExport } from "./export/useCsvExport";
import { buildHeaderRowsData } from "./logic/buildHeaders";
import { afterGetColumnHeader, getColHeaders, columnConfig } from "./logic/lineItemsSpreadsheet";
import {LICENSES} from "../../../../../constants/Licenses";

export interface LineItemsSpreadsheetAPI {
    exportAsCsv: () => void;
}

export interface LineItemsSpreadsheetProps {
    propertyIds?: string[];
}

const LineItemsSpreadsheetRenderer: ForwardRefRenderFunction<LineItemsSpreadsheetAPI, LineItemsSpreadsheetProps> = (props, ref) => {

    const hotTableComponent = useRef<HotTable>(null);
    const [hotRef, setHotRef] = useState<Handsontable>();
    const [hotData, setHotData] = useState<any[][]>([]);
    const csvExporter = useCsvExport();

    const data = useLineItemsData();

    useEffect(
            () => {
                if(hotTableComponent?.current?.hotInstance){
                    setHotRef(hotTableComponent?.current?.hotInstance);
                }
            },
            []
    );

    useEffect(
            () => {
                if(data.isReady && data.worksheetLines){
                    const newData = buildSpreadsheetData(data.worksheetLines);
                    const headerRows = buildHeaderRowsData(data.reforecastYear);
                    setHotData(headerRows.concat(newData));
                }
            },
            [data.isReady, data.worksheetLines]
    );

    useEffect(
            () => {
                if(data.isReady){
                    data.loadForProperties(props.propertyIds ?? []);
                }
            },
            [data.isReady, props.propertyIds]
    );

    useImperativeHandle(
            ref,
            () => ({
                exportAsCsv: () => {
                    if(hotRef){
                        csvExporter.exportAsCsv(hotRef);
                    }
                },
            }),
            [hotRef],
    );

    return (
            <div style={{
                height: "100%"
            }}>
                {data.isReady
                        ? <HotTable
                                ref={hotTableComponent}
                                data={hotData}
                                columnSorting={true}
                                settings={{
                                    width: "100%",
                                    height: "100%",
                                    colHeaders: (index: number): string => {
                                        return getColHeaders(index, data.reforecastYear);
                                    },
                                    afterGetColHeader: (column: number, th: HTMLTableHeaderCellElement, _headerLevel: number): void => {
                                        afterGetColumnHeader(column, th);
                                    },
                                    readOnly: true,
                                    viewportRowRenderingOffset: 30,
                                    hiddenRows: {
                                        rows: [0],
                                    },
                                    fixedRowsTop: 1,
                                    fixedColumnsLeft: 3,

                                    rowHeights: 40,

                                    columns: columnConfig,

                                    licenseKey: LICENSES.HandsOnTable
                                }}
                        />
                        : <></>
                }
            </div>
    );
};

const LineItemsSpreadsheet = React.forwardRef(LineItemsSpreadsheetRenderer);
export default LineItemsSpreadsheet;